import { REQUEST_STATUS } from "../constants/status";
import { BaseEntityRes } from "./base";
import { SummaryRequestDetails, SummarySubRequestDetails } from "./mydata";

export enum SORT_TYPE {
  date_asc = "date_Asc",
  date_desc = "date_Desc",
  download = "Download",
  processing = "Processing",
  expired = "Expired",
}

export enum HISTORY_REQUEST_TYPE {
  DOWNLOAD = "A",
  DELETE = "E",
  UNSUBSCRIBE = "U",
  ALL = "L",
}

export interface MyDataHistoryReqBody {
  request_type: HISTORY_REQUEST_TYPE;
  sort_by: SORT_TYPE;
  limit?: number;
  offset?: number;
  request_status?: REQUEST_STATUS;
  lang?: string;
  sa_cc?: string;
  site_cc?: string;
}

export interface MyDataHistoryEntity {
  request_count: number;
  next_offset?: number;
  request_list: SummaryRequestDetails<SummarySubRequestDetails>[];
}

export interface MyDataHistoryEntityRes
  extends BaseEntityRes,
  MyDataHistoryEntity { }
