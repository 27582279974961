import { matchPath } from "react-router-dom";

/**
 * Helper to check if a route should render a component given an array of routes.
 * It is ideal to use this util if the route contains parameters.
 *
 * If route does not have parameters, use `Array.includes()` instead.
 *
 * Example:
 * `routeShouldRenderComponent(pathname, ROUTES_NO_HELP_TIPS)` checks if the current route should render the help tips component
 */
export const routeShouldRenderComponent = (
  pathname: string,
  routes: string[]
) => {
  // It is needed to iterate through the array of routes
  // to handle dynamic route parameters comparison instead of
  // using Array.includes()
  // e.g.:
  //   - "/:languageCode/bdp-consigment" !== "/kr/bdp-consigment"
  return routes.some((route) =>
    matchPath(pathname, { path: route, exact: true }),
  );
};
