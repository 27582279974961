import React from "react";
import classNames from "classnames";
import { Svg, SVG_ICONS } from "../Svg/Svg";
import "./Tooltip.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
  onClickClose?: () => void;
  testId?: string;
}

export const Tooltip: React.FC<Props> = (props) => {
  const { className, children, onClickClose, testId } = props;
  const wrapClass = classNames("tooltip", className);

  const handleOnClickClose = () => {
    if (onClickClose) {
      onClickClose();
    }
  };

  return (
    <div className={wrapClass} data-testid={testId}>
      <div className="tooltip__close" onClick={handleOnClickClose}>
        <Svg icon={SVG_ICONS.CLOSE_SMALL} width={12} height={12} />
      </div>
      {children}
    </div>
  );
};
