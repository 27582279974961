import React from "react";
import { Typography } from "../../../../components/__common/Typography/Typography";
import { useTranslation } from "react-i18next";

enum types {
  ACCESS,
  APPEAL,
  CORRECT,
  DELETE,
  DONOTSELL,
  OPTOUT,
  RESTRICT,
  PORTABILITY,
  OBJECT,
}

enum regions {
  USA,
  LATAM,
  ASIA,
  EUROPE,
  TURKIYE,
  CHINA,
  OTHERS,
}

interface Props {
  type: keyof typeof types;
  region: keyof typeof regions | string;
}

const Rights: React.FC<Props> = ({ type, region }) => {
  const { t } = useTranslation();

  return (
    <div className="privacy-rights-modal__section">
      <Typography
        variant="title2"
        className="privacy-rights-modal__section__title"
      >
        {t(`PRIVCY_HUB_MODAL_PRIVACY_RIGHTS_${type}_TITLE`)}
      </Typography>
      {type === "DONOTSELL" && (
        <Typography
          variant="title2"
          className="privacy-rights-modal__section__subtitle"
        >
          {t("PRIVCY_HUB_MODAL_PRIVACY_RIGHTS_DONOTSELL_TITLE2")}
        </Typography>
      )}
      <Typography variant="body1" weight="wMedium">
        {t(`PRIVCY_HUB_MODAL_PRIVACY_RIGHTS_${type}_DETAIL`)}
      </Typography>
    </div>
  );
};

export default Rights;
