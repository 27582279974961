export interface GrrsSiteRegion {
  region: string;
  countries: GrrsSiteCountry[];
}

export interface GrrsSiteCountry {
  code: string | GRRS_NON_EU_COUNTRIES;
  name: string;
  desc?: string;
  link: string;
}

export enum GRRS_NON_EU_COUNTRIES {
  USA = "USA",
  TUR = "TUR",
  BRA = "BRA",
  // ARG = "ARG",
  // EGY = "EGY",
  // MDG = "MDG",
  CHN = "CHN",
  // PHL = "PHL",
}

export enum GRRS_EU_COUNTRIES {
  HRV = "HRV",
  SVN = "SVN",
  AUT = "AUT",
  LTU = "LTU",
  LVA = "LVA",
  EST = "EST",
  NLD = "NLD",
  BEL = "BEL",
  LUX = "LUX",
  CZE = "CZE",
  SVK = "SVK",
  FRA = "FRA",
  DEU = "DEU",
  GRC = "GRC",
  // 'CY', Cyprus not included
  HUN = "HUN",
  ITA = "ITA",
  MLT = "MLT",
  SWE = "SWE",
  FIN = "FIN",
  DNK = "DNK",
  ISL = "ISL",
  PRT = "PRT",
  POL = "POL",
  ROU = "ROU",
  BGR = "BGR",
  ESP = "ESP",
  GBR = "GBR",
  LIE = "LIE",
  IRL = "IRL",
  // Additional countries
  ALB = "ALB", // Albania
  NOR = "NOR", // Norway
  SRB = "SRB", // Serbia,
  CHE = "CHE", // Switzerland
  XXK = "XXK", // Kosovo
}

export const GrrsNoneEuCountries = {
  [GRRS_NON_EU_COUNTRIES.USA]: {
    code: GRRS_NON_EU_COUNTRIES.USA,
    name: "USA",
    desc: "United States (USA)",
    link: "https://www.samsung.com/us/privacy/",
  },
  // [GRRS_NON_EU_COUNTRIES.EGY]: {
  //   code: GRRS_NON_EU_COUNTRIES.EGY,
  //   name: "Egypt",
  //   link: "#",
  // },
  // [GRRS_NON_EU_COUNTRIES.MDG]: {
  //   code: GRRS_NON_EU_COUNTRIES.MDG,
  //   name: "Madagascar",
  //   link: "#",
  // },
  [GRRS_NON_EU_COUNTRIES.TUR]: {
    code: GRRS_NON_EU_COUNTRIES.TUR,
    name: "Türkiye",
    link: "https://kvkk-portal.samsung.com/",
  },
  [GRRS_NON_EU_COUNTRIES.CHN]: {
    code: GRRS_NON_EU_COUNTRIES.CHN,
    name: "China",
    link: "https://privacy.samsung.com.cn/",
  },
  // [GRRS_NON_EU_COUNTRIES.PHL]: {
  //   code: GRRS_NON_EU_COUNTRIES.PHL,
  //   name: "Philippines",
  //   link: "#",
  // },
  [GRRS_NON_EU_COUNTRIES.BRA]: {
    code: GRRS_NON_EU_COUNTRIES.BRA,
    name: "Brazil",
    link: "https://www.samsung.com/br/privacy/",
  },
  // [GRRS_NON_EU_COUNTRIES.ARG]: {
  //   code: GRRS_NON_EU_COUNTRIES.ARG,
  //   name: "Argentina",
  //   link: "#",
  // },
};

export const GrrsEU: GrrsSiteCountry = {
  code: "EU",
  name: "EU",
  link: "https://www.samsung.com/request-desk/ ",
};

export const GrrsSiteRegions: GrrsSiteRegion[] = [
  {
    region: "America",
    countries: [GrrsNoneEuCountries[GRRS_NON_EU_COUNTRIES.USA]],
  },
  {
    region: "Asia Pacific",
    countries: [GrrsNoneEuCountries[GRRS_NON_EU_COUNTRIES.CHN]],
  },
  {
    region: "Europe",
    countries: [GrrsEU, GrrsNoneEuCountries[GRRS_NON_EU_COUNTRIES.TUR]],
  },
  {
    region: "South America",
    countries: [GrrsNoneEuCountries[GRRS_NON_EU_COUNTRIES.BRA]],
  },
];
