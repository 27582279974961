import { CustomAction } from "..";
import { LOGIN_OPTION_TYPES } from "../actions-types";

export interface LoginOptionState {
  isLoginModalOpen: boolean;
  isEmailLoginModalOpen?: boolean;
  isEmailOtpModalOpen?: boolean;
  loginModalRedirect?: string;
  banDuration: string;
}

const defaultState: LoginOptionState = {
  isLoginModalOpen: false,
  isEmailLoginModalOpen: false,
  isEmailOtpModalOpen: false,
  loginModalRedirect: "",
  banDuration: "",
};

export const loginOptionReducer = (
  state = defaultState,
  action: CustomAction
) => {
  switch (action.type) {
    case LOGIN_OPTION_TYPES.SET_LOGIN_MODAL_OPEN:
      return {
        ...state,
        isLoginModalOpen: action.data.isLoginModalOpen,
        loginModalRedirect: action.data.loginModalRedirect,
      };
    case LOGIN_OPTION_TYPES.SET_EMAIL_LOGIN_MODAL_OPEN:
      return {
        ...state,
        isEmailLoginModalOpen: action.data,
      };
    case LOGIN_OPTION_TYPES.SET_EMAIL_OTP_MODAL_OPEN:
      return {
        ...state,
        isEmailOtpModalOpen: action.data,
      };
    case LOGIN_OPTION_TYPES.SET_OTP_ERROR_MESSAGE:
      return {
        ...state,
        banDuration: action.data,
      };
    default:
      return state;
  }
};
