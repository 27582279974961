import { PP_LANGUAGE, PP_REGION } from "../../constants/storage";
import { Cookie } from "../../services/storage";
import { PRIVACY_POLICY_TYPES } from "../actions-types";
import { CustomAction } from "../index";

export interface PrivacyState {
  language: string;
  region: string;
}

const defaultState: PrivacyState = {
  language: Cookie.get(PP_LANGUAGE) || "",
  region: Cookie.get(PP_REGION) || "",
};

const privacyReducer = (state = defaultState, action: CustomAction) => {
  switch (action.type) {
    case PRIVACY_POLICY_TYPES.SET_LANGUAGE_REGION:
      return {
        ...state,
        language: action.data.language,
        region: action.data.region,
      };

    default:
      return state;
  }
};

export default privacyReducer;
