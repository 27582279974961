import { LANGUAGE_TRANS } from "../constants/storage";
import { Cookie } from "../services/storage";

export const UtilsConverter = {
  convertSize: (size: number): string => {
    if (size !== undefined) {
      if (size < 1024) return `${size} B`;
      if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
      if (size < 1073741824) return `${(size / 1048576).toFixed(2)} MB`;
      return `${(size / 1073741824).toFixed(2)} GB`;
    }
    return "";
  },
};

export const getConsignmentDate = (kr: string, en_default: string) => {
  const lang = Cookie.get(LANGUAGE_TRANS);
  if (!lang) return en_default;
  if (lang === "ko") {
    return kr;
  } else {
    return en_default;
  }
};
export const UtilsParseCookieValue = (name: string) => {
  const cookieValue = Cookie.get(name);

  if (cookieValue) {
    return JSON.parse(cookieValue);
  } else {
    return null;
  }
};
