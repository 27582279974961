import React, { useEffect } from "react";

import { ErrorDetail } from "../../../components/ErrorDetail/ErrorDetail";

export const Error404: React.FC = () => {
  useEffect(() => {
    sessionStorage.setItem("invalidPage", "true");

    return () => {
      sessionStorage.removeItem("invalidPage");
    };
  }, []);

  return (
    <>
      <ErrorDetail variant="error404" />
    </>
  );
};
