import { useEffect, useState } from "react";

export const useIntersectionObserver = (
  containerRef: any,
  options: any,
  triggerOnce = false
) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(
    () => {
      const observer = new IntersectionObserver((entries: any) => {
        const [entry] = entries; // same as entries[0]
        setIsVisible(entry.isIntersecting);

        if (entry.isIntersecting && triggerOnce) {
          observer.unobserve(entry.target);
        }
      }, options);

      const currentRef = containerRef.current;
      if (currentRef) {
        observer.observe(currentRef);
      }
    },
    // eslint-disable-next-line
    [containerRef]
  );

  return isVisible;
};
