import { REQUEST_INFO } from "../constants/storage";
import { LocalStorage } from "../services/storage";
import { REQUEST_TYPE } from "../types/user";

export const UtilsRequest = {

  getRequestInfo: () => {
    let request = LocalStorage.get(REQUEST_INFO);
    return request !== null ? JSON.parse(request) : null;
  },

  setRequestInfo: (services: Array<string>, requestType: REQUEST_TYPE) => {
    const requestInfo = {
      requestType,
      services
    }
    LocalStorage.set(REQUEST_INFO, JSON.stringify(requestInfo));
  },

  removeRequestInfo: () => {
    LocalStorage.remove(REQUEST_INFO);
  }
};
