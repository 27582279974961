import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "../../../components/__common/Typography/Typography";
import "./Consignment.scss";
import { useLocation } from "react-router-dom";
import { ROUTES_CONSIGNMENT_PAGE } from "../../../configs/layout";

const DiagmonConsignment = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    const main = document.getElementById("main-body");
    if (!main) return;
    if (ROUTES_CONSIGNMENT_PAGE.includes(pathname)) {
      main.style.height = "100vh";
      main.style.marginTop = "0";
    } else {
      main.style.overflow = "auto";
    }
  }, [pathname]);
  return (
    <div className="consignment" data-testid="diagmon-consignment">
      <div className="table-container">
        <table cellSpacing="0">
          <tbody>
            <tr>
              <td colSpan={2}>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_COMPANY_KR")}
                </Typography>
              </td>
              <td colSpan={2}>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_COMPANY")}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_DIAGMON_CONSIGNMENT_SDS_KR")}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_DIAGMON_CONSIGNMENT_SDS_OPERATIONS_KR")}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_DIAGMON_CONSIGNMENT_SDS")}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_DIAGMON_CONSIGNMENT_SDS_OPERATIONS")}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans
                    i18nKey="PRIVACY_DIAGMON_CONSIGNMENT_AWS_KR"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a
                          href="https://aws.amazon.com/ko/compliance/sub-processors/"
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                    }}
                  />
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans i18nKey="PRIVACY_DIAGMON_CONSIGNMENT_AWS_OPERATIONS_KR" />
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans
                    i18nKey="PRIVACY_DIAGMON_CONSIGNMENT_AWS"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a
                          href="https://aws.amazon.com/ko/compliance/sub-processors/"
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                    }}
                  />
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans i18nKey="PRIVACY_DIAGMON_CONSIGNMENT_AWS_OPERATIONS" />
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      {/* <Typography variant="body2" className="consignment__date" weight="w500">
        <Trans
          i18nKey="PRIVACY_BDP_CONSIGNMENT_EFFECTIVE_DATE"
          values={{
            "%s": getConsignmentDate("2023년 9월 1일", "September 1, 2023"),
          }}
        />
      </Typography> */}
    </div>
  );
};

export default DiagmonConsignment;
