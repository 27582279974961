import {
  USER_SESSION_EXPIRED_TIME,
  USER_SESSION_ID,
} from "../constants/storage";
import { LocalStorage } from "../services/storage";

class IdleTimer {
  timeout: number;
  eventHandler: any;
  storageDiffHandler: any;
  interval: any;
  onTimeOut: () => void;
  onExpired: () => void;

  constructor({ timeout = 10, onTimeOut = () => {}, onExpired = () => {} }) {
    this.timeout = timeout;
    this.onTimeOut = onTimeOut;
    this.onExpired = onExpired;

    const expiredTime = parseInt(
      LocalStorage.get(USER_SESSION_EXPIRED_TIME) || "0",
      10
    );
    if (expiredTime > 0 && expiredTime < Date.now()) {
      if (this.onExpired) {
        this.onExpired();
      }
      this.cleanup();
      return;
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.storageDiffHandler = this.storageDiffChecker.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        LocalStorage.get(USER_SESSION_EXPIRED_TIME) || "0",
        10
      );

      if (expiredTime < Date.now()) {
        if (this.onTimeOut) {
          this.onTimeOut();
        }
        this.cleanup();
      }
    }, 1000);
  }

  updateExpiredTime() {
    LocalStorage.set(
      USER_SESSION_EXPIRED_TIME,
      Date.now() + this.timeout * 1000
    );
  }

  storageDiffChecker = (e: any) => {
    if (e.key === USER_SESSION_ID && e.oldValue && !e.newValue) {
      this.onTimeOut();
    }
  };

  tracker() {
    window.addEventListener("storage", this.storageDiffHandler);
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanup() {
    LocalStorage.remove(USER_SESSION_EXPIRED_TIME);
    clearInterval(this.interval);

    window.removeEventListener("storage", this.storageDiffHandler);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }
}

export default IdleTimer;
