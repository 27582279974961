import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Typography } from "../../Typography/Typography";
import "./Tab.scss";

interface TabSubComponents {
  Tab: React.FC<TabProps>;
}

interface Props {
  children: React.ReactNode;
  className?: string;
}
interface TabProps {
  title: string;
  name: string;
  count?: number;
  id?: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  type?: "1depth" | "2depth";
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, type: any) => void;
  testId?: string;
  radioTestId?: string;
  buttonTestid?: string;
}

const Tabs: React.FC<Props> & TabSubComponents = (props) => {
  const { children, className } = props;
  return (
    <>
      <div className={classNames("tab--container", className)}>{children}</div>
    </>
  );
};

const Tab: React.FC<TabProps> = (props) => {
  const {
    title,
    name,
    id,
    checked = false,
    disabled = false,
    type = "1depth",
    onChange,
    onClick,
    count,
    testId,
    radioTestId,
    className,
    buttonTestid,
  } = props;

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  const [selected, setSelected] = useState<boolean>(false);

  // const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event, type);
    }
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const wrapClass = classNames("tab", {
    [`tab--${type}`]: !!type,
    [`tab--${type}--selected`]: !!selected,
  });
  return (
    <>
      <label className={wrapClass} data-testid={testId}>
        <input
          data-testid={radioTestId}
          id={id}
          type="radio"
          checked={selected}
          disabled={disabled}
          onClick={handleOnClick}
          onChange={handleOnChange}
          name={name}
        ></input>
        <button
          data-testid={buttonTestid}
          className="tab__content"
          onClick={handleOnChange}
          disabled={disabled}
        >
          <Typography component="span" variant="body2" className={className}>
            {title}
          </Typography>
          {type === "2depth" && count && (
            <Typography
              component="span"
              variant="body2"
              className="tab--2depth__count"
            >
              {count < 10 ? "0" + count : count}
            </Typography>
          )}
        </button>
      </label>
    </>
  );
};

Tabs.Tab = Tab;

export default Tabs;
