import { BaseEntityRes } from "./base";
export interface MyDataRequest {
  type: "Download" | "Delete" | "Unsubscribe";
  items: [string];
}

export enum RequestType {
  DOWNLOAD = "A",
  DELETE = "E",
  UNSUBSCRIBE = "U",
}

export enum RequestStatus {
  EXPIRED = "E",
  PROCESSING = "T",
  COMPLETED = "C",
  COMPLETED_30DAYS = "E",
  REQUESTING = "W",
  CANCELED = "S",
  // FAILED = "F",
  DENIED = "D",
}

export interface MyDataRequestModalProps {
  statusLabel: string;
  title: string;
  icon: string;
  scheduleText?: string;
  subtitle: string;
}

export interface MyDataRequestModalTypeData {
  [RequestType.DOWNLOAD]: MyDataRequestModalProps;
  [RequestType.DELETE]: MyDataRequestModalProps;
  [RequestType.UNSUBSCRIBE]: MyDataRequestModalProps;
}
export interface MyDataRequestDetailsModal {
  [RequestStatus.REQUESTING]: MyDataRequestModalTypeData;
  [RequestStatus.COMPLETED]: MyDataRequestModalTypeData;
  [RequestStatus.PROCESSING]: MyDataRequestModalTypeData;
  [RequestStatus.EXPIRED]: MyDataRequestModalTypeData;
  [RequestStatus.CANCELED]: MyDataRequestModalTypeData;
  [RequestStatus.DENIED]: MyDataRequestModalTypeData;
}
export interface SummaryRequestDetails<SummarySubRequestDetails> {
  request_number: string;
  request_type: string;
  request_status: string;
  sub_request_count: number;
  sub_request_list: SummarySubRequestDetails[];
  user_request_date: string;
  file_expire?: string;
  complete_date?: string;
}
export interface SummarySubRequestDetails {
  sub_request_number?: string;
  sub_request_status: string;
  service_code: string;
  service_name?: string;
  service_icon: string;
  sub_complete_date?: string;
  attach_file?: [];
}

export interface SummaryEntity {
  count: {
    total_count: number;
    total_count_inprogress: number;
    total_count_requesting: number;
    total_count_completed: number;
  };
  download?: SummaryRequestDetails<SummarySubRequestDetails>;
  delete?: SummaryRequestDetails<SummarySubRequestDetails>;
  unsubscribe?: SummaryRequestDetails<SummarySubRequestDetails>;
}

export interface RequestEntity {
  request_number: string;
}

export interface RequestEntityReq {
  user_request_date: string;
  request_subtype: string;
  language: string;
  country: string;
  sa_country: string;
  service_count: number;
  service_list: { service_code: string }[];
  tz: string;
  geoip_country: string | null;
}

export interface SummaryEntityRes extends BaseEntityRes, SummaryEntity { }

export interface RequestEntityRes extends BaseEntityRes, RequestEntity { }


export interface ServiceRequestErrorEntity {
  isError: boolean;
  hasRendered: boolean;
  isSAUnverifiedError:boolean
}