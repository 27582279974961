export const RCODES = {
  SUCCESS: 0,
  INVALID_REQUEST_NUMBER: 4000011,
  GK_DUPLICATE_REQUEST: 6000006,
  INVALID_OTP: 4000067,
  MAX_OTP_RETRY: 4000068,
  GUEST_BANNED_15MINS: 4000071,
  GUEST_BANNED_30MINS: 4000072,
  GUEST_BANNED_1HR: 4000073,
  GUEST_BANNED_4HR: 4000074,
  GUEST_BANNED_1D: 4000075,
  FILE_PASSWORD_NOT_SENT: 4000076,
  USER_EMAIL_NOT_SET: 4000077,
  SA_UNVERIFIED_ERROR: 4000078,
};
