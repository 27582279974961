import React from "react";
import { Container } from "../../../../../components/Grid/Grid";
import { Svg, SVG_ICONS } from "../../../../../components/__common/Svg/Svg";
import { Typography } from "../../../../../components/__common/Typography/Typography";
import "./Summary.scss";
import { useWindowSize } from "../../../../../hooks/window-hooks";
import { LatestRequests } from "./LatestRequests";
import { SummaryEntity } from "../../../../../types/mydata";
import { t } from "i18next";
import { Trans } from "react-i18next";
import classNames from "classnames";

interface Props {
  summary: SummaryEntity;
  isFromChina?: boolean;
  isRestricted?: boolean;
  saCountry?: string;
}

export const Summary: React.FC<Props> = (props) => {
  const { isMobile } = useWindowSize();
  const summaryIconSize = isMobile ? 32 : 40;
  const {
    summary,
    isFromChina = false,
    isRestricted = false,
    saCountry,
  } = props;

  /** DISABLE SITE SURVEY: commented out for the meantime since feature toggling is still in progress
  const [surveyModalOpen, setSurveyModalOpen] = useState(false);
  const showSurveyCookie = Cookie.get(SHOW_SURVEY);
  useEffect(() => {
    if (Cookie.get(SHOW_SURVEY) === "true") {
      setSurveyModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSurveyCookie]);
  */
  return (
    <Container>
      {/* DISABLE SITE SURVEY: commented out for the meantime since feature toggling is still in progress 
       <SurveyModal isOpen={surveyModalOpen} /> 
       */}
      <div
        className={classNames("mydata-summary", {
          china: isFromChina || isRestricted,
        })}
      >
        <div className="mydata-summary__counts">
          <div className="mydata-summary__counts__item">
            <Svg
              icon={SVG_ICONS.MYDATA_REQUESTING}
              width={summaryIconSize}
              height={summaryIconSize}
            />
            <Typography
              variant="body2"
              className="mydata-summary__counts__item__text"
            >
              {t("PRIVACY_MYDATA_SUMMARY_LABEL_REQUESTED")}
            </Typography>
            <Typography
              variant="h3"
              className="mydata-summary__counts__item__count"
            >
              {isFromChina
                ? "00"
                : summary.count.total_count_requesting < 100
                ? ("0" + summary.count.total_count_requesting).slice(-2)
                : summary.count.total_count_requesting}
            </Typography>
          </div>
          <div className="spacer"></div>
          <div className="mydata-summary__counts__item">
            <Svg
              icon={SVG_ICONS.MYDATA_INPROGRESS}
              width={summaryIconSize}
              height={summaryIconSize}
            />
            <Typography
              variant="body2"
              className="mydata-summary__counts__item__text"
            >
              {t("PRIVACY_MYDATA_SUMMARY_LABEL_PROCESSING")}
            </Typography>
            <Typography
              variant="h3"
              className="mydata-summary__counts__item__count"
            >
              {isFromChina
                ? "00"
                : summary.count.total_count_inprogress < 100
                ? ("0" + summary.count.total_count_inprogress).slice(-2)
                : summary.count.total_count_inprogress}
            </Typography>
          </div>
          <div className="spacer"></div>
          <div className="mydata-summary__counts__item">
            <Svg
              icon={SVG_ICONS.MYDATA_COMPLETED}
              width={summaryIconSize}
              height={summaryIconSize}
            />
            <Typography
              variant="body2"
              className="mydata-summary__counts__item__text"
            >
              {t("PRIVACY_MYDATA_SUMMARY_LABEL_COMPLETED")}
            </Typography>
            <Typography
              variant="h3"
              className="mydata-summary__counts__item__count"
            >
              {isFromChina
                ? "00"
                : summary.count.total_count_completed < 100
                ? ("0" + summary.count.total_count_completed).slice(-2)
                : summary.count.total_count_completed}
            </Typography>
          </div>
        </div>

        {isFromChina || isRestricted ? (
          <div className="mydata-summary__services-container china">
            <Svg icon={SVG_ICONS.CHINA_NODATA} width={64} height={64} />
            <Typography variant="body1" weight="w500">
              {isFromChina && (
                <>
                  {t("PRIVACY_MYDATA_MSG_CHINA")} <br />
                  <Trans
                    i18nKey="PRIVACY_MYDATA_MSG_CHINA_CNPP_LINK"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a
                          target="_blank"
                          href="https://privacy.samsung.com.cn/"
                        />
                      ),
                    }}
                  />
                </>
              )}
              {isRestricted &&
                (saCountry === "USA" ? (
                  <Trans
                    i18nKey="PRIVACY_MYDATA_MSG_CHILDREN_USA"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a
                          target="_blank"
                          href="https://www.samsung.com/us/privacy"
                        />
                      ),
                    }}
                  />
                ) : (
                  t("PRIVACY_MYDATA_MSG_NOT_SUPPORTED")
                ))}
            </Typography>
          </div>
        ) : summary.count.total_count === 0 ? (
          <div className="mydata-summary__services-container empty_services">
            <Svg icon={SVG_ICONS.CHINA_NODATA} width={64} height={64} />
            <Typography variant="body1" weight="w500">
              {t("PRIVACY_MYDATA_SUMMARY_LABEL_NODATA")} <br />
            </Typography>
          </div>
        ) : (
          <LatestRequests summary={summary} />
        )}
      </div>
    </Container>
  );
};
