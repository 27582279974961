import React from "react";
import classNames from "classnames";
import { Typography } from "../../Typography/Typography";
import "./Checkbox.scss";
import { Svg } from "../../Svg/Svg";

export enum CBType {
  general = "general",
  selectBg = "selectBg",
  withoutSelectBg = "withoutSelectBg",
  withImg = "withImg",
}
interface Props {
  title: string;
  name: string;
  id?: string;
  type?: keyof typeof CBType;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, type?: any) => void;
  onClick?: () => void | undefined;
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement>,
    type?: any
  ) => void;
  className?: string;
  textClassName?: string;
  svgIcon?: string;
  testId?: string;
  checkboxTestid?: string;
}

export const Checkbox: React.FC<Props> = (props) => {
  const {
    id,
    title = "",
    name,
    disabled = false,
    onClick,
    onChange,
    onKeyPress,
    checked = false,
    type = CBType[CBType.general],
    className = "",
    textClassName = "",
    svgIcon = "",
    testId,
    checkboxTestid,
  } = props;

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event, type);
    }
  };

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyPress) {
      if (event.key === "Enter") {
        onKeyPress(event, type);
      }
    }
  };

  const wrapClass = classNames("checkbox", className, {
    [`checkbox--${type}`]: !!type,
    [`checkbox--${type}--selected`]: !!checked,
    [`checkbox--${type}--disabled`]: !!disabled,
    "no-text": !title,
  });
  return (
    <>
      {/* GENERAL Check box */}
      {type === CBType[CBType.general] && (
        <label className={wrapClass} data-testid={testId}>
          <input
            data-testid={checkboxTestid}
            id={id}
            type="checkbox"
            name={name}
            checked={checked}
            disabled={disabled}
            onClick={!disabled ? handleOnClick : undefined}
            onChange={!disabled ? handleOnChange : undefined}
            onKeyDown={!disabled ? handleOnKeyPress : undefined}
          ></input>
          <Typography
            component="span"
            variant="body2"
            className={textClassName}
          >
            {title}
          </Typography>
        </label>
      )}

      {type === CBType[CBType.selectBg] && (
        <label className={wrapClass} data-testid={testId}>
          <Typography
            component="span"
            variant="body2"
            className={textClassName}
          >
            {title}
          </Typography>
          <input
            id={id}
            type="checkbox"
            name={name}
            checked={checked}
            disabled={disabled}
            onClick={!disabled ? handleOnClick : undefined}
            onChange={!disabled ? handleOnChange : undefined}
          ></input>
        </label>
      )}

      {type === CBType[CBType.withoutSelectBg] && (
        <label className={wrapClass} data-testid={testId}>
          <Typography
            component="span"
            variant="body2"
            className={textClassName}
          >
            {title}
          </Typography>
          <input
            id={id}
            type="checkbox"
            name={name}
            checked={checked}
            disabled={disabled}
            onClick={!disabled ? handleOnClick : undefined}
            onChange={!disabled ? handleOnChange : undefined}
            onKeyDown={!disabled ? handleOnKeyPress : undefined}
          ></input>
          {svgIcon !== "" && <Svg icon={svgIcon} className="checkboxGoIcon" />}
        </label>
      )}

      {type === CBType[CBType.withImg] && (
        <label className={wrapClass} data-testid={testId}>
          <input
            id={id}
            type="checkbox"
            name={name}
            checked={checked}
            disabled={disabled}
            onClick={!disabled ? handleOnClick : undefined}
            onChange={!disabled ? handleOnChange : undefined}
          ></input>
          <Svg icon={svgIcon} className="checkboxImage" />
        </label>
      )}
    </>
  );
};
