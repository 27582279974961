import { HISTORY_REQUEST_TYPE } from "../types/history";
import { REQUEST_STATUS } from "./status";

export const serviceGroupData = [
  {
    id: "service-group-all",
    title: "PRIVACY_MYREQUEST_TAB_ALL",
    name: "service-group",
    value: HISTORY_REQUEST_TYPE.ALL,
  },
  {
    id: "service-group-download",
    title: "PRIVACY_MYREQUEST_TAB_DOWNLOAD",
    name: "service-group",
    value: HISTORY_REQUEST_TYPE.DOWNLOAD,
  },
  {
    id: "service-group-delete",
    title: "PRIVACY_MYREQUEST_TAB_DELETION",
    name: "service-group",
    value: HISTORY_REQUEST_TYPE.DELETE,
  },
  {
    id: "service-group-opt-out",
    title: "PRIVACY_MYREQUEST_TAB_OPTOUT",
    name: "service-group",
    value: HISTORY_REQUEST_TYPE.UNSUBSCRIBE,
  },
];

export const filterTabData = [
  {
    id: "filter-group-all",
    title: "PRIVACY_MYREQUEST_TAB_ALL",
    count: 1,
    type: "2depth",
    name: "filter-group",
  },
  {
    id: "filter-group-requesting",
    title: "PRIVACY_MYREQUEST_TAB_REQUESTED",
    count: 1,
    type: "2depth",
    name: "filter-group",
    value: REQUEST_STATUS.REQUESTING,
  },
  {
    id: "filter-group-processing",
    title: "PRIVACY_MYREQUEST_TAB_PROCESSING",
    count: 1,
    type: "2depth",
    name: "filter-group",
    value: REQUEST_STATUS.PROCESSING,
  },
  {
    id: "filter-group-completed",
    title: "PRIVACY_MYREQUEST_TAB_COMPLETED",
    count: 1,
    type: "2depth",
    name: "filter-group",
    value: REQUEST_STATUS.COMPLETED,
  },
  {
    id: "filter-group-expired",
    title: "PRIVACY_MYREQUEST_TAB_EXPIRED",
    count: 1,
    type: "2depth",
    name: "filter-group",
    value: REQUEST_STATUS.EXPIRED,
  },
  {
    id: "filter-group-canceled",
    title: "PRIVACY_MYREQUEST_TAB_CANCELED",
    count: 2,
    type: "2depth",
    name: "filter-group",
    value: REQUEST_STATUS.CANCELED,
  },
];
