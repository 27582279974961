import { ERegions, HubRegion, MapRegion } from "../types/landing-page";

export const MapRegions: MapRegion[] = [
  {
    code: ERegions.GLOBAL,
    name: "Global",
    rightsCode: "OTHERS",
  },
  {
    code: ERegions.US,
    name: "USA",
    rightsCode: "USA",
  },
  {
    code: ERegions.LATIN_AMERICA,
    name: "Latin America",
    rightsCode: "LATAM",
  },
  {
    code: ERegions.ASIA_PACIFIC,
    name: "S.E Asia & Oceania",
    rightsCode: "SAPP",
  },
  {
    code: ERegions.MENA,
    name: "MENA",
    rightsCode: "MENA",
  },
  // {
  //   code: "Europe",
  //   name: "EU",
  //   rightsCode: "EUROPE",
  // },
  // {
  //   code: "CN",
  //   name: "China",
  //   rightsCode: "CHINA",
  // },
  {
    code: ERegions.TURKIYE,
    name: "Türkiye",
    rightsCode: "TURKIYE",
  },
];

export const HubRegions: HubRegion[] = [
  // Asia pacific
  {
    code: ERegions.ASIA_PACIFIC,
    name: "Asia Pacific",
    countries: [
      {
        code: "AU",
        name: "Australia",
        displayName: "Australia",
        link: "https://sapp.privacy.samsung.com/au/home",
      },
      {
        code: "BD",
        name: "Bangladesh",
        displayName: "Bangladesh",
        link: null,
      },
      {
        code: "BT",
        name: "Bhutan",
        displayName: "Bhutan",
        link: null,
      },
      {
        code: "BN",
        name: "Brunei Darussalam",
        displayName: "Brunei Darussalam",
        link: null,
      },
      {
        code: "KH",
        name: "Cambodia",
        displayName: "Cambodia",
        link: null,
      },
      {
        code: "CN",
        name: "China",
        displayName: "中国大陆",
        link: "https://privacy.samsung.com.cn/",
      },
      {
        code: "CK",
        name: "Cook Islands",
        displayName: "Cook Islands",
        link: null,
      },
      {
        code: "FJ",
        name: "Fiji",
        displayName: "Fiji",
        link: null,
      },
      {
        code: "PF",
        name: "French Polynesia",
        displayName: "French Polynesia",
        link: null,
      },
      {
        code: "GE",
        name: "Georgia",
        displayName: "Georgia",
        link: null,
      },
      {
        code: "HK",
        name: "Hong Kong",
        displayName: "香港",
        link: null,
      },
      {
        code: "IN",
        name: "India",
        displayName: "India",
        link: null,
      },
      {
        code: "ID",
        name: "Indonesia",
        displayName: "Indonesia",
        link: "https://sapp.privacy.samsung.com/id/home",
      },
      {
        code: "JP",
        name: "Japan",
        displayName: "日本",
        link: null,
      },
      {
        code: "KZ",
        name: "Kazakhstan",
        displayName: "Казахстан",
        link: null,
      },
      {
        code: "KR",
        name: "Korea",
        displayName: "한국",
        link: null,
      },
      {
        code: "KI",
        name: "Kiribati",
        displayName: "Kiribati",
        link: null,
      },
      {
        code: "KG",
        name: "Kyrgyzstan",
        displayName: "Kyrgyzstan",
        link: null,
      },
      {
        code: "LA",
        name: "Lao People's Democratic Republic",
        displayName: "Lao People's Democratic Republic",
        link: null,
      },
      {
        code: "MO",
        name: "Macao",
        displayName: "Macao",
        link: null,
      },
      {
        code: "MY",
        name: "Malaysia",
        displayName: "Malaysia",
        link: "https://sapp.privacy.samsung.com/my/home",
      },
      {
        code: "MV",
        name: "Maldives",
        displayName: "Maldives",
        link: null,
      },
      {
        code: "MH",
        name: "Marshall Islands",
        displayName: "Marshall Islands",
        link: null,
      },
      {
        code: "FM",
        name: "Micronesia, Federated States Of",
        displayName: "Micronesia, Federated States Of",
        link: null,
      },
      {
        code: "MN",
        name: "Mongolia",
        displayName: "Монгол",
        link: null,
      },
      {
        code: "MM",
        name: "Myanmar",
        displayName: "Myanmar",
        link: null,
      },
      {
        code: "NR",
        name: "Nauru",
        displayName: "Nauru",
        link: null,
      },
      {
        code: "NP",
        name: "Nepal",
        displayName: "Nepal",
        link: null,
      },
      {
        code: "NC",
        name: "New Caledonia",
        displayName: "New Caledonia",
        link: null,
      },
      {
        code: "NZ",
        name: "New Zealand",
        displayName: "New Zealand",
        link: "https://sapp.privacy.samsung.com/nz/home",
      },
      {
        code: "NU",
        name: "Niue",
        displayName: "Niue",
        link: null,
      },
      {
        code: "PW",
        name: "Palau",
        displayName: "Palau",
        link: null,
      },
      {
        code: "PG",
        name: "Papua New Guinea",
        displayName: "Papua New Guinea",
        link: null,
      },
      {
        code: "PH",
        name: "Philippines",
        displayName: "Philippines",
        link: "https://sapp.privacy.samsung.com/ph/home",
      },
      {
        code: "WS",
        name: "Samoa",
        displayName: "Samoa",
        link: null,
      },
      {
        code: "SG",
        name: "Singapore",
        displayName: "Singapore",
        link: "https://sapp.privacy.samsung.com/sg/home",
      },
      {
        code: "SB",
        name: "Solomon Islands",
        displayName: "Solomon Islands",
        link: null,
      },
      {
        code: "LK",
        name: "Sri Lanka",
        displayName: "Sri Lanka",
        link: null,
      },
      {
        code: "TW",
        name: "Taiwan",
        displayName: "台灣",
        link: null,
      },
      {
        code: "TJ",
        name: "Tajikistan",
        displayName: "Tajikistan",
        link: null,
      },
      {
        code: "TH",
        name: "Thailand",
        displayName: "ประเทศไทย",
        link: "https://sapp.privacy.samsung.com/th/home",
      },
      {
        code: "TL",
        name: "Timor-Leste",
        displayName: "Timor-Leste",
        link: null,
      },
      {
        code: "TO",
        name: "Tonga",
        displayName: "Tonga",
        link: null,
      },
      {
        code: "TV",
        name: "Tuvalu",
        displayName: "Tuvalu",
        link: null,
      },
      {
        code: "UZ",
        name: "Uzbekistan",
        displayName: "Uzbekistan",
        link: null,
      },
      {
        code: "VU",
        name: "Vanuatu",
        displayName: "Vanuatu",
        link: null,
      },
      {
        code: "VN",
        name: "Vietnam",
        displayName: "Việt Nam",
        link: "https://sapp.privacy.samsung.com/vn/home",
      },
    ],
  },
  // Eu
  {
    code: ERegions.EUROPE,
    name: "Europe",
    countries: [
      {
        code: "AL",
        name: "Albania",
        displayName: "Shqipëri",
        link: null,
      },
      {
        code: "AD",
        name: "Andorra",
        displayName: "Andorra",
        link: null,
      },
      {
        code: "AM",
        name: "Armenia",
        displayName: "Armenia",
        link: null,
      },
      {
        code: "AT",
        name: "Austria",
        displayName: "Österreich",
        link: null,
      },
      {
        code: "AZ",
        name: "Azerbaijan",
        displayName: "Azerbaijan",
        link: null,
      },
      {
        code: "BY",
        name: "Belarus",
        displayName: "Belarus",
        link: null,
      },
      {
        code: "BE",
        name: "Belgium",
        displayName: "Belgium",
        link: null,
      },
      {
        code: "BA",
        name: "Bosnia and Herzegovina",
        displayName: "Bosnia And Herzegovina",
        link: null,
      },
      {
        code: "BG",
        name: "Bulgaria",
        displayName: "България",
        link: null,
      },
      {
        code: "HR",
        name: "Croatia",
        displayName: "Hrvatska",
        link: null,
      },
      {
        code: "CZ",
        name: "Czech",
        displayName: "Česká republika",
        link: null,
      },
      {
        code: "CY",
        name: "Cyprus",
        displayName: "Cyprus",
        link: null,
      },
      {
        code: "DK",
        name: "Denmark",
        displayName: "Danmark",
        link: null,
      },
      {
        code: "EE",
        name: "Estonia",
        displayName: "Estonian",
        link: null,
      },
      {
        code: "FK",
        name: "Falkland Islands",
        displayName: "Falkland Islands",
        link: null,
      },
      {
        code: "FO",
        name: "Faroe Islands",
        displayName: "Faroe Islands",
        link: null,
      },
      {
        code: "FI",
        name: "Finland",
        displayName: "Suomi",
        link: null,
      },
      {
        code: "FR",
        name: "France",
        displayName: "France",
        link: null,
      },
      {
        code: "MK",
        name: "FYROM",
        displayName: "Северна Македонија",
        link: null,
      },
      {
        code: "DE",
        name: "Germany",
        displayName: "Deutschland",
        link: null,
      },
      {
        code: "GI",
        name: "Gibraltar",
        displayName: "Gibraltar",
        link: null,
      },
      {
        code: "GR",
        name: "Greece",
        displayName: "Ελλάδα",
        link: null,
      },
      {
        code: "GL",
        name: "Greenland",
        displayName: "Greenland",
        link: null,
      },
      {
        code: "GP",
        name: "Guadeloupe",
        displayName: "Guadeloupe",
        link: null,
      },
      {
        code: "VA",
        name: "Holy See (Vatican City State)",
        displayName: "Holy See (Vatican City State) ",
        link: null,
      },
      {
        code: "HU",
        name: "Hungary",
        displayName: "Magyarország",
        link: null,
      },
      {
        code: "IS",
        name: "Iceland",
        displayName: "Iceland",
        link: null,
      },
      {
        code: "IE",
        name: "Ireland",
        displayName: "Ireland",
        link: null,
      },
      {
        code: "IT",
        name: "Italy",
        displayName: "Italia",
        link: null,
      },
      {
        code: "XK",
        name: "Kosovo",
        displayName: "Kosovo",
        link: null,
      },
      {
        code: "LV",
        name: "Latvia",
        displayName: "Latvija",
        link: null,
      },
      {
        code: "LI",
        name: "Liechtenstein",
        displayName: "Liechtenstein",
        link: null,
      },
      {
        code: "LT",
        name: "Lithuania",
        displayName: "Lietuva",
        link: null,
      },
      {
        code: "LU",
        name: "Luxembourg",
        displayName: "Luxembourg",
        link: null,
      },
      {
        code: "MT",
        name: "Malta",
        displayName: "Malta",
        link: null,
      },
      {
        code: "MD",
        name: "Moldova",
        displayName: "Moldova",
        link: null,
      },
      {
        code: "MC",
        name: "Monaco",
        displayName: "Monaco",
        link: null,
      },
      {
        code: "ME",
        name: "Montenegro",
        displayName: "Montenegro",
        link: null,
      },
      {
        code: "NL",
        name: "Netherlands",
        displayName: "Nederland",
        link: null,
      },
      {
        code: "NO",
        name: "Norway",
        displayName: "Norge",
        link: null,
      },
      {
        code: "PL",
        name: "Poland",
        displayName: "Polska",
        link: null,
      },
      {
        code: "PT",
        name: "Portugal",
        displayName: "Portugal",
        link: null,
      },
      {
        code: "RO",
        name: "Romania",
        displayName: "Romania",
        link: null,
      },
      {
        code: "RU",
        name: "Russia",
        displayName: "Россия",
        link: null,
      },
      {
        code: "PM",
        name: "Saint Pierre and Miquelon",
        displayName: "Saint Pierre And Miquelon",
        link: null,
      },
      {
        code: "SM",
        name: "San Marino",
        displayName: "San Marino",
        link: null,
      },
      {
        code: "RS",
        name: "Serbia",
        displayName: "Cрбија",
        link: null,
      },
      {
        code: "SK",
        name: "Slovakia",
        displayName: "Slovensko",
        link: null,
      },
      {
        code: "SI",
        name: "Slovenia",
        displayName: "Slovenija",
        link: null,
      },
      {
        code: "ES",
        name: "Spain",
        displayName: "Espana",
        link: null,
      },
      {
        code: "SE",
        name: "Sweden",
        displayName: "Sverige",
        link: null,
      },
      {
        code: "CH",
        name: "Switzerland",
        displayName: "Schweiz",
        link: null,
      },
      {
        code: "TR",
        name: "Türkiye",
        displayName: "Türkiye",
        link: "https://kvkk-portal.samsung.com",
      },
      {
        code: "UA",
        name: "Ukraina",
        displayName: "Україна",
        link: null,
      },
      {
        code: "GB",
        name: "United Kingdom",
        displayName: "UK",
        link: null,
      },
    ],
  },
  // Latin america
  {
    code: ERegions.LATIN_AMERICA,
    name: "Latin America",
    countries: [
      {
        code: "AI",
        name: "Anguilla",
        displayName: "Anguilla",
        link: null,
      },
      {
        code: "AG",
        name: "Antigua and Barbuda",
        displayName: "Antigua and Barbuda",
        link: null,
      },
      {
        code: "AR",
        name: "Argentina",
        displayName: "Argentina",
        link: "https://sdapla.privacy.samsung.com/privacy/ar",
      },
      {
        code: "AW",
        name: "Aruba",
        displayName: "Aruba",
        link: null,
      },
      {
        code: "BS",
        name: "Bahamas",
        displayName: "Bahamas & Caribbean Islands",
        link: null,
      },
      {
        code: "BB",
        name: "Barbados",
        displayName: "Barbados",
        link: null,
      },
      {
        code: "BZ",
        name: "Belize",
        displayName: "Belize",
        link: null,
      },
      {
        code: "BM",
        name: "Bermuda",
        displayName: "Bermuda",
        link: null,
      },
      {
        code: "BO",
        name: "Bolivia",
        displayName: "Bolivia",
        link: "https://sdapla.privacy.samsung.com/privacy/bo",
      },
      {
        code: "BR",
        name: "Brazil",
        displayName: "Brasil",
        link: "https://sdapla.privacy.samsung.com/privacy/br",
      },
      {
        code: "VG",
        name: "British Virgin Islands",
        displayName: "British Virgin Islands",
        link: null,
      },
      {
        code: "KY",
        name: "Cayman Islands",
        displayName: "Cayman Islands",
        link: null,
      },
      {
        code: "CL",
        name: "Chile",
        displayName: "Chile",
        link: "https://sdapla.privacy.samsung.com/privacy/cl",
      },
      {
        code: "CO",
        name: "Colombia",
        displayName: "Colombia",
        link: "https://sdapla.privacy.samsung.com/privacy/co",
      },
      {
        code: "CR",
        name: "Costa Rica",
        displayName: "Costa Rica",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "CU",
        name: "Cuba",
        displayName: "Cuba",
        link: null,
      },
      {
        code: "DM",
        name: "Dominica",
        displayName: "Dominica",
        link: null,
      },
      {
        code: "EC",
        name: "Ecuador",
        displayName: "Ecuador",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "SV",
        name: "El Salvador",
        displayName: "El Salvador",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "GF",
        name: "French Guiana",
        displayName: "French Guiana",
        link: null,
      },
      {
        code: "GD",
        name: "Grenada",
        displayName: "Grenada",
        link: null,
      },
      {
        code: "GT",
        name: "Guatemala",
        displayName: "Guatemala",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "GY",
        name: "Guyana",
        displayName: "Guyana",
        link: null,
      },
      {
        code: "HT",
        name: "Haiti",
        displayName: "Haiti",
        link: null,
      },
      {
        code: "HN",
        name: "Honduras",
        displayName: "Honduras",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "JM",
        name: "Jamaica",
        displayName: "Jamaica",
        link: null,
      },
      {
        code: "MX",
        name: "Mexico",
        displayName: "México",
        link: "https://sdapla.privacy.samsung.com/privacy/mx",
      },
      {
        code: "MS",
        name: "Montserrat",
        displayName: "Montserrat",
        link: null,
      },
      {
        code: "AN",
        name: "Netherlands Antilles",
        displayName: "Netherlands Antilles",
        link: null,
      },
      {
        code: "NI",
        name: "Nicaragua",
        displayName: "Nicaragua",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "PA",
        name: "Panama",
        displayName: "Panamá",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "PY",
        name: "Paraguay",
        displayName: "Paraguay",
        link: "https://sdapla.privacy.samsung.com/privacy/py",
      },
      {
        code: "PE",
        name: "Peru",
        displayName: "Perú",
        link: "https://sdapla.privacy.samsung.com/privacy/pe",
      },
      {
        code: "PR",
        name: "Puerto Rico",
        displayName: "Puerto Rico",
        link: null,
      },
      {
        code: "DO",
        name: "Dominican Republic",
        displayName: "República Dominicana",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "KN",
        name: "Saint Kitts and Nevis",
        displayName: "Saint Kitts and Nevis",
        link: null,
      },
      {
        code: "LC",
        name: "Saint Lucia",
        displayName: "Saint Lucia",
        link: null,
      },
      {
        code: "VC",
        name: "Saint Vincent and The Grenadines",
        displayName: "Saint Vincent And The Grenadines",
        link: null,
      },
      {
        code: "SR",
        name: "Suriname",
        displayName: "Suriname",
        link: null,
      },
      {
        code: "TT",
        name: "Trinidad and Tobago",
        displayName: "Trinidad And Tobago",
        link: null,
      },
      {
        code: "TC",
        name: "Turks and Caicos Islands",
        displayName: "Turks and Caicos Islands",
        link: null,
      },
      {
        code: "UY",
        name: "Uruguay",
        displayName: "Uruguay",
        link: "https://sdapla.privacy.samsung.com/privacy/uy",
      },
      {
        code: "VE",
        name: "Venezuela",
        displayName: "Venezuela",
        link: null,
      },
    ],
  },
  // MENA (Middle East and Africa)
  {
    code: ERegions.MENA,
    name: "MENA",
    countries: [
      {
        code: "AF",
        name: "Afghanistan",
        displayName: "Afghanistan",
        link: "https://samsung.menaprivacy.com/pk_en",
      },
      {
        code: "DZ",
        name: "Algeria",
        displayName: "Algérie",
        link: "https://samsung.menaprivacy.com/n_africa",
      },
      {
        code: "AO",
        name: "Angola",
        displayName: "Angola",
        link: null,
      },
      {
        code: "BH",
        name: "Bahrain",
        displayName: "البحرين",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "BJ",
        name: "Benin",
        displayName: "Bénin",
        link: null,
      },
      {
        code: "BW",
        name: "Botswana",
        displayName: "Botswana",
        link: null,
      },
      {
        code: "BF",
        name: "Burkina Faso",
        displayName: "Burkina Faso",
        link: null,
      },
      {
        code: "BI",
        name: "Burundi",
        displayName: "Burundi",
        link: null,
      },
      {
        code: "CV",
        name: "Cape Verde",
        displayName: "Cabo Verde",
        link: null,
      },
      {
        code: "CM",
        name: "Cameroon",
        displayName: "Cameroun",
        link: null,
      },
      {
        code: "CF",
        name: "Central African Republic",
        displayName: "République centrafricaine",
        link: null,
      },
      {
        code: "TD",
        name: "Chad",
        displayName: "Tchad",
        link: null,
      },
      {
        code: "KM",
        name: "Comoros",
        displayName: "Comores",
        link: null,
      },
      {
        code: "CG",
        name: "Congo",
        displayName: "Congo",
        link: null,
      },
      {
        code: "CI",
        name: "Cote D'Ivoire",
        displayName: "Côte d’Ivoire",
        link: null,
      },
      {
        code: "DJ",
        name: "Djibouti",
        displayName: "Djibouti",
        link: null,
      },
      {
        code: "CD",
        name: "DR Congo",
        displayName: "DR Congo",
        link: null,
      },

      {
        code: "EG",
        name: "Egypt",
        displayName: "مصر",
        link: "https://samsung.menaprivacy.com/eg_ar",
      },
      {
        code: "GQ",
        name: "Equatorial Guinea",
        displayName: "Equatorial Guinea",
        link: null,
      },
      {
        code: "ER",
        name: "Eritrea",
        displayName: "Eritrea",
        link: null,
      },
      {
        code: "ET",
        name: "Ethiopia",
        displayName: "Ethiopia",
        link: null,
      },
      {
        code: "GA",
        name: "Gabon",
        displayName: "Gabon",
        link: null,
      },
      {
        code: "GM",
        name: "Gambia",
        displayName: "Gambia",
        link: null,
      },
      {
        code: "GH",
        name: "Ghana",
        displayName: "Ghana",
        link: null,
      },
      {
        code: "GN",
        name: "Guinea",
        displayName: "République de Guinée",
        link: null,
      },
      {
        code: "GW",
        name: "Guinea-Bissau",
        displayName: "Guiné-Bissau",
        link: null,
      },
      {
        code: "IR",
        name: "Iran",
        displayName: "ایران",
        link: "https://samsung.menaprivacy.com/ir_fa",
      },
      {
        code: "IQ",
        name: "Iraq",
        displayName: "العراق",
        link: "https://samsung.menaprivacy.com/iq_ar",
      },
      {
        code: "IL",
        name: "Israel",
        displayName: "ישראל",
        link: "https://samsung.menaprivacy.com/il_he",
      },
      {
        code: "JO",
        name: "Jordan",
        displayName: "الأردن",
        link: "https://samsung.menaprivacy.com/jo_en",
      },
      {
        code: "KE",
        name: "Kenya",
        displayName: "Kenya",
        link: null,
      },
      {
        code: "KW",
        name: "Kuwait",
        displayName: "الكويت",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "LB",
        name: "Lebanon",
        displayName: "لبنان",
        link: "https://samsung.menaprivacy.com/jo_en",
      },
      {
        code: "LS",
        name: "Lesotho",
        displayName: "Lesotho",
        link: null,
      },
      {
        code: "LR",
        name: "Liberia",
        displayName: "Liberia",
        link: null,
      },
      {
        code: "LY",
        name: "Libya",
        displayName: "ليبيا",
        link: null,
      },
      {
        code: "MG",
        name: "Madagascar",
        displayName: "Madagascar",
        link: null,
      },
      {
        code: "MW",
        name: "Malawi",
        displayName: "Malawi",
        link: null,
      },
      {
        code: "ML",
        name: "Mali",
        displayName: "Mali",
        link: null,
      },
      {
        code: "MA",
        name: "Morocco",
        displayName: "Maroc",
        link: "https://samsung.menaprivacy.com/n_africa",
      },
      {
        code: "MR",
        name: "Mauritania",
        displayName: "Mauritanie",
        link: null,
      },
      {
        code: "MU",
        name: "Mauritius",
        displayName: "Île Maurice ",
        link: null,
      },

      {
        code: "MZ",
        name: "Mozambique",
        displayName: "Moçambique",
        link: null,
      },
      {
        code: "NA",
        name: "Namibia",
        displayName: "Namibia",
        link: null,
      },
      {
        code: "NE",
        name: "Niger",
        displayName: "Niger",
        link: null,
      },
      {
        code: "NG",
        name: "Nigeria",
        displayName: "Nigeria",
        link: null,
      },
      {
        code: "OM",
        name: "Oman",
        displayName: "عُمان",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "PK",
        name: "Pakistan",
        displayName: "Pakistan",
        link: "https://samsung.menaprivacy.com/pk_en",
      },
      {
        code: "QA",
        name: "Qatar",
        displayName: "قطر",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "RE",
        name: "Reunion",
        displayName: "Réunion",
        link: null,
      },
      {
        code: "RW",
        name: "Rwanda",
        displayName: "Rwanda",
        link: null,
      },
      {
        code: "SA",
        name: "Saudi Arabia",
        displayName: "المملكة العربية السعودية",
        link: "https://samsung.menaprivacy.com/sa_en",
      },
      {
        code: "ST",
        name: "Sao Tome and Principe",
        displayName: "Sao Tome and Principe",
        link: null,
      },
      {
        code: "SN",
        name: "Senegal",
        displayName: "Sénégal",
        link: null,
      },
      {
        code: "SC",
        name: "Seychelles",
        displayName: "Seychelles",
        link: null,
      },
      {
        code: "SL",
        name: "Sierra Leone",
        displayName: "Sierra Leone",
        link: null,
      },
      {
        code: "SO",
        name: "Somalia",
        displayName: "الصومال",
        link: null,
      },
      {
        code: "ZA",
        name: "South Africa",
        displayName: "South Africa",
        link: null,
      },
      {
        code: "SS",
        name: "South Sudan",
        displayName: "South Sudan",
        link: null,
      },
      {
        code: "SD",
        name: "Sudan",
        displayName: "السودان",
        link: null,
      },

      {
        code: "SZ",
        name: "Swaziland",
        displayName: "Swaziland",
        link: null,
      },
      {
        code: "SY",
        name: "Syrian Arab Republic",
        displayName: "Syrian Arab Republic",
        link: null,
      },
      {
        code: "TZ",
        name: "Tanzania, United Republic Of",
        displayName: "Tanzania",
        link: null,
      },
      {
        code: "TM",
        name: "Turkmenistan",
        displayName: "Turkmenistan",
        link: null,
      },
      {
        code: "TG",
        name: "Togo",
        displayName: "Togo",
        link: null,
      },
      {
        code: "TN",
        name: "Tunisia",
        displayName: "Tunisie",
        link: "https://samsung.menaprivacy.com/n_africa",
      },
      {
        code: "UG",
        name: "Uganda",
        displayName: "Uganda",
        link: null,
      },
      {
        code: "AE",
        name: "United Arab Emirates",
        displayName: "الإمارات العربية المتحدة",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "YE",
        name: "Yemen",
        displayName: "اليمن",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "ZM",
        name: "Zambia",
        displayName: "Zambia",
        link: null,
      },
      {
        code: "ZW",
        name: "Zimbabwe",
        displayName: "Zimbabwe",
        link: null,
      },
    ],
  },
  // NA
  {
    code: ERegions.NORTH_AMERICA,
    name: "North America",
    countries: [
      {
        code: "CA",
        name: "Canada",
        displayName: "Canada",
        link: null,
      },
      {
        code: "GU",
        name: "Guam",
        displayName: "Guam",
        link: null,
      },
      {
        code: "US",
        name: "USA",
        displayName: "USA",
        link: "https://www.samsung.com/us/privacy/ccpa",
      },
    ],
  },
];

export const countriesWithNoSiteAccess = ["CN"];

export const CountriesWithLocalPrivacy = HubRegions.map((region) =>
  region.countries.filter((country) => country.link !== null)
).flat();

export const CountriesWithoutLocalPrivacy = HubRegions.map((region) =>
  region.countries.filter((country) => country.link === null)
).flat();

export const EuropeCountriesExceptTurkey = HubRegions.map((region) =>
  region.countries.filter(
    (country) => country.code !== "TR" && region.name === "Europe"
  )
).flat();

//get regions that has country with local sites
export const getRegionsWithLocalSites = () => {
  // Get regions that only has countries w/ local site
  const regionsWithLocalSite = HubRegions.filter(
    (region) =>
      region.countries.filter(
        (country) => country.link !== null && country.name !== "China"
      ).length > 0
  );

  // Filter out the countries w/ local site from above regions
  // NOTE: All of Europe countries (except turkey) is excluded
  const regionsFilteredCountries = regionsWithLocalSite.map((region) => {
    if (region.name === "Europe") {
      return {
        ...region,
        countries: region.countries.filter((country) => country.code === "TR"),
      };
    }

    return {
      ...region,
      countries: region.countries.filter((country) => country.link !== null),
    };
  });
  return regionsFilteredCountries;
};

export const RegionPrivacyRights: any = {
  USA: {
    rights: ["ACCESS", "DELETE", "CORRECT", "DONOTSELL", "APPEAL"],
  },
  LATAM: {
    rights: ["ACCESS", "DELETE", "OPTOUT", "RESTRICT", "PORTABILITY"],
  },
  EUROPE: {
    rights: [
      "ACCESS",
      "DELETE",
      "CORRECT",
      "RESTRICT",
      "PORTABILITY",
      "OBJECT",
      "OPTOUT",
    ],
  },
  ASIA: {
    rights: [
      "ACCESS",
      "DELETE",
      "CORRECT",
      "RESTRICT",
      "PORTABILITY",
      "OBJECT",
      "OPTOUT",
    ],
  },
  TURKIYE: {
    rights: ["ACCESS", "DELETE", "CORRECT", "OBJECT", "OPTOUT"],
  },
  CHINA: {
    rights: ["ACCESS", "DELETE", "CORRECT", "RESTRICT", "OBJECT", "OPTOUT"],
  },

  // A.K.A Global
  OTHERS: {
    rights: ["ACCESS", "DELETE", "OPTOUT"],
  },
  SAPP: {
    rights: ["ACCESS", "DELETE", "OPTOUT"],
  },
  MENA: {
    rights: ["ACCESS", "DELETE", "OPTOUT"],
  },
};
