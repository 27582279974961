export interface Region {
  name: string;
  countries: Country[];
}

export interface Country {
  code: string;
  name: string;
  displayName?: string;
  languages: Language[];
}

export interface CountryWithRegion {
  code: string;
  name: string;
  languages: Language[];
  region: string;
}

export enum LANGUAGE_VIEW {
  PP_LANGUAGE = "PP_LANGUAGE",
  SITE_LANGUAGE = "SITE_LANGUAGE",
}

export interface Language {
  isRTL: boolean;
  code: string;
  trans: string;
  cUsLang?: string;
  languageView?: LANGUAGE_VIEW;
}

export interface SustainabilityLink {
  countryCode: string;
  links: {
    trans: string;
    link: string;
  }[];
}
