import { BaseEntityRes } from "./base";

export enum ServiceStatus {
  AVAILABLE = "A",
  BUSY = "B",
}

export interface ServiceEntity {
  service_code: string;
  service_status: string;
  service_name?: string;
  service_icon_url: string;
  service_description: string;
  service_description_download: string;
  service_description_delete: string;
  service_description_optout: string;
  service_deletion_warning: string;
  joined_date: number;
}

export interface ServicesEntityRes<ServiceEntity> extends BaseEntityRes {
  service_list: ServiceEntity[];
}

export interface Service {
  icon: string;
  name: string;
  description: string;
  description_download?: string;
  description_delete?: string;
  description_optout?: string;
  deletionWarning: string;
}
