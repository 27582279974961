import React from "react";
import { Container } from "../../../../../../components/Grid/Grid";
import { Typography } from "../../../../../../components/__common/Typography/Typography";
import { useTranslation } from "react-i18next";
import "./Default.scss";
import CountryCarousel from "../Carousels/CountryCarousel/CountryCarousel";

interface Props {
  setModalOpen: (value: boolean) => void;
}

const Default: React.FC<Props> = ({ setModalOpen }) => {
  const { t } = useTranslation();

  return (
    <Container className="default">
      <Container>
        <Typography variant="title2" className="default__title">
          {t("PRIVACY_HUB_MANAGE_TITLE")}
        </Typography>
        <Typography variant="body1" className="default__subtitle">
          {t("PRIVACY_HUB_MANAGE_DESC1")}
        </Typography>
        <Typography variant="body1" className="default__subtitle">
          {t("PRIVACY_HUB_MANAGE_DESC2")}
        </Typography>
      </Container>

      <CountryCarousel setModalOpen={setModalOpen} />
    </Container>
  );
};

export default Default;
