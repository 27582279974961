export interface MapRegion {
  code: string;
  name: string;
  rightsCode: string;
}

export interface HubRegion {
  code: string;
  name: string;
  countries: HubCountry[];
}

export interface HubCountry {
  code: string;
  name: string;
  displayName: string;
  link: string | null;
}

export enum ERegions {
  ASIA_PACIFIC = "AP", // A.K.A SAPP
  EUROPE = "EU",
  LATIN_AMERICA = "LA",
  MENA = "ME",
  NORTH_AMERICA = "NA",

  // For map
  GLOBAL = "GL",
  US = "US",
  TURKIYE = "TR",
  CHINA = "CN",
}
