import React, { useState } from "react";
import Modal from "../../../../components/__common/Modal/Modal";
import Accordion from "../../../../components/__common/Accordion/Accordion";
import { Col, Container, Row } from "../../../../components/Grid/Grid";
import {
  CBType,
  Checkbox,
} from "../../../../components/__common/_controls/Checkbox/Checkbox";
import "./LocalPrivacySiteModal.scss";
import triggerGAEvent from "../../../../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_LANDING_PAGE,
  EVENT_LANDING_PAGE_CHANGE_LOCALSITE,
} from "../../../../constants/analytics";
import { UtilsLocale } from "../../../../utils/UtilsLocale";
import {
  CountriesWithLocalPrivacy,
  HubRegions,
  getRegionsWithLocalSites,
} from "../../../../data/landing-page";
import { HubCountry } from "../../../../types/landing-page";
import { useLayout } from "../../../../hooks/layout-hooks";
import { Cookie } from "../../../../services/storage";
import { USER_SELECTED_COUNTRY } from "../../../../constants/storage";
import { useTranslation } from "react-i18next";

interface Props {
  onlyRegionsWithLocalSites?: boolean;
  show: boolean;
  onClickClose: () => void;
  overlay?: boolean;
}

const LocalPrivacySiteModal: React.FC<Props> = ({
  overlay = true,
  show,
  onClickClose,
  onlyRegionsWithLocalSites,
}) => {
  const { t } = useTranslation();
  const [selectedRegion, setSelectedRegion] = useState<any>();
  const { setSelectedCountry } = useLayout();
  const filteredRegionsWithLocalSites = getRegionsWithLocalSites();

  const handleGAEvent = (countryCode: string) => {
    const iso2Code = UtilsLocale.convertISO3toISO2(countryCode);
    const hasLocalSite = CountriesWithLocalPrivacy.some(
      (country) => country.code === iso2Code
    );

    const localsite = hasLocalSite ? "-localsite" : "";

    triggerGAEvent(
      EVENT_CATEGORY_LANDING_PAGE,
      `${EVENT_LANDING_PAGE_CHANGE_LOCALSITE}-${iso2Code}${localsite}`
    );
  };

  const handlePrivacySite = (country: HubCountry) => {
    setSelectedCountry(country);
    Cookie.set(USER_SELECTED_COUNTRY, JSON.stringify(country), {
      expires: 30,
    });
  };
  const hubRegionsModalData = onlyRegionsWithLocalSites
    ? filteredRegionsWithLocalSites
    : HubRegions;

  return (
    <Modal
      className="local-privacy-modal"
      show={show}
      overlay={overlay}
      testId="local-privacy-modal"
    >
      <Modal.Header
        headertestid="local-privacy-on-hide-button"
        onHide={onClickClose}
      >
        <Modal.Title>{t("PRIVACY_GRRS_SELECT_COUNTRY")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion>
          {hubRegionsModalData?.map((region, index) => (
            <Accordion.Item
              id={index === HubRegions.length - 1 ? "last-region" : region.name}
              title={region.name}
              key={region.name}
              testId={`hub-region-item`}
              activeItem={selectedRegion && selectedRegion.name}
              onClick={(isActive) => {
                if (isActive) {
                  setSelectedRegion(region);
                } else {
                  setSelectedRegion(undefined);
                }
              }}
            >
              <Container>
                <Row>
                  {region.countries.map((country) => {
                    const lastRegion = HubRegions[HubRegions.length - 1];
                    const lastCountry =
                      lastRegion.countries[lastRegion.countries.length - 1]
                        .name;

                    return (
                      <Col md={6} sm={12} key={country.code}>
                        <Checkbox
                          id={
                            country.name === lastCountry
                              ? "last-language"
                              : country.code
                          }
                          key={country.code}
                          title={country.displayName}
                          name="checkbox-withoutSelectBg"
                          type={CBType.withoutSelectBg}
                          onChange={() => {
                            if (onlyRegionsWithLocalSites && country.link) {
                              window.open(country.link, "_self");
                            }
                            handleGAEvent(country.code);
                            handlePrivacySite(country);
                            onClickClose();
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};

export default LocalPrivacySiteModal;
