export const DEFAULT_REGION = "America";
export const DEFAULT_COUNTRY = "USA";
export const DEFAULT_COUNTRY_CODE = "US";
export const DEFAULT_LANGUAGE = "English";
export const DEFAULT_LANGUAGE_CODE = "en";
export const DEFAULT_LANGUAGE_TRANS = "en";
export const DEFAULT_BROWSER_LANGUAGE = "en-US";

export const RTL_LANGUAGE_CODES = ["he", "fa", "ur", "ar"];
export const SUPPORTED_LANGUAGES = [
  "ar",
  "en-rUS",
  "de",
  "fr",
  "fr-rFR",
  "nl",
  "sr",
  "bg",
  "hr",
  "el",
  "cs",
  "da",
  "et-rEE",
  "fi",
  "hu",
  "ga",
  "it",
  "it-rIT",
  "lv",
  "lt",
  "mk-rMK",
  "nb",
  "pl",
  "pt-rPT",
  "ro",
  "sk",
  "sl",
  "es",
  "es-rES",
  "sv",
  "tr",
  "ru",
  "kk-rKZ",
  "uk",
  "uz-rUZ",
  "zh-rHK",
  "in",
  "ja",
  "ko",
  "ms-rMY",
  "zh-rCN",
  "th",
  "vi",
  "pt-rBR",
  "en",
  "en-rGB",
  // 'es-rUS',
  "fa",
  "iw",
  "ur-rPK",
  "zh-rTW",
];

export const STORAGE_SUPPORTED_COUNTRIES = [
  "AUT",
  "BEL",
  "BGR",
  "BRA",
  "CYP",
  "CZE",
  "DEU",
  "DNK",
  "ESP",
  "EST",
  "FIN",
  "FRA",
  "GBR",
  "GRC",
  "HRV",
  "HUN",
  "IRL",
  "ITA",
  "KOR",
  "LTU",
  "LUX",
  "LVA",
  "MLT",
  "NLD",
  "NOR",
  "POL",
  "PRT",
  "ROU",
  "SVK",
  "SVN",
  "SWE",
  "USA",
];

// ref: SamsungaccountPPLangPolicy.xlsx
// o - 32, minus Cyprus = 31
// 27 Countires From EU (except Cyprus) and plus 4 countries Albania, Norway, Serbia, Swiss
export const EU_COUNTRIES = [
  "HR",
  "SI",
  "AT",
  "LT",
  "LV",
  "EE",
  "NL",
  "BE",
  "LU",
  "CZ",
  "SK",
  "FR",
  "DE",
  "GR",
  // 'CY', Cyprus not included
  "HU",
  "IT",
  "MT",
  "SE",
  "FI",
  "DK",
  "IS",
  "PT",
  "PL",
  "RO",
  "BG",
  "ES",
  "LI",
  "GB",
  "IE",
  // Additional countries
  "AL", // Albania
  "NO", // Norway
  "RS", // Serbia,
  "CH", // Switzerland
  "XK", // Kosovo
];

// o (32) + x (22) = 54
// export const EU_COUNTRIES = [
//   'AT', 'BE', 'BG', 'HR', 'CY',
//   'CZ', 'DK', 'EE', 'FI', 'FR',
//   'DE', 'GR', 'HU', 'IE', 'IT',
//   'LV', 'LT', 'LU', 'MT', 'NL',
//   'PL', 'PT', 'RO', 'SK', 'SI',
//   'ES', 'SE', 'GB', 'GF', 'GP',
//   'MQ', 'ME', 'YT', 'RE', 'MF',
//   'GI', 'AX', 'PM', 'GL', 'BL',
//   'SX', 'AW', 'CW', 'WF', 'PF',
//   'NC', 'TF', 'AI', 'BM', 'IO',
//   'VG', 'KY', 'FK', 'MS', 'PN',
//   'SH', 'GS', 'TC', 'AD', 'LI',
//   'MC', 'SM', 'VA', 'JE', 'GG',
//   'GI', 'CH'
// ];

export const US_COUNTRIES = [
  "AI",
  "AG",
  "AR",
  "AW",
  "BS",
  "BB",
  "BZ",
  "BM",
  "BO",
  "BR",
  "VG",
  "CA",
  "KY",
  "CL",
  "CO",
  "CR",
  "CU",
  "DM",
  "DO",
  "EC",
  "SV",
  "GF",
  "GD",
  "GT",
  "GY",
  "HT",
  "HN",
  "JM",
  "MX",
  "MS",
  "AN",
  "NI",
  "PA",
  "PY",
  "PE",
  "PR",
  "KN",
  "LC",
  "PM",
  "VC",
  "SR",
  "TT",
  "TC",
  "UY",
  "US",
  "VE",
];

export const USA_COUNTRIES = [
  "ABW",
  "AIA",
  "ANT",
  "ARG",
  "ATG",
  "BHS",
  "BLZ",
  "BMU",
  "BOL",
  "BRA",
  "BRB",
  "CAN",
  "CHL",
  "COL",
  "CRI",
  "CUB",
  "DMA",
  "DOM",
  "ECU",
  "GRD",
  "GUF",
  "GTM",
  "GUY",
  "HND",
  "HTI",
  "JAM",
  "KNA",
  "CYM",
  "LCA",
  "MSR",
  "MEX",
  "NIC",
  "PAN",
  "PER",
  "SPM",
  "PRI",
  "PRY",
  "SUR",
  "SLV",
  "TCA",
  "TTO",
  "USA",
  "URY",
  "VCT",
  "VEN",
  "VGB",
];
export const SAMSUNG_O_COUNTRIES = ["FR", "IT", "PA"];
export const REQUEST_FORBIDDEN_COUNTRIES = ["CHN"];
export const REQUEST_RESTRICTED_13_COUNTRIES = ["USA"];
export const REQUEST_RESTRICTED_14_COUNTRIES = ["KOR"];
export const GEOIP_CHINA_COUNTRY_CODE = "CN";

export const TTS_VOICES_MAP: { [key: string]: string } = {
  "sq-rAL": "Microsoft Anila Online (Natural) - Albanian (Albania)",
  ar: "Microsoft Fatima Online (Natural) - Arabic (United Arab Emirates)",
  bg: "Microsoft Kalina Online (Natural) - Bulgarian (Bulgaria)",
  "zh-rCN": "Microsoft Xiaoyi Online (Natural) - Chinese (Mainland)",
  "zh-rHK":
    "Microsoft HiuGaai Online (Natural) - Chinese (Cantonese Traditional)",
  "zh-rTW": "Microsoft HsiaoChen Online (Natural) - Chinese (Taiwan)",
  hr: "Microsoft Gabrijela Online (Natural) - Croatian (Croatia)",
  cs: "Microsoft Vlasta Online (Natural) - Czech (Czech)",
  da: "Microsoft Christel Online (Natural) - Danish (Denmark)",
  nl: "Microsoft Colette Online (Natural) - Dutch (Netherlands)",
  en: "Microsoft Zira - English (United States)",
  "en-rGB": "Microsoft Libby Online (Natural) - English (United Kingdom)",
  "en-rUS": "Microsoft Zira - English (United States)",
  "et-rEE": "Microsoft Anu Online (Natural) - Estonian (Estonia)",
  fa: "Microsoft Dilara Online (Natural) - Persian (Iran)",
  fi: "Microsoft Noora Online (Natural) - Finnish (Finland)",
  fr: "Microsoft Sylvie Online (Natural) - French (Canada)",
  "fr-rCA": "Microsoft Sylvie Online (Natural) - French (Canada)",
  de: "Microsoft Amala Online (Natural) - German (Germany)",
  el: "Microsoft Athina Online (Natural) - Greek (Greece)",
  iw: "Microsoft Hila Online (Natural) - Hebrew (Israel)",
  hu: "Microsoft Noemi Online (Natural) - Hungarian (Hungary)",
  id: "Microsoft Gadis Online (Natural) - Indonesian (Indonesia)",
  it: "Microsoft Elsa Online (Natural) - Italian (Italy)",
  ja: "Microsoft Nanami Online (Natural) - Japanese (Japan)",
  "kk-rKZ": "Microsoft Aigul Online (Natural) - Kazakh (Kazakhstan)",
  ko: "Microsoft SunHi Online (Natural) - Korean (Korea)",
  lv: "Microsoft Everita Online (Natural) - Latvian (Latvia)",
  lt: "Microsoft Ona Online (Natural) - Lithuanian (Lithuania)",
  "mk-rMK":
    "Microsoft Marija Online (Natural) - Macedonian (Republic of North Macedonia)",
  "ms-rMY": "Microsoft Yasmin Online (Natural) - Malay (Malaysia)",
  "my-rMM": "Microsoft Nilar Online (Natural) - Burmese (Myanmar)",
  nb: "Microsoft Pernille Online (Natural) - Norwegian (Bokmål, Norway)",
  pl: "Microsoft Zofia Online (Natural) - Polish (Poland)",
  "pt-rPT": "Microsoft Raquel Online (Natural) - Portuguese (Portugal)",
  "pt-rBR": "Microsoft Francisca Online (Natural) - Portuguese (Brazil)",
  ro: "Microsoft Alina Online (Natural) - Romanian (Romania)",
  ru: "Microsoft Svetlana Online (Natural) - Russian (Russia)",
  sr: "Microsoft Sophie Online (Natural) - Serbian (Serbia)",
  sk: "Microsoft Viktoria Online (Natural) - Slovak (Slovakia)",
  sl: "Microsoft Petra Online (Natural) - Slovenian (Slovenia)",
  "es-rES": "Microsoft Elvira Online (Natural) - Spanish (Spain)",
  "es-rUS": "Microsoft Paloma Online (Natural) - Spanish (United States)",
  sv: "Microsoft Lorena Online (Natural) - Spanish (El Salvador)",
  th: "Microsoft Premwadee Online (Natural) - Thai (Thailand)",
  tr: "Microsoft Emel Online (Natural) - Turkish (Turkey)",
  uk: "Microsoft Polina Online (Natural) - Ukrainian (Ukraine)",
  "ur-rPK": "Microsoft Uzma Online (Natural) - Urdu (Pakistan)",
  "uz-rUZ": "Microsoft Madina Online (Natural) - Uzbek (Uzbekistan)",
  vi: "Microsoft HoaiMy Online (Natural) - Vietnamese (Vietnam)",
  "fr-rFR": "Microsoft Denise Online (Natural) - French (France)",
  "it-rIT": "Microsoft Elsa Online (Natural) - Italian (Italy)",
  es: "Microsoft Paloma Online (Natural) - Spanish (United States)",
};

export const ISO3CountryCodesMap: { [key: string]: string } = {
  AFG: "AF",
  ALB: "AL",
  DZA: "DZ",
  ASM: "AS",
  AND: "AD",
  AGO: "AO",
  AIA: "AI",
  ATA: "AQ",
  ATG: "AG",
  ARG: "AR",
  ARM: "AM",
  ABW: "AW",
  AUS: "AU",
  AUT: "AT",
  AZE: "AZ",
  BHS: "BS",
  BHR: "BH",
  BGD: "BD",
  BRB: "BB",
  BLR: "BY",
  BEL: "BE",
  BLZ: "BZ",
  BEN: "BJ",
  BMU: "BM",
  BTN: "BT",
  BOL: "BO",
  BES: "BQ",
  BIH: "BA",
  BWA: "BW",
  BVT: "BV",
  BRA: "BR",
  IOT: "IO",
  BRN: "BN",
  BGR: "BG",
  BFA: "BF",
  BDI: "BI",
  CPV: "CV",
  KHM: "KH",
  CMR: "CM",
  CAN: "CA",
  CYM: "KY",
  CAF: "CF",
  TCD: "TD",
  CHL: "CL",
  CHN: "CN",
  CXR: "CX",
  CCK: "CC",
  COL: "CO",
  COM: "KM",
  COD: "CD",
  COG: "CG",
  COK: "CK",
  CRI: "CR",
  HRV: "HR",
  CUB: "CU",
  CUW: "CW",
  CYP: "CY",
  CZE: "CZ",
  CIV: "CI",
  DNK: "DK",
  DJI: "DJ",
  DMA: "DM",
  DOM: "DO",
  ECU: "EC",
  EGY: "EG",
  SLV: "SV",
  GNQ: "GQ",
  ERI: "ER",
  EST: "EE",
  SWZ: "SZ",
  ETH: "ET",
  FLK: "FK",
  FRO: "FO",
  FJI: "FJ",
  FIN: "FI",
  FRA: "FR",
  GUF: "GF",
  PYF: "PF",
  ATF: "TF",
  GAB: "GA",
  GMB: "GM",
  GEO: "GE",
  DEU: "DE",
  GHA: "GH",
  GIB: "GI",
  GRC: "GR",
  GRL: "GL",
  GRD: "GD",
  GLP: "GP",
  GUM: "GU",
  GTM: "GT",
  GGY: "GG",
  GIN: "GN",
  GNB: "GW",
  GUY: "GY",
  HTI: "HT",
  HMD: "HM",
  VAT: "VA",
  HND: "HN",
  HKG: "HK",
  HUN: "HU",
  ISL: "IS",
  IND: "IN",
  IDN: "ID",
  IRN: "IR",
  IRQ: "IQ",
  IRL: "IE",
  IMN: "IM",
  ISR: "IL",
  ITA: "IT",
  JAM: "JM",
  JPN: "JP",
  JEY: "JE",
  JOR: "JO",
  KAZ: "KZ",
  KEN: "KE",
  KIR: "KI",
  PRK: "KP",
  KOR: "KR",
  KWT: "KW",
  KGZ: "KG",
  LAO: "LA",
  LVA: "LV",
  LBN: "LB",
  LSO: "LS",
  LBR: "LR",
  LBY: "LY",
  LIE: "LI",
  LTU: "LT",
  LUX: "LU",
  MAC: "MO",
  MDG: "MG",
  MWI: "MW",
  MYS: "MY",
  MDV: "MV",
  MLI: "ML",
  MLT: "MT",
  MHL: "MH",
  MTQ: "MQ",
  MRT: "MR",
  MUS: "MU",
  MYT: "YT",
  MEX: "MX",
  FSM: "FM",
  MDA: "MD",
  MCO: "MC",
  MNG: "MN",
  MNE: "ME",
  MSR: "MS",
  MAR: "MA",
  MOZ: "MZ",
  MMR: "MM",
  NAM: "NA",
  NRU: "NR",
  NPL: "NP",
  NLD: "NL",
  NCL: "NC",
  NZL: "NZ",
  NIC: "NI",
  NER: "NE",
  NGA: "NG",
  NIU: "NU",
  NFK: "NF",
  MNP: "MP",
  NOR: "NO",
  OMN: "OM",
  PAK: "PK",
  PLW: "PW",
  PSE: "PS",
  PAN: "PA",
  PNG: "PG",
  PRY: "PY",
  PER: "PE",
  PHL: "PH",
  PCN: "PN",
  POL: "PL",
  PRT: "PT",
  PRI: "PR",
  QAT: "QA",
  MKD: "MK",
  ROU: "RO",
  RUS: "RU",
  RWA: "RW",
  REU: "RE",
  BLM: "BL",
  SHN: "SH",
  KNA: "KN",
  LCA: "LC",
  MAF: "MF",
  SPM: "PM",
  VCT: "VC",
  WSM: "WS",
  SMR: "SM",
  STP: "ST",
  SAU: "SA",
  SEN: "SN",
  SRB: "RS",
  SYC: "SC",
  SLE: "SL",
  SGP: "SG",
  SXM: "SX",
  SVK: "SK",
  SVN: "SI",
  SLB: "SB",
  SOM: "SO",
  ZAF: "ZA",
  SGS: "GS",
  SSD: "SS",
  ESP: "ES",
  LKA: "LK",
  SDN: "SD",
  SUR: "SR",
  SJM: "SJ",
  SWE: "SE",
  CHE: "CH",
  SYR: "SY",
  TWN: "TW",
  TJK: "TJ",
  TZA: "TZ",
  THA: "TH",
  TLS: "TL",
  TGO: "TG",
  TKL: "TK",
  TON: "TO",
  TTO: "TT",
  TUN: "TN",
  TUR: "TR",
  TKM: "TM",
  TCA: "TC",
  TUV: "TV",
  UGA: "UG",
  UKR: "UA",
  ARE: "AE",
  GBR: "GB",
  UMI: "UM",
  USA: "US",
  URY: "UY",
  UZB: "UZ",
  VUT: "VU",
  VEN: "VE",
  VNM: "VN",
  VGB: "VG",
  VIR: "VI",
  WLF: "WF",
  ESH: "EH",
  YEM: "YE",
  ZMB: "ZM",
  ZWE: "ZW",
  ALA: "AX",
};

export const SUPPORTED_PPS = [
  "ar-AE",
  "ar-BH",
  "ar-EG",
  "ar-IQ",
  "ar-JO",
  "ar-KW",
  "ar-LB",
  "ar-LY",
  "ar-MA",
  "ar-OM",
  "ar-QA",
  "ar-SA",
  "ar-SY",
  "ar-YE",
  "bg-BG",
  "cs-CZ",
  "da-DK",
  "de-AT",
  "de-CH",
  "de-DE",
  "default",
  "el-CY",
  "el-GR",
  "en-AD", // EU
  "en-AL", // EU
  "en-GB", // EU
  "en-IE", // EU
  "en-IS", // EU
  "en-LI", // EU
  "en-MK", // EU
  "en-MT", // EU
  "en-XK", // EU
  "en-FK", // EU
  "en-FO", // EU
  "en-GI", // EU
  "en-GL", // EU
  "en-GP", // EU
  "en-MC", // EU
  "en-PM", // EU
  "en-SM", // EU
  "en-TR", // EU
  "es-AR",
  "es-BO",
  "es-CL",
  "es-CO",
  "es-CR",
  "es-DO",
  "es-EC",
  "es-ES",
  "es-GT",
  "es-HN",
  "es-NI",
  "es-PA",
  "es-PE",
  "es-PR",
  "es-PY",
  "es-SV",
  "es-UY",
  "es-VE",
  "es_MX-MX",
  "et-EE",
  "fa-IR",
  "fi-FI",
  "fr-BE",
  "fr-CD",
  "fr-CG",
  "fr-CH",
  "fr-CI",
  "fr-CM",
  "fr-DZ",
  "fr-FR",
  "fr-LU",
  "fr-MA",
  "fr-MR",
  "fr-SN",
  "fr-TN",
  "fr_CA-CA",
  "gd-IE",
  "he-IL",
  "hr-BA",
  "hr-HR",
  "hu-HU",
  "hy-AM",
  "id-ID",
  "it-CH",
  "it-IT",
  "ja-JP",
  "kk-KZ",
  "ko-KR",
  "lt-LT",
  "lv-LV",
  "mk-MK",
  "ms-MY",
  "my-MM",
  "nl-BE",
  "nl-NL",
  "no-NO",
  "pl-PL",
  "pt-AO",
  "pt-MZ",
  "pt-PT",
  "pt_BR-BR",
  "ro-RO",
  "ru-AM",
  "ru-AZ",
  "ru-BY",
  "ru-GE",
  "ru-KZ",
  "ru-MD",
  "ru-UZ",
  "ru_RU-RU",
  "sk-SK",
  "sl-SI",
  "sq-AL",
  "sr-ME",
  "sr-RS",
  "sv-SE",
  "th-TH",
  "tr-TR",
  "uk-UA",
  "ur-PK",
  "vi-VN",
  "zh_CN-CN",
  "zh_HK-HK",
  "zh_MO-MO",
  "zh_TW-TW",
];

export const EUR_COUNTRIES = [
  "ALB",
  "AND",
  "AUT",
  "BEL",
  "BIH",
  "BGR",
  "HRV",
  "CYP",
  "CZE",
  "DNK",
  "EST",
  "FRO",
  "FIN",
  "FRA",
  "DEU",
  "GIB",
  "GRC",
  "GRL",
  "VAT",
  "HUN",
  "ISL",
  "IRL",
  "ITA",
  "XKX",
  "LVA",
  "LIE",
  "LTU",
  "LUX",
  "MLT",
  "MCO",
  "MNE",
  "NLD",
  "MKD",
  "NOR",
  "POL",
  "PRT",
  "ROU",
  "SMR",
  "SRB",
  "SVK",
  "SVN",
  "ESP",
  "SWE",
  "CHE",
  "GBR",
];
