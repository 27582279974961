import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";

import { Image } from "../Image";
import { Svg, SVG_ICONS } from "../Svg/Svg";
import { Typography } from "../Typography/Typography";
import "./Carousel.scss";
import { useWindowSize } from "../../../hooks/window-hooks";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import { ServiceEntity } from "../../../types/services";

interface Props {
  services: ServiceEntity[];
  onClickRemoveService: (serviceCode: ServiceEntity) => void;
  testId?: string;
}

export const ServicesCarousel: React.FC<Props> = (props) => {
  const { windowSize } = useWindowSize();
  const [isDown, setisDown] = useState<boolean>(false);
  const [startX, setstartX] = useState<number>(0);
  const [scrollLeft, setscrollLeft] = useState<number>(0);
  const [isMaxLeft, setMaxLeft] = useState<boolean>(true);
  const [isMaxRight, setMaxRight] = useState<boolean>(false);

  const servicesRef = useRef<HTMLDivElement>(null);
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const onMouseDown = (e: any) => {
    setisDown(true);
    setstartX(e.pageX - servicesRef!.current!.offsetLeft);
    setscrollLeft(servicesRef!.current!.scrollLeft);
  };

  const onMouseUp = (e: any) => {
    setisDown(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const onMouseLeave = (e: any) => {
    e.preventDefault();
    setisDown(false);
  };

  const onMouseMove = (e: any) => {
    if (!isDown) return;
    e.preventDefault();
    const x: number = e.pageX - servicesRef!.current!.offsetLeft;
    const walk = x - startX;
    servicesRef!.current!.scrollLeft = scrollLeft - walk;
  };

  const onClickRight = (e: any) => {
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      servicesRef!.current!.scrollLeft += 10;
      scrollAmount += 10;
      if (scrollAmount >= 300) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };

  const onClickLeft = (e: any) => {
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      servicesRef!.current!.scrollLeft -= 10;
      scrollAmount += 10;
      if (scrollAmount >= 300) {
        window.clearInterval(slideTimer);
      }
    }, 10);
  };

  useEffect(
    () => {
      refreshArrowButtons();
    },
    // eslint-disable-next-line
    [props.services, windowSize, layoutState.isRTL]
  );

  const refreshArrowButtons = () => {
    const posLeft = servicesRef!.current?.scrollLeft || 0;
    const length = servicesRef!.current?.scrollWidth || 0;
    const right = servicesRef!.current?.clientWidth || 0;

    if (layoutState.isRTL) {
      setMaxRight(posLeft >= 0);
      setMaxLeft(length <= parseInt((posLeft * -1).toString()) + right + 10);
    } else {
      setMaxLeft(posLeft === 0);
      setMaxRight(length <= parseInt(posLeft.toString()) + right + 10);
    }
  };

  return (
    <div
      className={classNames("service-carousel-container", {
        "max-left": isMaxLeft,
        "max-right": isMaxRight,
      })}
      data-testid={props.testId}
    >
      <Svg
        icon={SVG_ICONS.CAROUSEL_ARROW}
        width={16}
        height={16}
        className="service-carousel-container__arrow-left"
        onClick={onClickLeft}
        testId="onclick-left"
      />
      <div
        data-testid="service-carousel"
        className="service-carousel"
        ref={servicesRef}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onScroll={refreshArrowButtons}
      >
        {props.services.map((service) => {
          return (
            <ServiceCarouselItem
              key={service.service_code}
              icon={service.service_icon_url}
              name={service.service_name ? service.service_name : ""}
              onClickRemoveService={() => {
                props.onClickRemoveService(service);
              }}
            />
          );
        })}
      </div>
      <Svg
        testId="onclick-right"
        icon={SVG_ICONS.CAROUSEL_ARROW}
        width={16}
        height={16}
        className="service-carousel-container__arrow-right"
        onClick={onClickRight}
      />
    </div>
  );
};

interface ItemProps {
  icon: string;
  name: string;
  onClickRemoveService: () => void;
}

export const ServiceCarouselItem: React.FC<ItemProps> = (props) => {
  const { name, icon, onClickRemoveService } = props;

  return (
    <div className="service-carousel__item">
      <div className="service-carousel__item__icon">
        <Image image={icon} width={24} height={24} />
      </div>
      <div className="service-carousel__item__name">
        <Typography
          className="service-carousel__item__name__text"
          variant="body3"
        >
          {name}
        </Typography>
      </div>
      <div
        className="service-carousel__item__close"
        onClick={onClickRemoveService}
        data-testid="sc-rm-svc-btn"
      >
        <Svg icon={SVG_ICONS.DRAWER_CLOSE} width={7.9} height={7.9} />
        <button></button>
      </div>
    </div>
  );
};
