import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import { ROUTES } from "../../configs/routes";
import { useDrawerHooks } from "../../hooks/drawer-hooks";
import { Col, Container, Row } from "../Grid/Grid";
import { Image } from "../__common/Image/Image";
import { Svg, SVG_ICONS } from "../__common/Svg/Svg";
import { Drawer } from "./Drawer";
import "./HeaderMobile.scss";
import { UtilsUser } from "../../utils/UtilsUser";
import { useAuth } from "../../hooks/auth-hooks";
import { useUserHooks } from "../../hooks/user-hooks";
import { API_ENDPOINTS, API_SA_BASE_URL } from "../../constants/api";
import triggerGAEvent from "../../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_HEADER,
  EVENT_HEADER_ACTION_PRIVACY_LOGO,
} from "../../constants/analytics";

import { useLoginOptionHooks } from "../../hooks/login-option-hooks";

export const HeaderMobile: React.FC = () => {
  const { logout } = useAuth();
  const { userData } = useUserHooks();
  const { toggleDrawer, isDrawerOpen } = useDrawerHooks();
  const { pathname } = useLocation();
  const { handleLoginBasedOnGeoIp } = useLoginOptionHooks();

  const handleBurgerMenuClick = () => {
    toggleDrawer();
  };

  return (
    <>
      <Container
        className={classNames("header-mobile", {
          underlined: pathname !== ROUTES.ROOT,
        })}
      >
        <Container>
          <Row className="header-mobile__row">
            <Col className="header-mobile__logo">
              <div className="header-mobile__logo__container">
                <Link
                  data-testid="link-to-root-mobile"
                  to={ROUTES.ROOT}
                  onClick={() =>
                    triggerGAEvent(
                      EVENT_CATEGORY_HEADER,
                      EVENT_HEADER_ACTION_PRIVACY_LOGO
                    )
                  }
                >
                  <Svg icon={SVG_ICONS.LOGO} width={124} height={16} />
                </Link>
              </div>
            </Col>
            <Col className="header-mobile__profile">
              <div
                className={classNames("header-mobile__profile__link", {
                  loggedIn: UtilsUser.isUserLoggedIn(),
                })}
              >
                {UtilsUser.isUserLoggedIn() ? (
                  userData.profileImageUrl ? (
                    <Image
                      testId="user-profile-image"
                      image={userData.profileImageUrl}
                      name={userData.name}
                      width={28}
                      height={28}
                      className="logged-in"
                      onClick={handleBurgerMenuClick}
                    />
                  ) : (
                    <Svg
                      icon={SVG_ICONS.PROFILE}
                      className="profile-placeholder"
                      width={28}
                      height={28}
                      onClick={handleBurgerMenuClick}
                    />
                  )
                ) : (
                  <Svg
                    icon={SVG_ICONS.PROFILE_SIGNOUT}
                    width={40}
                    onClick={handleLoginBasedOnGeoIp}
                    height={40}
                  />
                )}
              </div>
            </Col>
            <Col className="header-mobile__menu">
              <div
                className="header-mobile__menu__link"
                onClick={handleBurgerMenuClick}
              >
                <Svg icon={SVG_ICONS.BURGER_MENU} width={40} height={40} />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Drawer
        onClickLoginTestId="on-click-login"
        isDrawerOpen={isDrawerOpen}
        isLogin={UtilsUser.isUserLoggedIn()}
        onClickDrawerClose={toggleDrawer}
        onClickLogin={() => {
          if (UtilsUser.isUserLoggedIn()) {
            window
              .open(`${API_SA_BASE_URL}${API_ENDPOINTS.SA_PROFILE}`, "_blank")
              ?.focus();
          } else {
            toggleDrawer();
            handleLoginBasedOnGeoIp();
            // toggleLoginModal({
            //   isLoginModalOpen: true,
            //   loginModalRedirect: pathname,
            // });
          }
        }}
        onClickSignOut={logout}
      />
    </>
  );
};
