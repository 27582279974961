import { useEffect, useState } from "react";

import { MOBILE_MAX_WIDTH, TABLET_PORTRAIT_MAX_WIDTH } from "../constants/appConstants";

interface Size {
  width: number | undefined;
  height: number | undefined;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTabletPortrait, setIsTabletPortrait] = useState<boolean>(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      setIsMobile(window.innerWidth <= MOBILE_MAX_WIDTH);
      setIsTabletPortrait(window.innerWidth <= TABLET_PORTRAIT_MAX_WIDTH && window.innerWidth > MOBILE_MAX_WIDTH);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { windowSize, isMobile, isTabletPortrait };
};
