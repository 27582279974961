import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Layout } from "./components/Layout/Layout";
import { ROUTES } from "./configs/routes";
import { Error400 } from "./pages/nonauth/ErrorPages/Error400";
import { Error403 } from "./pages/nonauth/ErrorPages/Error403";
import { Error404 } from "./pages/nonauth/ErrorPages/Error404";
import { Error500 } from "./pages/nonauth/ErrorPages/Error500";
import { Error502 } from "./pages/nonauth/ErrorPages/Error502";
import { Error503 } from "./pages/nonauth/ErrorPages/Error503";
import { MyData } from "./pages/auth/MyData/MyData";
import { History } from "./pages/auth/MyData/History/History";
import { auth, nonAuth, unsupportedBrowserOnly } from "./services/auth";
import { Login } from "./pages/nonauth/Login/Login";
import PrivacyPolicyOverview from "./pages/nonauth/PrivacyPolicy/Overview";
import ADSSOLogout from "./pages/auth/ADSSOLogout/ADSSOLogout";
import ScrollToTop from "./components/__hoc/ScrollToTopHoc";
import { PrivacyPolicy } from "./pages/nonauth/PrivacyPolicy/PrivacyPolicy";
import { CookiePolicy } from "./pages/nonauth/CookiePolicy/CookiePolicy";
import { GlobalRightsResponse } from "./pages/nonauth/GlobalRightsResponse/GlobalRightsResponse";
import { ErrorIE } from "./pages/nonauth/ErrorPages/ErrorIE";
import LandingPage from "./pages/nonauth/LandingPage/LandingPage";
import { ErrorMaintenance } from "./pages/nonauth/ErrorPages/ErrorMaintenance";
import BdpConsignment from "./pages/nonauth/Consignment/BdpConsignment";
import AnalyticsConsignment from "./pages/nonauth/Consignment/AnalyticsConsignment";
import DiagmonConsignment from "./pages/nonauth/Consignment/DiagmonConsignment";
import { INVALID_PAGE } from "./constants/storage";
import { useSiteSettingsHook } from "./hooks/site-settings-hooks";
import DigitalLegacyAuth from "./pages/nonauth/DigitalLegacyAuth/DigitalLegacyAuth";
import { IS_PRE, IS_PROD } from "./constants/environment";

const AppRouter = () => {
  const { loadSystemProperties } = useSiteSettingsHook();

  useEffect(() => {
    // need to add this during mount because 404 page doesn't unmount when the user try to deeplink from 404 page to other valid page
    sessionStorage.removeItem(INVALID_PAGE);

    // TODO: implement feature toggle here
    if (!IS_PROD && !IS_PRE) loadSystemProperties();
  }, []);

  return (
    <Router>
      <Layout>
        <ScrollToTop>
          <Switch>
            <Route exact path={ROUTES.ROOT} component={nonAuth(LandingPage)} />
            <Route exact path={ROUTES.LOGIN} component={Login} />
            <Route exact path={ROUTES.ERROR_400} component={Error400} />
            <Route exact path={ROUTES.ERROR_403} component={Error403} />

            <Route exact path={ROUTES.ERROR_500} component={Error500} />
            <Route exact path={ROUTES.ERROR_502} component={Error502} />
            <Route exact path={ROUTES.ERROR_503} component={Error503} />
            <Route
              exact
              path={ROUTES.ERROR_IE}
              component={unsupportedBrowserOnly(ErrorIE)}
            />
            <Route
              exact
              path={ROUTES.ERROR_MAINTENANCE}
              component={ErrorMaintenance}
            />
            <Route
              exact
              path={ROUTES.MYDATA_HISTORY}
              component={auth(History)}
            />
            <Route exact path={ROUTES.MYDATA} component={auth(MyData)} />
            <Route
              exact
              path={ROUTES.OLD_DOWNLOAD_HISTORY}
              component={auth(MyData)}
            />
            <Route
              exact
              path={ROUTES.OLD_DELETE_HISTORY}
              component={auth(MyData)}
            />
            <Route
              exact
              path={ROUTES.OLD_OPTOUT_HISTORY}
              component={auth(MyData)}
            />
            <Route
              exact
              path={ROUTES.OLD_DOWNLOAD_REQUEST}
              component={auth(MyData)}
            />
            <Route
              exact
              path={ROUTES.OLD_DELETE_REQUEST}
              component={auth(MyData)}
            />
            <Route
              exact
              path={ROUTES.OLD_OPTOUT_REQUEST}
              component={auth(MyData)}
            />
            <Route
              exact
              path={ROUTES.POLICY_OVERVIEW}
              component={nonAuth(PrivacyPolicyOverview)}
            />
            <Route
              exact
              path={ROUTES.SUMMARY_REQUEST_DETAILS}
              component={auth(MyData)}
            />
            <Route
              exact
              path={ROUTES.MYDATA_HISTORY_REQUEST_DETAILS}
              component={auth(History)}
            />
            <Route
              exact
              path={ROUTES.OLD_REQUEST_DETAILS}
              component={auth(History)}
            />
            <Route
              exact
              path={ROUTES.PRIVACY_POLICY}
              component={nonAuth(PrivacyPolicy)}
            />

            <Route
              exact
              path={ROUTES.COOKIE_POLICY}
              component={nonAuth(CookiePolicy)}
            />

            <Route
              exact
              path={ROUTES.ABOUT_GRPS}
              component={nonAuth(GlobalRightsResponse)}
            />
            <Route
              exact
              path={[
                ROUTES.OLD_CONSIGNMENT_WITH_KR,
                ROUTES.OLD_CONSIGNMENT,
                ROUTES.BDP_CONSIGNMENT,
              ]}
              component={nonAuth(BdpConsignment)}
            />
            <Route
              exact
              path={ROUTES.ANALYTICS_CONSIGNMENT}
              component={nonAuth(AnalyticsConsignment)}
            />
            <Route
              exact
              path={ROUTES.DIAGMON_CONSIGNMENT}
              component={nonAuth(DiagmonConsignment)}
            />

            <Route
              exact
              path={ROUTES.DIGITAL_LEGACY_AUTH}
              component={nonAuth(DigitalLegacyAuth)}
            />

            <Route
              exact
              path={ROUTES.ADSSO_LOGOUT}
              component={nonAuth(ADSSOLogout)}
            />
            <Route component={Error404} />
          </Switch>
        </ScrollToTop>
      </Layout>
    </Router>
  );
};

export default AppRouter;
