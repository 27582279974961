import Cookies from "js-cookie";

export const Cookie = {
  get: (key: string) => {
    return Cookies.get(key);
  },
  set: (key: string, value: string, expires?: { expires: number }) => {
    return Cookies.set(key, value, expires);
  },
  remove: (key: string, path?: object) => {
    return Cookies.remove(key, path);
  },
};

export const LocalStorage = {
  get: (key: string) => {
    return localStorage.getItem(key);
  },
  set: (key: string, value: any) => {
    localStorage.setItem(key, value);
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};
