import { VersionHistory } from "../types/version-history";

export const VersionHistories: VersionHistory[] = [
  {
    date: "05302024",
    displayDate: "May 30, 2024",
    isLatest: true,
  },
  {
    date: "02292024",
    displayDate: "February 29, 2024",
    isLatest: false,
  },
  {
    date: "09262023",
    displayDate: "September 26, 2023",
    isLatest: false,
  },
  {
    date: "06292023",
    displayDate: "June 29, 2023",
    isLatest: false,
  },
  {
    date: "12192022",
    displayDate: "December 19, 2022",
    isLatest: false,
  },
  {
    date: "07152022",
    displayDate: "July 15, 2022",
    isLatest: false,
  },
  {
    date: "05312022",
    displayDate: "May 31, 2022",
    isLatest: false,
  },
  {
    date: "04202022",
    displayDate: "April 20, 2022",
    isLatest: false,
  },
  {
    date: "06302021",
    displayDate: "June 30, 2021",
    isLatest: false,
  },
  {
    date: "04302021",
    displayDate: "April 30, 2021",
    isLatest: false,
  },
  {
    date: "04092021",
    displayDate: "April 09, 2021",
    isLatest: false,
  },
  {
    date: "03042021",
    displayDate: "March 04, 2021",
    isLatest: false,
  },
  {
    date: "02222021",
    displayDate: "February 22, 2021",
    isLatest: false,
  },
  {
    date: "01102021",
    displayDate: "January 10, 2021",
    isLatest: false,
  },
  {
    date: "12222020",
    displayDate: "December 22, 2020",
    isLatest: false,
  },
  {
    date: "09252020",
    displayDate: "September 25, 2020",
    isLatest: false,
  },
  {
    date: "08212020",
    displayDate: "August 21, 2020",
    isLatest: false,
  },
  {
    date: "07152020",
    displayDate: "July 15, 2020",
    isLatest: false,
  },
  {
    date: "06052020",
    displayDate: "June 05, 2020",
    isLatest: false,
  },
  {
    date: "06012020",
    displayDate: "June 01, 2020",
    isLatest: false,
  },
  {
    date: "05252020",
    displayDate: "May 25, 2020",
    isLatest: false,
  },
  {
    date: "05122020",
    displayDate: "May 12, 2020",
    isLatest: false,
  },
  {
    date: "12272019",
    displayDate: "December 27, 2019",
    isLatest: false,
  },
  {
    date: "11272019",
    displayDate: "November 27, 2019",
    isLatest: false,
  },
  {
    date: "10282019",
    displayDate: "October 28, 2019",
    isLatest: false,
  },
  {
    date: "03252019",
    displayDate: "March 25, 2019",
    isLatest: false,
  },
];
