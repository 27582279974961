import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Svg } from "../../../../components/__common/Svg/Svg";
import { Typography } from "../../../../components/__common/Typography/Typography";
import { Button } from "../../../../components/__common/_controls";
import { MyDataRequestDetail } from "../../../../data/mydata-request-detail-screen";
import { ApplicationState } from "../../../../store";
import { MyDataState } from "../../../../store/reducers/mydata-reducer";
import { RequestStatus, RequestType } from "../../../../types/mydata";
import { RequestDetailsEntity } from "../../../../types/request-details";
import { UtilsDateTime } from "../../../../utils/UtilsDateTIme";
interface Props {
  details: RequestDetailsEntity;
  status: RequestStatus;
  requestType: RequestType;
  onClickCancel?: () => void;
  onClickTransferPassword?: () => void;
}

export const TitleSection: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [hasDownloadableFile, setHasDownloadableFile] = useState(false);
  const myDataState = useSelector<ApplicationState, MyDataState>(
    (state) => state.myData
  );

  const { isFilePasswordSent } = myDataState;

  const {
    status,
    requestType,
    onClickCancel,
    onClickTransferPassword,
    details,
  } = props;

  const handleOnClickCancel = () => {
    if (onClickCancel) {
      onClickCancel();
    }
  };

  const handleOnClickTransferPassword = () => {
    if (onClickTransferPassword) {
      onClickTransferPassword();
    }
  };

  const handleOnClickDownloadAllFiles = () => {
    details.sub_request_list.forEach((subreq) => {
      if (subreq.attach_file) {
        subreq.attach_file.forEach((file) => {
          const element = document.createElement("iframe");
          element.setAttribute("src", file.url);
          element.style.display = "none";
          document.body.appendChild(element);
        });
      }
    });
  };

  useEffect(() => {
    if (UtilsDateTime.getExpiredDate(details)) {
      setHasDownloadableFile(true);
    }
  }, [details]);

  return (
    <>
      <div className="request-details__icon-main">
        <Svg
          icon={
            MyDataRequestDetail[status as RequestStatus][
              requestType as RequestType
            ].icon
          }
          width={96}
          height={96}
        />
      </div>
      <div className="request-details__text-section">
        <Typography
          variant="body2"
          component="div"
          className="request-details__text-section__status"
        >
          {t(
            MyDataRequestDetail[status as RequestStatus][
              requestType as RequestType
            ].statusLabel
          )}
        </Typography>
        {status === RequestStatus.COMPLETED &&
        requestType === RequestType.DOWNLOAD ? (
          hasDownloadableFile ? (
            <>
              <Typography variant="title3" component="div">
                {t(
                  MyDataRequestDetail[status as RequestStatus][
                    requestType as RequestType
                  ].title
                )}
              </Typography>
              <Typography
                variant="body2"
                weight="w400"
                component="div"
                className="request-details__text-section__subtitle"
              >
                {isFilePasswordSent &&
                  t(
                    MyDataRequestDetail[status as RequestStatus][
                      requestType as RequestType
                    ].subtitle
                  )}
              </Typography>
            </>
          ) : (
            <Typography variant="title3" component="div">
              {t("PRIVACY_MYDATA_REQ_DETAIL_BODY_EXP_EMPTY")}
            </Typography>
          )
        ) : (
          <>
            {status === RequestStatus.CANCELED &&
              requestType === RequestType.DELETE && (
                <Typography variant="title3" component="div" weight="w700">
                  {t("PRIVACY_REQUEST_DETAILS_CANCELED_DELETE")}
                </Typography>
              )}

            {status === RequestStatus.CANCELED &&
              requestType === RequestType.DOWNLOAD && (
                <Typography variant="title3" component="div" weight="w700">
                  {t("PRIVACY_REQUEST_DETAILS_CANCELED")}
                </Typography>
              )}

            {status === RequestStatus.DENIED && (
              <Typography variant="title3" component="div" weight="w700">
                {t("PRIVACY_MYDATA_REQ_ERROR_TITLE")}
              </Typography>
            )}

            <Typography
              variant="body2"
              weight="w400"
              component="div"
              className="request-details__text-section__subtitle"
            >
              {t(
                MyDataRequestDetail[status as RequestStatus][
                  requestType as RequestType
                ].subtitle
              )}
            </Typography>
          </>
        )}
      </div>
      <div className="request-details__button">
        {status === RequestStatus.REQUESTING && (
          <span>
            <Button
              testId="cancel-request-modal"
              variant="solid"
              type="secondary"
              title={t("PRIVACY_REQUEST_DETAILS_BTN_CANCEL")}
              onClick={handleOnClickCancel}
              className="request-details__button__single"
            />
          </span>
        )}
        {status === RequestStatus.COMPLETED &&
          requestType === RequestType.DOWNLOAD &&
          UtilsDateTime.getExpiredDate(details) &&
          !isFilePasswordSent && (
            <>
              <span>
                <Button
                  testId="transfer-password-button"
                  variant="solid"
                  type="secondary"
                  title={t(
                    "PRIVACY_MYDATA_REQ_DETAIL_BUTTON_SEND_FILE_PASSWORD"
                  )}
                  onClick={handleOnClickTransferPassword}
                />
              </span>
              <span>
                <Button
                  testId="download-all-file-button"
                  variant="solid"
                  type="secondary"
                  title={t("PRIVACY_REQUEST_DETAILS_BTN_DOWNLOAD_ALL")}
                  onClick={handleOnClickDownloadAllFiles}
                />
              </span>
            </>
          )}
      </div>
    </>
  );
};
