// africa
import AO from "../../../assets/images/png/flags/africa/ao.png";
import BF from "../../../assets/images/png/flags/africa/bf.png";
import BI from "../../../assets/images/png/flags/africa/bi.png";
import BJ from "../../../assets/images/png/flags/africa/bj.png";
import BW from "../../../assets/images/png/flags/africa/bw.png";
import CD from "../../../assets/images/png/flags/africa/cd.png";
import CF from "../../../assets/images/png/flags/africa/cf.png";
import CG from "../../../assets/images/png/flags/africa/cg.png";
import CI from "../../../assets/images/png/flags/africa/ci.png";
import CM from "../../../assets/images/png/flags/africa/cm.png";
import CV from "../../../assets/images/png/flags/africa/cv.png";
import DJ from "../../../assets/images/png/flags/africa/dj.png";
import DZ from "../../../assets/images/png/flags/africa/dz.png";
import EG from "../../../assets/images/png/flags/africa/eg.png";
import ER from "../../../assets/images/png/flags/africa/er.png";
import ET from "../../../assets/images/png/flags/africa/et.png";
import GA from "../../../assets/images/png/flags/africa/ga.png";
import GG from "../../../assets/images/png/flags/africa/gg.png";
import GH from "../../../assets/images/png/flags/africa/gh.png";
import GM from "../../../assets/images/png/flags/africa/gm.png";
import GN from "../../../assets/images/png/flags/africa/gn.png";
import GW from "../../../assets/images/png/flags/africa/gw.png";
import KE from "../../../assets/images/png/flags/africa/ke.png";
import KM from "../../../assets/images/png/flags/africa/km.png";
import LR from "../../../assets/images/png/flags/africa/lr.png";
import LS from "../../../assets/images/png/flags/africa/ls.png";
import LY from "../../../assets/images/png/flags/africa/ly.png";
import MA from "../../../assets/images/png/flags/africa/ma.png";
import MG from "../../../assets/images/png/flags/africa/mg.png";
import ML from "../../../assets/images/png/flags/africa/ml.png";
import MR from "../../../assets/images/png/flags/africa/mr.png";
import MU from "../../../assets/images/png/flags/africa/mu.png";
import MW from "../../../assets/images/png/flags/africa/mw.png";
import MZ from "../../../assets/images/png/flags/africa/mz.png";
import NA from "../../../assets/images/png/flags/africa/na.png";
import NE from "../../../assets/images/png/flags/africa/ne.png";
import NG from "../../../assets/images/png/flags/africa/ng.png";
import RE from "../../../assets/images/png/flags/africa/re.png";
import RW from "../../../assets/images/png/flags/africa/rw.png";
import SC from "../../../assets/images/png/flags/africa/sc.png";
import SD from "../../../assets/images/png/flags/africa/sd.png";
import SL from "../../../assets/images/png/flags/africa/sl.png";
import SN from "../../../assets/images/png/flags/africa/sn.png";
import SO from "../../../assets/images/png/flags/africa/so.png";
import SS from "../../../assets/images/png/flags/africa/ss.png";
import ST from "../../../assets/images/png/flags/africa/st.png";
import SZ from "../../../assets/images/png/flags/africa/sz.png";
import TD from "../../../assets/images/png/flags/africa/td.png";
import TG from "../../../assets/images/png/flags/africa/tg.png";
import TN from "../../../assets/images/png/flags/africa/tn.png";
import TZ from "../../../assets/images/png/flags/africa/tz.png";
import UG from "../../../assets/images/png/flags/africa/ug.png";
import ZA from "../../../assets/images/png/flags/africa/za.png";
import ZM from "../../../assets/images/png/flags/africa/zm.png";
import ZW from "../../../assets/images/png/flags/africa/zw.png";
// asia
import AU from "../../../assets/images/png/flags/asia-pacific/au.png";
import BD from "../../../assets/images/png/flags/asia-pacific/bd.png";
import BN from "../../../assets/images/png/flags/asia-pacific/bn.png";
import BT from "../../../assets/images/png/flags/asia-pacific/bt.png";
import CK from "../../../assets/images/png/flags/asia-pacific/ck.png";
import CN from "../../../assets/images/png/flags/asia-pacific/cn.png";
import FJ from "../../../assets/images/png/flags/asia-pacific/fj.png";
import FM from "../../../assets/images/png/flags/asia-pacific/fm.png";
import GE from "../../../assets/images/png/flags/asia-pacific/ge.png";
import HK from "../../../assets/images/png/flags/asia-pacific/hk.png";
import ID from "../../../assets/images/png/flags/asia-pacific/id.png";
import IN from "../../../assets/images/png/flags/asia-pacific/in.png";
import JP from "../../../assets/images/png/flags/asia-pacific/jp.png";
import KG from "../../../assets/images/png/flags/asia-pacific/kg.png";
import KH from "../../../assets/images/png/flags/asia-pacific/kh.png";
import KI from "../../../assets/images/png/flags/asia-pacific/ki.png";
import KR from "../../../assets/images/png/flags/asia-pacific/kr.png";
import KZ from "../../../assets/images/png/flags/asia-pacific/kz.png";
import LA from "../../../assets/images/png/flags/asia-pacific/la.png";
import LK from "../../../assets/images/png/flags/asia-pacific/lk.png";
import MH from "../../../assets/images/png/flags/asia-pacific/mh.png";
import MM from "../../../assets/images/png/flags/asia-pacific/mm.png";
import MN from "../../../assets/images/png/flags/asia-pacific/mn.png";
import MO from "../../../assets/images/png/flags/asia-pacific/mo.png";
import MV from "../../../assets/images/png/flags/asia-pacific/mv.png";
import MY from "../../../assets/images/png/flags/asia-pacific/my.png";
import NC from "../../../assets/images/png/flags/asia-pacific/nc.png";
import NP from "../../../assets/images/png/flags/asia-pacific/np.png";
import NR from "../../../assets/images/png/flags/asia-pacific/nr.png";
import NU from "../../../assets/images/png/flags/asia-pacific/nu.png";
import NZ from "../../../assets/images/png/flags/asia-pacific/nz.png";
import PF from "../../../assets/images/png/flags/asia-pacific/pf.png";
import PG from "../../../assets/images/png/flags/asia-pacific/pg.png";
import PH from "../../../assets/images/png/flags/asia-pacific/ph.png";
import PW from "../../../assets/images/png/flags/asia-pacific/pw.png";
import SB from "../../../assets/images/png/flags/asia-pacific/sb.png";
import SG from "../../../assets/images/png/flags/asia-pacific/sg.png";
import TH from "../../../assets/images/png/flags/asia-pacific/th.png";
import TJ from "../../../assets/images/png/flags/asia-pacific/tj.png";
import TL from "../../../assets/images/png/flags/asia-pacific/tl.png";
import TO from "../../../assets/images/png/flags/asia-pacific/to.png";
import TV from "../../../assets/images/png/flags/asia-pacific/tv.png";
import TW from "../../../assets/images/png/flags/asia-pacific/tw.png";
import UZ from "../../../assets/images/png/flags/asia-pacific/uz.png";
import VN from "../../../assets/images/png/flags/asia-pacific/vn.png";
import VU from "../../../assets/images/png/flags/asia-pacific/vu.png";
import WS from "../../../assets/images/png/flags/asia-pacific/ws.png";
// europe
import AD from "../../../assets/images/png/flags/europe/ad.png";
import AL from "../../../assets/images/png/flags/europe/al.png";
import AM from "../../../assets/images/png/flags/europe/am.png";
import AT from "../../../assets/images/png/flags/europe/at.png";
import AZ from "../../../assets/images/png/flags/europe/az.png";
import BA from "../../../assets/images/png/flags/europe/ba.png";
import BE from "../../../assets/images/png/flags/europe/be.png";
import BG from "../../../assets/images/png/flags/europe/bg.png";
import BY from "../../../assets/images/png/flags/europe/by.png";
import CH from "../../../assets/images/png/flags/europe/ch.png";
import CY from "../../../assets/images/png/flags/europe/cy.png";
import CZ from "../../../assets/images/png/flags/europe/cz.png";
import DE from "../../../assets/images/png/flags/europe/de.png";
import DK from "../../../assets/images/png/flags/europe/dk.png";
import EE from "../../../assets/images/png/flags/europe/ee.png";
import ES from "../../../assets/images/png/flags/europe/es.png";
import FI from "../../../assets/images/png/flags/europe/fi.png";
import FK from "../../../assets/images/png/flags/europe/fk.png";
import FO from "../../../assets/images/png/flags/europe/fo.png";
import FR from "../../../assets/images/png/flags/europe/fr.png";
import GB from "../../../assets/images/png/flags/europe/gb.png";
import GI from "../../../assets/images/png/flags/europe/gi.png";
import GL from "../../../assets/images/png/flags/europe/gl.png";
import GP from "../../../assets/images/png/flags/europe/gp.png";
import GR from "../../../assets/images/png/flags/europe/gr.png";
import HR from "../../../assets/images/png/flags/europe/hr.png";
import HU from "../../../assets/images/png/flags/europe/hu.png";
import IE from "../../../assets/images/png/flags/europe/ie.png";
import IS from "../../../assets/images/png/flags/europe/is.png";
import IT from "../../../assets/images/png/flags/europe/it.png";
import LI from "../../../assets/images/png/flags/europe/li.png";
import LT from "../../../assets/images/png/flags/europe/lt.png";
import LU from "../../../assets/images/png/flags/europe/lu.png";
import LV from "../../../assets/images/png/flags/europe/lv.png";
import MC from "../../../assets/images/png/flags/europe/mc.png";
import MD from "../../../assets/images/png/flags/europe/md.png";
import ME from "../../../assets/images/png/flags/europe/me.png";
import MK from "../../../assets/images/png/flags/europe/mk.png";
import MT from "../../../assets/images/png/flags/europe/mt.png";
import NL from "../../../assets/images/png/flags/europe/nl.png";
import NO from "../../../assets/images/png/flags/europe/no.png";
import PL from "../../../assets/images/png/flags/europe/pl.png";
import PM from "../../../assets/images/png/flags/europe/pm.png";
import PT from "../../../assets/images/png/flags/europe/pt.png";
import RO from "../../../assets/images/png/flags/europe/ro.png";
import RS from "../../../assets/images/png/flags/europe/rs.png";
import RU from "../../../assets/images/png/flags/europe/ru.png";
import SE from "../../../assets/images/png/flags/europe/se.png";
import SK from "../../../assets/images/png/flags/europe/sk.png";
import SI from "../../../assets/images/png/flags/europe/si.png";
import SM from "../../../assets/images/png/flags/europe/sm.png";
import TR from "../../../assets/images/png/flags/europe/tr.png";
import UA from "../../../assets/images/png/flags/europe/ua.png";
import VA from "../../../assets/images/png/flags/europe/va.png";
// latin america
import AG from "../../../assets/images/png/flags/latin-america/ag.png";
import AI from "../../../assets/images/png/flags/latin-america/ai.png";
import AN from "../../../assets/images/png/flags/latin-america/an.png";
import AR from "../../../assets/images/png/flags/latin-america/ar.png";
import AW from "../../../assets/images/png/flags/latin-america/aw.png";
import BB from "../../../assets/images/png/flags/latin-america/bb.png";
import BM from "../../../assets/images/png/flags/latin-america/bm.png";
import BO from "../../../assets/images/png/flags/latin-america/bo.png";
import BR from "../../../assets/images/png/flags/latin-america/br.png";
import BS from "../../../assets/images/png/flags/latin-america/bs.png";
import BZ from "../../../assets/images/png/flags/latin-america/bz.png";
import CL from "../../../assets/images/png/flags/latin-america/cl.png";
import CO from "../../../assets/images/png/flags/latin-america/co.png";
import CR from "../../../assets/images/png/flags/latin-america/cr.png";
import CU from "../../../assets/images/png/flags/latin-america/cu.png";
import DM from "../../../assets/images/png/flags/latin-america/dm.png";
import DO from "../../../assets/images/png/flags/latin-america/do.png";
import EC from "../../../assets/images/png/flags/latin-america/ec.png";
import GD from "../../../assets/images/png/flags/latin-america/gd.png";
import GF from "../../../assets/images/png/flags/latin-america/gf.png";
import GT from "../../../assets/images/png/flags/latin-america/gt.png";
import GY from "../../../assets/images/png/flags/latin-america/gy.png";
import HN from "../../../assets/images/png/flags/latin-america/hn.png";
import HT from "../../../assets/images/png/flags/latin-america/ht.png";
import JM from "../../../assets/images/png/flags/latin-america/jm.png";
import KN from "../../../assets/images/png/flags/latin-america/kn.png";
import KY from "../../../assets/images/png/flags/latin-america/ky.png";
import LC from "../../../assets/images/png/flags/latin-america/lc.png";
import MS from "../../../assets/images/png/flags/latin-america/ms.png";
import MX from "../../../assets/images/png/flags/latin-america/mx.png";
import NI from "../../../assets/images/png/flags/latin-america/ni.png";
import PA from "../../../assets/images/png/flags/latin-america/pa.png";
import PE from "../../../assets/images/png/flags/latin-america/pe.png";
import PR from "../../../assets/images/png/flags/latin-america/pr.png";
import PY from "../../../assets/images/png/flags/latin-america/py.png";
import SR from "../../../assets/images/png/flags/latin-america/sr.png";
import SV from "../../../assets/images/png/flags/latin-america/sv.png";
import TC from "../../../assets/images/png/flags/latin-america/tc.png";
import TT from "../../../assets/images/png/flags/latin-america/tt.png";
import UY from "../../../assets/images/png/flags/latin-america/uy.png";
import VC from "../../../assets/images/png/flags/latin-america/vc.png";
import VE from "../../../assets/images/png/flags/latin-america/ve.png";
import VG from "../../../assets/images/png/flags/latin-america/vg.png";
// middle east
import AE from "../../../assets/images/png/flags/middle-east/ae.png";
import AF from "../../../assets/images/png/flags/middle-east/af.png";
import BH from "../../../assets/images/png/flags/middle-east/bh.png";
import IL from "../../../assets/images/png/flags/middle-east/il.png";
import IQ from "../../../assets/images/png/flags/middle-east/iq.png";
import IR from "../../../assets/images/png/flags/middle-east/ir.png";
import JO from "../../../assets/images/png/flags/middle-east/jo.png";
import KW from "../../../assets/images/png/flags/middle-east/kw.png";
import LB from "../../../assets/images/png/flags/middle-east/lb.png";
import OM from "../../../assets/images/png/flags/middle-east/om.png";
import PK from "../../../assets/images/png/flags/middle-east/pk.png";
import QA from "../../../assets/images/png/flags/middle-east/qa.png";
import SA from "../../../assets/images/png/flags/middle-east/sa.png";
import SY from "../../../assets/images/png/flags/middle-east/sy.png";
import TM from "../../../assets/images/png/flags/middle-east/tm.png";
import YE from "../../../assets/images/png/flags/middle-east/ye.png";
// north america
import CA from "../../../assets/images/png/flags/north-america/ca.png";
import GU from "../../../assets/images/png/flags/north-america/gu.png";
import US from "../../../assets/images/png/flags/north-america/us.png";
import VI from "../../../assets/images/png/flags/north-america/vi.png";

export const COUNTRY_FLAGS: any = {
  // africa
  AO,
  BF,
  BI,
  BJ,
  BW,
  CD,
  CF,
  CG,
  CI,
  CM,
  CV,
  DJ,
  DZ,
  EG,
  ER,
  ET,
  GA,
  GG,
  GH,
  GM,
  GN,
  GW,
  KE,
  KM,
  LR,
  LS,
  LY,
  MA,
  MG,
  ML,
  MR,
  MU,
  MW,
  MZ,
  NA,
  NE,
  NG,
  RE,
  RW,
  SC,
  SD,
  SL,
  SN,
  SO,
  SS,
  ST,
  SZ,
  TD,
  TG,
  TN,
  TZ,
  UG,
  ZA,
  ZM,
  ZW,
  // asia
  AU,
  BD,
  BN,
  BT,
  CK,
  CN,
  FJ,
  FM,
  GE,
  HK,
  ID,
  IN,
  JP,
  KG,
  KH,
  KI,
  KR,
  KZ,
  LA,
  LK,
  MH,
  MM,
  MN,
  MO,
  MV,
  MY,
  NC,
  NP,
  NR,
  NU,
  NZ,
  PF,
  PG,
  PH,
  PW,
  SB,
  SG,
  TH,
  TJ,
  TL,
  TO,
  TV,
  TW,
  UZ,
  VN,
  VU,
  WS,
  // europe
  AD,
  AL,
  AM,
  AT,
  AZ,
  BA,
  BE,
  BG,
  BY,
  CH,
  CY,
  CZ,
  DE,
  DK,
  EE,
  ES,
  FI,
  FK,
  FO,
  FR,
  GB,
  GI,
  GL,
  GP,
  GR,
  HR,
  HU,
  IE,
  IS,
  IT,
  LI,
  LT,
  LU,
  LV,
  MC,
  MD,
  ME,
  MK,
  MT,
  NL,
  NO,
  PL,
  PM,
  PT,
  RO,
  RS,
  RU,
  SE,
  SK,
  SI,
  SM,
  TR,
  UA,
  VA,
  // latin america
  AG,
  AI,
  AN,
  AR,
  AW,
  BB,
  BM,
  BO,
  BR,
  BS,
  BZ,
  CL,
  CO,
  CR,
  CU,
  DM,
  DO,
  EC,
  GD,
  GF,
  GT,
  GY,
  HN,
  HT,
  JM,
  KN,
  KY,
  LC,
  MS,
  MX,
  NI,
  PA,
  PE,
  PR,
  PY,
  SR,
  SV,
  TC,
  TT,
  UY,
  VC,
  VE,
  VG,
  // middle east
  AE,
  AF,
  BH,
  IL,
  IQ,
  IR,
  JO,
  KW,
  LB,
  OM,
  PK,
  QA,
  SA,
  SY,
  TM,
  YE,
  // north america
  CA,
  GU,
  US,
  VI,
};
