import {
  API_BASE_URL,
  API_ENDPOINTS,
  API_PUBLIC_AUTH_TOKEN,
} from "../constants/api";
import { RCODES } from "../constants/rcodes";
import Http from "../services/http";
import { DEFAULT_COUNTRY_CODE } from "../constants/locale";
import { IS_PRE, IS_PROD } from "../constants/environment";
import { DETECTED } from "../constants/site-settings";
import { useDispatch, useSelector } from "react-redux";
import {
  actionSetSysProps,
  actionSetSysPropsLoading,
} from "../store/actions/site-actions";
import { ApplicationState } from "../store";
import { SystemState } from "../store/reducers/site-reducer";

export const useSiteSettingsHook = () => {
  const dispatch = useDispatch();
  const siteSettings = useSelector<ApplicationState, SystemState>(
    (state) => state.system
  );

  const loadSystemProperties = async () => {
    dispatch(actionSetSysPropsLoading(true));

    const url = `${API_BASE_URL}${API_ENDPOINTS.SYSTEM_PROPERTIES}`;
    const extraHeaders = {
      Authorization: API_PUBLIC_AUTH_TOKEN,
    };

    try {
      const res = await Http.get(url, extraHeaders);

      if (res.rcode !== RCODES.SUCCESS) {
        throw new Error("Error in fetching system properties");
      }

      const systemProperties = res.system_properties.reduce(
        (acc: any, curr: any) => {
          acc[curr.property_key] = curr.property_value;
          return acc;
        },
        {}
      );

      dispatch(actionSetSysProps(systemProperties));
      dispatch(actionSetSysPropsLoading(false));
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * API call for getting geoip of user.
   * @private
   * @returns geoip
   */
  const _getDefaultGeoip = async (): Promise<string> => {
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.GEOIP}`;
      const res = await fetch(url, {
        method: "GET",
        headers: { Authorization: API_PUBLIC_AUTH_TOKEN },
      });

      const { headers } = res;
      return headers.get("country") || DEFAULT_COUNTRY_CODE;
    } catch (error) {
      console.error(error);
      return DEFAULT_COUNTRY_CODE;
    }
  };

  const getCountryCode = async (): Promise<string> => {
    let countryCode = DEFAULT_COUNTRY_CODE;

    if (siteSettings.sysProps.GEO_IP !== DETECTED && !IS_PROD && !IS_PRE) {
      countryCode = siteSettings.sysProps.GEO_IP;
    } else {
      countryCode = await _getDefaultGeoip();
    }

    return countryCode;
  };

  return {
    loadSystemProperties,
    getCountryCode,
  };
};
