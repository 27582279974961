// COOKIE VARIABLES ESSENTIALS
export const COOKIE_SC_ATKN = "SC_ATKN";
export const COOKIE_GST_ATKN = "GST_ATKN";
export const COOKIE_ADSSO_TOKEN = "ADSSO_TOKEN";
export const EMAIL_LOGIN_VERIFICATION_SESSION =
  "EMAIL_LOGIN_VERIFICATION_SESSION";
export const EMAIL_LOGIN_VERIFICATION_RESULT =
  "EMAIL_LOGIN_VERIFICATION_RESULT";
export const SC_DID = "SC_CDID";
export const SC_SSO = "SC_SSO";
export const COOKIE_NOTICE_EXPIRY = "cookieNoticeExpiryCode";
export const ACTIVE_ACTION = "activeAction";
export const SELECTED_SERVICES = "selectedServices";
export const CONFIRM_TOKEN = "confirmToken";
export const SHOW_SURVEY = "showSurvey";
export const USER_SURVEY_SKIP = "surveySkip";
export const X_SC_DID = "x-sc-did";
export const INVALID_PAGE = "invalidPage";
export const CLOSE_CONFIRMATION_MODAL = "closeConfirmationModal";

// LOCAL STORAGE VARIABLES
export const USER_DATA_NAMESPACE = "USER_DATA_NAMESPACE";
export const USER_SESSION_EXPIRED_TIME = "USER_SESSION_EXPIRED_TIME";
export const REQUEST_INFO = "REQUEST_INFO";
export const ALLOW_FUNCTIONAL_COOKIES = "ALLOW_FUNCTIONAL_COOKIES";
export const ALLOW_PERFORMANCE_COOKIES = "ALLOW_PERFORMANCE_COOKIES";
export const SHOW_COOKIE_NOTICE = "SHOW_COOKIE_NOTICE";
export const COOKIE_NOTICE_EXPIRY_CODE = "COOKIE_NOTICE_EXPIRY_CODE";
export const USER_SESSION_ID = "USER_SESSION_ID";
export const GEO_IP = "GEO_IP";
export const MYDATA_HISTORY_CONFIRM_TOKEN = "MYDATA_HISTORY_CONFIRM_TOKEN";
export const RESTRICTED = "RESTRICTED";

// FUNCTIONAL COOKIE
export const LANGUAGE = "language";
export const LANGUAGE_CODE = "languageCode";
export const LANGUAGE_TRANS = "languageTrans";
export const REGION = "region";
export const COUNTRY = "country";
export const COUNTRY_CODE = "countryCode";
export const REQUEST_NUMBER = "requestNumber";
export const SHOW_NOTICE = "showNotice";
export const PP_LANGUAGE = "ppLanguage";
export const PP_REGION = "ppRegion";
export const HIGH_CONTRAST = "_s2p_contrast";
export const SHOW_MEMBER_NOTICE = "showMemberNotice";
export const REDIRECT_URI = "redirectURI";
export const REDIRECT = "redirect";
export const USER_SELECTED_COUNTRY = "userSelectedCountry";

// Performance
export const PERFORMANCE_COOKIES = {
  GA: "_ga",
  GAT: "_gat",
  GID: "_gid",
};

// Site access credentials cookies for dev and stg environment
export const SITE_ACCESS_KEY = "siteAccessKey";
