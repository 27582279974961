import React from "react";
import { Container } from "../../../../../components/Grid/Grid";
import { Typography } from "../../../../../components/__common/Typography/Typography";
import { useTranslation } from "react-i18next";
import { SVG_ICONS } from "../../../../../components/__common/Svg/Svg";
import "./Banner.scss";

interface Props {
  onClick?: () => void;
}

export const Banner: React.FC<Props> = (Props) => {
  const { t } = useTranslation();
  const { onClick, ...otherProps } = Props;

  return (
    <>
      <div className={"landingPage-top-banner"} id={"landingPage-top-banner"}>
        <div className={`landingPage-top-banner__textContainer`}>
          <Container>
            <Container className={"landingPage-top-banner__title"}>
              <Typography
                className={`landingPage-top-banner__title__text`}
                variant="h1"
                component="h1"
              >
                {t("PRIVACY_HUB_BANNER_TEXT1")}
              </Typography>
              <Typography
                className={`landingPage-top-banner__title__text`}
                variant="h1"
                component="h1"
              >
                {t("PRIVACY_HUB_BANNER_TEXT2")}
              </Typography>
            </Container>
            <Container className={`landingPage-top-banner__caption`}>
              <Typography
                className={`landingPage-top-banner__caption__text`}
                variant="body1"
              >
                {t("PRIVACY_HUB_BANNER_DESC")}
              </Typography>
            </Container>
          </Container>
          <div
            className={`landingPage-top-banner__textContainer__mouseScroll`}
            onClick={onClick}
            {...otherProps}
          >
            <img
              src={SVG_ICONS.MOUSE_SCROLL}
              width={58}
              height={58}
              alt="mouse-scroll"
            />
          </div>
        </div>
      </div>
    </>
  );
};
