import { ROUTES } from "../configs/routes";
import { SVG_ICONS } from "../components/__common/Svg/Svg";
import { Navigation } from "../types/navs";
import {
  EVENT_HEADER_ACTION_PP_OVERVIEW,
  EVENT_HEADER_ACTION_PRIVACY_POLICY,
  EVENT_HEADER_ACTION_MY_DATA,
} from "../constants/analytics";

export const HeaderMenu: Array<Navigation> = [
  //removed global privacy site menu item due to new hub landing page implementation
  {
    id: 1,
    icon: SVG_ICONS.MOBILE_MENU_PP,
    title: {
      text: "PRIVACY_HEADER_MENU_PRIVACY_POLICY",
      link: "",
    },
    gaAction: "",
    showLocation: false,
    items: [
      {
        id: 1,
        text: "PRIVACY_HEADER_SUBMENU_OVERVIEW",
        link: ROUTES.POLICY_OVERVIEW,
        detail: {
          text: "PRIVACY_HEADER_SUBMENU_OVERVIEW_TEXT",
          svg: SVG_ICONS.MENU_PRIVACY_OVERVIEW_ICON,
        },
        gaAction: EVENT_HEADER_ACTION_PP_OVERVIEW,
      },
      {
        id: 2,
        text: "PRIVACY_HEADER_SUBMENU_GPP",
        link: ROUTES.PRIVACY_POLICY,
        detail: {
          text: "PRIVACY_HEADER_SUBMENU_GPP_TEXT",
          svg: SVG_ICONS.MENU_PRIVACYPOLICY_ICON,
        },
        gaAction: EVENT_HEADER_ACTION_PRIVACY_POLICY,
      },
    ],
  },
  {
    id: 2,
    icon: SVG_ICONS.MOBILE_MENU_MY_DATA,
    showLocation: true,
    title: {
      text: "PRIVACY_HEADER_MENU_MYDATA",
      link: ROUTES.MYDATA,
    },
    gaAction: EVENT_HEADER_ACTION_MY_DATA,
  },
];
