import React from "react";
import classNames from "classnames";
import "./Typography.scss";

enum variants {
  h1,
  h2,
  h3,
  title1,
  title2,
  title3,
  body1,
  body2,
  body3,
  caption,
  flag,
}

enum weights {
  w700,
  w400,
  w500,
  wMedium,
  wBold,
}

interface Props {
  variant: keyof typeof variants;
  weight?: keyof typeof weights;
  component?: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  ss?: boolean;
  onClick?: () => void;
  testId?: string;
}

export const Typography: React.FC<Props> = (props) => {
  const {
    variant,
    children,
    component = "div",
    className,
    style,
    ss,
    weight = "",
    onClick,
    testId,
  } = props;
  const wrapClass = classNames(
    "typography",
    { [variant]: !!variant },
    { [weight]: !!weight },
    { "typography--ss-font": !!ss },
    className
  );

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const CustomTag = component as keyof JSX.IntrinsicElements;

  return (
    <CustomTag
      data-testid={testId}
      className={wrapClass}
      style={style}
      onClick={handleOnClick}
    >
      {children}
    </CustomTag>
  );
};
