import React, { useEffect } from "react";

interface Props {
  children: React.ReactNode | JSX.Element;
  elements: string;
  isActive: boolean;
  // isAutofocus?: boolean;
}
const FocusTrapping: React.FC<Props> = (props) => {
  const modalRef = React.useRef(null);
  const { children, elements, isActive } = props;

  useEffect(() => {
    if (isActive) {
      const modalElement: React.LegacyRef<HTMLDivElement> = modalRef;
      const focusableElements =
        modalElement.current && modalElement.current.querySelectorAll(elements);

      const arrayOfElements =
        focusableElements && Array.from(focusableElements);
      const firstElement = focusableElements && focusableElements[0];
      const lastElement =
        focusableElements && focusableElements[focusableElements.length - 1];
      if (firstElement) {
        (firstElement as HTMLElement).setAttribute("tabindex", "0");
        (firstElement as HTMLElement).classList.add("firstChild");
        (firstElement as HTMLElement).focus();
      }

      const lastRegion = modalElement.current?.querySelector("#last-region");

      const handleTabKeyPress = (event: KeyboardEvent) => {
        if (
          event.key === "Tab" &&
          modalElement &&
          arrayOfElements &&
          document.activeElement
        ) {
          const index = arrayOfElements.indexOf(document.activeElement);

          // specific case for LocalPrivacySiteModal
          const lastClass =
            lastRegion?.classList[lastRegion?.classList.length - 1];
          const isLastRegion =
            document.activeElement.firstChild ===
            lastRegion?.querySelector("div");
          if (lastClass !== "active" && isLastRegion) {
            event.preventDefault();
            (firstElement as HTMLElement).setAttribute("tabindex", "0");
            (firstElement as HTMLElement).focus();
            (firstElement as HTMLElement).classList.remove("firstChild");
          }
          if (
            index === -1 ||
            ((lastElement as HTMLElement).hasAttribute("disabled") &&
              index === arrayOfElements.length - 2)
          ) {
            event.preventDefault();
            (firstElement as HTMLElement).setAttribute("tabindex", "0");
            (firstElement as HTMLElement).focus();
            (firstElement as HTMLElement).classList.remove("firstChild");
          }

          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            (lastElement as HTMLElement).setAttribute("tabindex", "0");
            (lastElement as HTMLElement).focus();
            (lastElement as HTMLElement).classList.remove("firstChild");
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            (firstElement as HTMLElement).setAttribute("tabindex", "0");
            (firstElement as HTMLElement).focus();
            (firstElement as HTMLElement).classList.remove("firstChild");
          }
        }
      };

      modalElement.current &&
        modalElement.current.addEventListener("keydown", handleTabKeyPress);

      return () => {
        modalElement.current &&
          modalElement.current.removeEventListener(
            "keydown",
            handleTabKeyPress
          );
      };
    }
  }, [modalRef, children, isActive]);

  return <div ref={modalRef}>{children}</div>;
};

export default FocusTrapping;
