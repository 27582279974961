import reactGA from "react-ga4";

import {
  HOSTNAME_DEV,
  HOSTNAME_PRD,
  HOSTNAME_STG,
  TRACKING_ID_DEV,
  TRACKING_ID_PRD_GA4,
  TRACKING_ID_STG_GA4,
  VALID_ROUTES,
} from "../constants/analytics";

import { ALLOW_PERFORMANCE_COOKIES } from "../constants/storage";

let isInitialized = false;

const initializeGoogleAnalytics = () => {
  const locationHost = window.location.host;
  const cookieExpiry = 31536000; // override default 2 yrs cookie expiry to just 1 yr
  const isGoogleCookiesAllowed =
    localStorage.getItem(ALLOW_PERFORMANCE_COOKIES) === "true";

  if (!isInitialized && isGoogleCookiesAllowed) {
    if (locationHost === HOSTNAME_STG) {
      reactGA.initialize(TRACKING_ID_STG_GA4, {
        gaOptions: {
          cookieExpires: cookieExpiry,
        },
      });
      isInitialized = true;
    } else if (locationHost === HOSTNAME_PRD) {
      reactGA.initialize(TRACKING_ID_PRD_GA4, {
        gaOptions: {
          cookieExpires: cookieExpiry,
        },
      });
      isInitialized = true;
    } else if (locationHost === HOSTNAME_DEV) {
      reactGA.initialize(TRACKING_ID_DEV, {
        gaOptions: {
          cookieExpires: cookieExpiry,
        },
      });
      isInitialized = true;
    }
  }
  return isInitialized;
};

export const disableGoogleAnalyticsTracking = () => {
  delete window.GoogleAnalyticsObject;
  delete window.ga;
  delete window.gaData;
  delete window.gaGlobal;
  delete window.gaplugins;
  delete window.google_tag_data;
};

export const sendPageView = () => {
  if (isInitialized) {
    const ga = reactGA.ga();
    const page = window.location.pathname;
    if (ga) {
      // Catch successful initial login
      if (window.location.search === "?success=1") {
        ga("set", "page", "/login-success");
        ga("send", "pageview");
      }

      if (VALID_ROUTES.includes(page)) {
        ga("set", "page", page);
        ga("send", "pageview");
      }
    }
  }
};

export default initializeGoogleAnalytics;
