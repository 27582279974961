import { SVG_ICONS } from "../components/__common/Svg/Svg";
import { GpsRegion, GPS_REGION_CODE } from "../types/gps-region";

export const GpsRegions: GpsRegion[] = [
  {
    code: GPS_REGION_CODE.LATIN_AMERICA,
    name: "Latin America",
    image: SVG_ICONS.GPS_MAP_LATIN_AMERICA,
    countries: [
      {
        code: "AR",
        name: "Argentina",
        link: "https://sdapla.privacy.samsung.com/privacy/ar",
      },
      {
        code: "BO",
        name: "Bolivia",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "BR",
        name: "Brasil ",
        link: "https://www.samsung.com/br/privacy/",
      },
      {
        code: "CL",
        name: "Chile",
        link: "https://sdapla.privacy.samsung.com/privacy/cl",
      },
      {
        code: "CO",
        name: "Colombia",
        link: "https://sdapla.privacy.samsung.com/privacy/co",
      },
      {
        code: "CR",
        name: "Costa Rica",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "EC",
        name: "Ecuador",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "SV",
        name: "El Salvador",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "GT",
        name: "Guatemala",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "HN",
        name: "Honduras",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "MX",
        name: "México",
        link: "https://sdapla.privacy.samsung.com/privacy/mx",
      },
      {
        code: "NI",
        name: "Nicaragua",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "PA",
        name: "Panamá",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "PY",
        name: "Paraguay",
        link: "https://sdapla.privacy.samsung.com/privacy/py",
      },
      {
        code: "PE",
        name: "Perú",
        link: "https://sdapla.privacy.samsung.com/privacy/pe",
      },
      {
        code: "DO",
        name: "República Dominicana",
        link: "https://sdapla.privacy.samsung.com/privacy/latin",
      },
      {
        code: "UY",
        name: "Uruguay",
        link: "https://sdapla.privacy.samsung.com/privacy/uy",
      },
    ],
  },
  {
    code: GPS_REGION_CODE.NORTH_AMERICA,
    name: "North America",
    image: SVG_ICONS.GPS_MAP_NORTH_AMERICA,
    countries: [
      { code: "US", name: "USA", link: "https://www.samsung.com/us/privacy/" },
    ],
  },
  {
    code: GPS_REGION_CODE.ASIA_PACIFIC,
    name: "Asia Pacific",
    image: SVG_ICONS.GPS_MAP_ASIA,
    countries: [
      {
        code: "AU",
        name: "Australia",
        link: "https://sapp.privacy.samsung.com/au/home",
      },
      {
        code: "CN",
        name: "中國",
        // name: "China",
        link: "https://privacy.samsung.com.cn/",
      },
      {
        code: "ID",
        name: "Indonesia",
        link: "https://sapp.privacy.samsung.com/id/home",
      },
      {
        code: "MY",
        name: "Malaysia",
        link: "https://sapp.privacy.samsung.com/my/home",
      },
      {
        code: "NZ",
        name: "New Zealand",
        link: "https://sapp.privacy.samsung.com/nz/home",
      },
      {
        code: "PH",
        name: "Philippines",
        link: "https://sapp.privacy.samsung.com/ph/home",
      },
      {
        code: "SG",
        name: "Singapore",
        link: "https://sapp.privacy.samsung.com/sg/home",
      },
      {
        code: "TH",
        // name: "ประเทศไทย",
        name: "Thailand",
        link: "https://sapp.privacy.samsung.com/th/home",
      },
      {
        code: "VN",
        // name: "Việt Nam",
        name: "Vietnam",
        link: "https://sapp.privacy.samsung.com/vn/home",
      },
    ],
  },
  {
    code: GPS_REGION_CODE.EUROPE,
    name: "Europe",
    image: SVG_ICONS.GPS_MAP_EUROPE,
    countries: [
      {
        code: "ALB",
        name: "Shqipëri ",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "AUT",
        name: "Österreich",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "BEL",
        name: "Belgium",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "BGR",
        name: "България",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "HRV",
        name: "Hrvatska",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "CZE",
        name: "Česká republika",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "DNK",
        name: "Danmark",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "EST",
        name: "Estonian",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "FIN",
        name: "Suomi",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "FRA",
        name: "France",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "DEU",
        name: "Deutschland",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "GRC",
        name: "Ελλάδα",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "HUN",
        name: "Magyarország",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "ISL",
        name: "Iceland",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "IRL",
        name: "Ireland",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "ITA",
        name: "Italia",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "LVA",
        name: "Latvija",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "LTU",
        name: "Lietuva",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "LUX",
        name: "Luxembourg",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "MLT",
        name: "Malta",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "NLD",
        name: "Nederland",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "NOR",
        name: "Norge",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "POL",
        name: "Polska",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "PRT",
        name: "Portugal",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "ROU",
        name: "Romania",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "SRB",
        name: "Cрбија",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "SVK",
        name: "Slovensko",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "SVN",
        name: "Slovenija ",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "ESP",
        name: "Espana",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "SWE",
        name: "Sverige",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "CHE",
        name: "Schweiz",
        link: "https://www.samsung.com/request-desk",
      },
      {
        code: "TUR",
        name: "Türkiye",
        link: "https://kvkk-portal.samsung.com/",
      },
      {
        code: "GBR",
        name: "UK",
        link: "https://www.samsung.com/request-desk",
      },
    ],
  },
  {
    code: GPS_REGION_CODE.MENA,
    name: "MENA",
    image: SVG_ICONS.GPS_MAP_MENA,
    countries: [
      {
        code: "AF",
        name: "Afghanistan",
        link: "https://samsung.menaprivacy.com/pk_en",
      },
      {
        code: "DZ",
        name: "Algérie",
        link: "https://samsung.menaprivacy.com/n_africa",
      },
      {
        code: "BH",
        name: "البحرين",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "EG",
        name: "مصر",
        link: "https://samsung.menaprivacy.com/eg_ar",
      },
      {
        code: "IR",
        name: "ایران",
        link: "https://samsung.menaprivacy.com/ir_fa",
      },
      {
        code: "IQ",
        name: "العراق",
        link: "https://samsung.menaprivacy.com/iq_ar",
      },
      {
        code: "IL",
        name: "ישראל",
        link: "https://samsung.menaprivacy.com/il_he",
      },
      {
        code: "JO",
        name: "الأردن",
        link: "https://samsung.menaprivacy.com/jo_en",
      },
      {
        code: "KW",
        name: "الكويت",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "LB",
        name: "لبنان",
        link: "https://samsung.menaprivacy.com/jo_en",
      },
      {
        code: "MA",
        name: "Maroc",
        link: "https://samsung.menaprivacy.com/n_africa",
      },
      {
        code: "OM",
        name: "عُمان",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "PK",
        name: "Pakistan",
        link: "https://samsung.menaprivacy.com/pk_en",
      },
      {
        code: "QA",
        name: "قطر",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "SA",
        name: "المملكة العربية السعودية ",
        link: "https://samsung.menaprivacy.com/sa_en",
      },
      {
        code: "TN",
        name: "Tunisie",
        link: "https://samsung.menaprivacy.com/n_africa",
      },
      {
        code: "AE",
        name: "الإمارات العربية المتحد",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
      {
        code: "YE",
        name: "اليمن",
        link: "https://samsung.menaprivacy.com/ae_en",
      },
    ],
  },
];
