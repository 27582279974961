import { CustomAction } from "..";
import { HubCountry } from "../../types/landing-page";
import { SetLanguage } from "../../types/layout";
import { LAYOUT_ACTION_TYPES } from "../actions-types";

export const actionSetLanguage = (data: SetLanguage): CustomAction => {
  return {
    type: LAYOUT_ACTION_TYPES.SET_LAYOUT_LANGUAGE,
    data,
  };
};

export const actionSetManageCookieOpen = (data: boolean): CustomAction => {
  return {
    type: LAYOUT_ACTION_TYPES.SET_MANAGE_COOKIE_OPEN,
    data,
  };
};

export const actionSetShowNoticeOpen = (data: boolean): CustomAction => {
  return {
    type: LAYOUT_ACTION_TYPES.SET_SHOW_NOTICE_OPEN,
    data,
  };
};

export const actionSetHighContrast = (data: boolean): CustomAction => {
  return {
    type: LAYOUT_ACTION_TYPES.SET_HIGHT_CONTRAST,
    data,
  };
};

export const actionSetCookieNoticeOpen = (data: boolean): CustomAction => {
  return {
    type: LAYOUT_ACTION_TYPES.SET_SHOW_COOKIE_NOTICE_OPEN,
    data,
  };
};

export const actionSetSurveyOpen = (data: boolean): CustomAction => {
  return {
    type: LAYOUT_ACTION_TYPES.SET_SHOW_SURVEY_OPEN,
    data,
  };
};

export const actionSetTTSVoices = (
  data: Array<SpeechSynthesisVoice>
): CustomAction => {
  return {
    type: LAYOUT_ACTION_TYPES.SET_TTS_VOICES,
    data,
  };
};

export const actionSetSelectedCountry = (data: HubCountry): CustomAction => {
  return {
    type: LAYOUT_ACTION_TYPES.SET_SELECTED_COUNTRY,
    data,
  };
};

export const actionSetIsCookieNoticeAccepted = (
  data: boolean
): CustomAction => {
  return {
    type: LAYOUT_ACTION_TYPES.SET_IS_COOKIE_NOTICE_ACCEPTED,
    data,
  };
};
