import React from "react";
import { ErrorDetail } from "../../../components/ErrorDetail/ErrorDetail";

export const ErrorMaintenance: React.FC = () => {
  return (
    <>
      <ErrorDetail variant="errorMaintenance" />
    </>
  );
};
