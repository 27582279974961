import { ServiceEntity } from "../../types/services";
import { SERVICES_ACTION_TYPES } from "../actions-types";
import { CustomAction } from "../index";

export const actionSetServices = (data: ServiceEntity[]): CustomAction => {
  return {
    type: SERVICES_ACTION_TYPES.SET_SERVICES,
    data,
  };
};

export const actionSetServicesLoading = (): CustomAction => {
  return {
    type: SERVICES_ACTION_TYPES.SET_SERVICES_LOADING,
  };
};
export const actionSetSelectedServices = (data: string[]): CustomAction => {
  return {
    type: SERVICES_ACTION_TYPES.SET_SELECTED_SERVICES,
    data,
  };
};
