import { CustomAction } from "..";
import { PrivacyPolicyLang } from "../../types/privacy";
import { PRIVACY_POLICY_TYPES } from "../actions-types";

export const actionSetPrivacyLanguageRegion = (
  data: PrivacyPolicyLang
): CustomAction => {
  return {
    type: PRIVACY_POLICY_TYPES.SET_LANGUAGE_REGION,
    data,
  };
};
