import classNames from "classnames";
import React from "react";

import "./Switch.scss";

interface Props {
  value?: boolean;
  onToggle?: (value: boolean) => void;
  testId?: string;
}
export const Switch: React.FC<Props> = (props) => {
  const { value = false, onToggle, testId } = props;

  const wrapClass = classNames("switch-content", { on: value });

  const handleToggle = () => {
    if (onToggle) {
      onToggle(!value);
    }
  };

  return (
    <button className={wrapClass} onClick={handleToggle} data-testid={testId}>
      <div className="switch-content__track"></div>
      <div className="switch-content__thumb"></div>
    </button>
  );
};
