import React from "react";
import { useHistory } from "react-router-dom";

import { SummaryEntity } from "../../../../../types/mydata";
import { ROUTES } from "../../../../../configs/routes";
import { Button } from "../../../../../components/__common/_controls";
import { RequestDetails } from "../RequestDetails/RequestDetails";
import { useTranslation } from "react-i18next";
import triggerGAEvent from "../../../../../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_MY_DATA,
  EVENT_MY_DATA_ACTION_SUMMARY_VIEW_ALL,
} from "../../../../../constants/analytics";
import { useMyDataHooks } from "../../../../../hooks/mydata-hooks";
import { useHub } from "../../../../../hooks/hub-hooks";

interface Props {
  summary: SummaryEntity;
}

export const LatestRequests: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { summary } = props;
  const { resetRequestBody } = useMyDataHooks();
  const history = useHistory();
  const { handleGARequestHistoryRedirect } = useHub();

  return (
    <>
      <div className="mydata-summary__services-container">
        <ul>
          {summary.download && (
            <li>
              <RequestDetails
                request={summary.download}
                route={ROUTES.MYDATA}
                isFromSummary
              />
            </li>
          )}

          {summary.delete && (
            <li>
              <RequestDetails
                request={summary.delete}
                route={ROUTES.MYDATA}
                isFromSummary
              />
            </li>
          )}

          {summary.unsubscribe && (
            <li>
              <RequestDetails
                request={summary.unsubscribe}
                route={ROUTES.MYDATA}
                isFromSummary
              />
            </li>
          )}
        </ul>
      </div>
      <div className="mydata-summary__services-viewall-container">
        {summary.count.total_count > 0 && (
          <span>
            <Button
              variant="outlined"
              type="content"
              title={`${t("PRIVACY_MYDATA_SUMMARY_BTN_VIEWALL")} (${
                summary.count.total_count
              })`}
              className="mydata-summary__services-viewall-container__button"
              onClick={() => {
                handleGARequestHistoryRedirect();
                triggerGAEvent(
                  EVENT_CATEGORY_MY_DATA,
                  EVENT_MY_DATA_ACTION_SUMMARY_VIEW_ALL
                );
                resetRequestBody();
                history.push(ROUTES.MYDATA_HISTORY);
              }}
            />
          </span>
        )}
      </div>
    </>
  );
};
