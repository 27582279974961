import React, { useEffect } from "react";
import Loading from "../../../assets/images/gif/loading_transparent.gif";
import LoadingHC from "../../../assets/images/gif/loading_transparent_HC.gif";
import { Image } from "../../../components/__common/Image";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import './DigitalLegacyAuth.scss';
import { useAuth } from "../../../hooks/auth-hooks";

export const DigitalLegacyAuth: React.FC = () => {

  const { digitalLegacyLogin } = useAuth();
  useEffect(() => {
    digitalLegacyLogin();
  }, [])
  const { isHighContrast } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  return <>
    <Image
      image={!isHighContrast ? Loading : LoadingHC}
      width={60}
      height={60}
      className="dl-route__loading"
    />
  </>
}

export default DigitalLegacyAuth;