import { SustainabilityLink } from "../types/countries";

export const SustainabilityLinks: SustainabilityLink[] = [
  {
    countryCode: "DZ",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/	",
      },
    ],
  },
  {
    countryCode: "AO",
    links: [
      {
        trans: "pt",
        link: "https://www.samsung.com/africa_pt/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "BJ",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "BW",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "BF",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "BI",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "CV",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "CF",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "TD",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "KM",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "CG",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "CD",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "CI",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "DJ",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "EG",
    links: [
      {
        trans: "ar",
        link: "https://www.samsung.com/eg/sustainability/digital-responsibility/privacy/",
      },
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "GQ",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "ER",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "ET",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "GA",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "GM",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "GH",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "GN",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "GW",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "KE",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "LS",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "LR",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "LY",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "MG",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "MW",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "ML",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "MR",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "MU",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "MA",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
      {
        trans: "ar",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "MZ",
    links: [
      {
        trans: "pt",
        link: "https://www.samsung.com/africa_pt/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "NA",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "NE",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "NG",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "RE",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "RW",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "ST",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "SN",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "SC",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "SL",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "ZA",
    links: [
      {
        trans: "za",
        link: "https://www.samsung.com/za/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "SS",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "SD",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "SZ",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "TZ",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_en/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "TG",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "TN",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "UG",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "ZM",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "ZW",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/africa_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },

  //AMERICA

  {
    countryCode: "AR",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/ar/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "BS",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "BO",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "BR",
    links: [
      {
        trans: "pt",
        link: "https://www.samsung.com/br/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "CA",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ca/sustainability/security-and-privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/ca_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "CL",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/cl/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "CO",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/co/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "CR",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "DO",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "EC",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "SV",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "GT",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "HN",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "JM",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "MX",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/mx/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "NI",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "PA",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "PY",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/py/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "PE",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/pe/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "PR",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "TT",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/latin/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "UY",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/uy/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "US",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/us/about-us/digital-responsibility/privacy/",
      },
    ],
  },

  // ASIA PACIFIC

  {
    countryCode: "AU",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/au/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "BD",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/bd/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "CN",
    links: [
      {
        trans: "zh",
        link: "https://www.samsung.com/cn/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "HK",
    links: [
      {
        trans: "zh",
        link: "https://www.samsung.com/hk/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "IN",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/in/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "IN",
    links: [
      {
        trans: "id",
        link: "https://www.samsung.com/id/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "KR",
    links: [
      {
        trans: "ko",
        link: "https://www.samsung.com/sec/sustainability/privacy-and-security/",
      },
    ],
  },
  {
    countryCode: "MY",
    links: [
      {
        trans: "ms",
        link: "https://www.samsung.com/my/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "MM",
    links: [
      {
        trans: "my",
        link: "https://www.samsung.com/mm/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "NZ",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/nz/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "PH",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ph/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "SG",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/sg/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "TW",
    links: [
      {
        trans: "zh",
        link: "https://www.samsung.com/tw/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "TH",
    links: [
      {
        trans: "th",
        link: "https://www.samsung.com/th/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "VN",
    links: [
      {
        trans: "vi",
        link: "https://www.samsung.com/vn/sustainability/security-and-privacy/",
      },
    ],
  },

  //CIS

  {
    countryCode: "KZ",
    links: [
      {
        trans: "kk",
        link: "https://www.samsung.com/kz_kz/sustainability/digital-responsibility/privacy/",
      },
      {
        trans: "ru",
        link: "https://www.samsung.com/kz_ru/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "RU",
    links: [
      {
        trans: "ru",
        link: "https://www.samsung.com/ru/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "UZ",
    links: [
      {
        trans: "uz",
        link: "https://www.samsung.com/uz_uz/sustainability/digital-responsibility/privacy/",
      },
    ],
  },

  //Europe

  {
    countryCode: "AL",
    links: [
      {
        trans: "sq",
        link: "https://www.samsung.com/al/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "AT",
    links: [
      {
        trans: "de",
        link: "https://www.samsung.com/at/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "BE",
    links: [
      {
        trans: "nl",
        link: "https://www.samsung.com/be/sustainability/security-and-privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/be_fr/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "BA",
    links: [
      {
        trans: "hr",
        link: "https://www.samsung.com/ba/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "BG",
    links: [
      {
        trans: "bg",
        link: "https://www.samsung.com/bg/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "HR",
    links: [
      {
        trans: "hr",
        link: "https://www.samsung.com/hr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "CZ",
    links: [
      {
        trans: "cs",
        link: "https://www.samsung.com/cz/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "DK",
    links: [
      {
        trans: "da",
        link: "https://www.samsung.com/dk/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "EE",
    links: [
      {
        trans: "et",
        link: "https://www.samsung.com/ee/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "FI",
    links: [
      {
        trans: "fi",
        link: "https://www.samsung.com/fi/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "FR",
    links: [
      {
        trans: "fr",
        link: "https://www.samsung.com/fr/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "DE",
    links: [
      {
        trans: "de",
        link: "https://www.samsung.com/de/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "GR",
    links: [
      {
        trans: "el",
        link: "https://www.samsung.com/gr/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "HU",
    links: [
      {
        trans: "hu",
        link: "https://www.samsung.com/hu/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "IE",
    links: [
      {
        trans: "gd",
        link: "https://www.samsung.com/ie/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "IT",
    links: [
      {
        trans: "it",
        link: "https://www.samsung.com/it/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "LV",
    links: [
      {
        trans: "lv",
        link: "https://www.samsung.com/lv/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "NL",
    links: [
      {
        trans: "nl",
        link: "https://www.samsung.com/nl/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "NO",
    links: [
      {
        trans: "no",
        link: "https://www.samsung.com/no/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "PL",
    links: [
      {
        trans: "pl",
        link: "https://www.samsung.com/pl/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "PT",
    links: [
      {
        trans: "pt",
        link: "https://www.samsung.com/pt/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "RO",
    links: [
      {
        trans: "ro",
        link: "https://www.samsung.com/ro/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "RS",
    links: [
      {
        trans: "sr",
        link: "https://www.samsung.com/rs/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "SK",
    links: [
      {
        trans: "sk",
        link: "https://www.samsung.com/ro/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "SI",
    links: [
      {
        trans: "sl",
        link: "https://www.samsung.com/si/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "ES",
    links: [
      {
        trans: "es",
        link: "https://www.samsung.com/es/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "SE",
    links: [
      {
        trans: "sv",
        link: "https://www.samsung.com/se/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "CH",
    links: [
      {
        trans: "de",
        link: "https://www.samsung.com/ch/sustainability/digital-responsibility/privacy/",
      },
      {
        trans: "fr",
        link: "https://www.samsung.com/ch_fr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "TR",
    links: [
      {
        trans: "tr",
        link: "https://www.samsung.com/tr/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "UA",
    links: [
      {
        trans: "uk",
        link: "https://www.samsung.com/ua/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "GB",
    links: [
      {
        trans: "rn",
        link: "https://www.samsung.com/uk/sustainability/security-and-privacy/",
      },
    ],
  },

  // Middle East

  {
    countryCode: "AF",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ae/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "BH",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ae/sustainability/security-and-privacy/",
      },
      {
        trans: "ar",
        link: "https://www.samsung.com/ae_ar/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "IR",
    links: [
      {
        trans: "fa",
        link: "https://www.samsung.com/iran/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "IQ",
    links: [
      {
        trans: "ar",
        link: "https://www.samsung.com/iq_ar/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "IL",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/il/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "JO",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/levant/sustainability/security-and-privacy/",
      },
      {
        trans: "ar",
        link: "https://www.samsung.com/levant_ar/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "KW",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ae/sustainability/security-and-privacy/",
      },
      {
        trans: "ar",
        link: "https://www.samsung.com/ae_ar/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "LB",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/lb/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "OM",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ae/sustainability/security-and-privacy/",
      },
      {
        trans: "ar",
        link: "https://www.samsung.com/ae_ar/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "PK",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/pk/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "QA",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ae/sustainability/security-and-privacy/",
      },
      {
        trans: "ar",
        link: "https://www.samsung.com/ae_ar/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "SA",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/sa_en/sustainability/security-and-privacy/",
      },
      {
        trans: "ar",
        link: "https://www.samsung.com/sa/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "SO",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ae/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "SY",
    links: [
      {
        trans: "ar",
        link: "https://www.samsung.com/ae_ar/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "TM",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ae/sustainability/security-and-privacy/",
      },
    ],
  },
  {
    countryCode: "AE",
    links: [
      {
        trans: "en",
        link: "https://www.samsung.com/ae/sustainability/security-and-privacy/",
      },
      {
        trans: "ar",
        link: "https://www.samsung.com/ae_ar/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
  {
    countryCode: "YE",
    links: [
      {
        trans: "ar",
        link: "https://www.samsung.com/ae_ar/sustainability/digital-responsibility/privacy/",
      },
    ],
  },
];
