import classNames from "classnames";
import React from "react";

import "./Overlay.scss";

interface Props {
  onClick?: () => void;
  open?: boolean;
  testId?: string;
}

export const Overlay: React.FC<Props> = (props) => {
  const { onClick, open = false, testId } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={classNames("overlay", { active: open })}
      onClick={handleClick}
      data-testid={testId}
    ></div>
  );
};
