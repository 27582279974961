import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  GrrsEU,
  GrrsNoneEuCountries,
  GrrsSiteCountry,
  GRRS_EU_COUNTRIES,
  GRRS_NON_EU_COUNTRIES,
} from "../../data/global-rights-countries";
import { ApplicationState } from "../../store";
import { UserState } from "../../store/reducers/user-reducer";
import { Container } from "../Grid/Grid";
import { Svg, SVG_ICONS } from "../__common/Svg/Svg";
import { Typography } from "../__common/Typography/Typography";
import "./GlobalSiteLinkPopup.scss";
import { useCookie } from "../../hooks/cookie-hooks";
import triggerGAEvent from "../../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_GPS_POPUP,
  EVENT_GPS_ACTION_LINK_VISIT,
  EVENT_GPS_ACTION_COUNTRY_VISIT,
} from "../../constants/analytics";
import { UtilsUser } from "../../utils/UtilsUser";

interface Props {
  onHide: () => void;
  testId?: string;
}

export const GlobalSiteLinkPopup: React.FC<Props> = (props) => {
  const { onHide, testId } = props;

  const { t } = useTranslation();
  const { isShowMemberNotice, setCookieShowMemberNotice } = useCookie();
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { userData } = userState;
  const [grrsCountryData, setGrrsCountryData] =
    useState<GrrsSiteCountry | null>(null);

  const handleOnHideClick = () => {
    onHide();
    setCookieShowMemberNotice();
  };

  const handleGAEvent = (country: string, eventType?: string) => {
    if (eventType === "linkEvent") {
      triggerGAEvent(
        EVENT_CATEGORY_GPS_POPUP,
        `${EVENT_GPS_ACTION_LINK_VISIT}-${country}`
      );
    } else {
      triggerGAEvent(
        EVENT_CATEGORY_GPS_POPUP,
        `${EVENT_GPS_ACTION_COUNTRY_VISIT}-${country}`
      );
    }
  };

  useEffect(() => {
    if (
      Object.values(GRRS_NON_EU_COUNTRIES).includes(
        userData.countryCode as GRRS_NON_EU_COUNTRIES
      )
    ) {
      handleGAEvent(userData.countryCode);
      setGrrsCountryData(
        GrrsNoneEuCountries[userData.countryCode as GRRS_NON_EU_COUNTRIES]
      );
    }

    if (
      Object.values(GRRS_EU_COUNTRIES).includes(
        userData.countryCode as GRRS_EU_COUNTRIES
      )
    ) {
      handleGAEvent(userData.countryCode);
      setGrrsCountryData(GrrsEU);
    }
  }, [userData.countryCode]);

  return isShowMemberNotice && !UtilsUser.isEmailLogin() ? (
    grrsCountryData && (
      <>
        <Container>
          <div className="global-site__container">
            <Svg icon={SVG_ICONS.MAP_PIN} width={24} height={24} />
            <Typography
              variant="body1"
              component="span"
              className="global-site__container__info"
              weight="w500"
            >
              {t("PRIVACY_GRRS_NOTICE_COUNTRY", { "%s": grrsCountryData.name })}
            </Typography>
            <a
              onClick={() => handleGAEvent(grrsCountryData.code, "linkEvent")}
              href={grrsCountryData.link}
              rel="noreferrer"
              target="_blank"
              className="global-site__container__info__link"
            >
              {t("PRIVACY_GRRS_NOTICE_TEXT", {
                "%s": grrsCountryData.desc
                  ? grrsCountryData.desc
                  : grrsCountryData.name,
              })}
            </a>
            <button
              data-testid={testId}
              onClick={handleOnHideClick}
              className="global-site__container__close"
            >
              <Svg icon={SVG_ICONS.CLOSE_SMALL} width={16} height={16} />
            </button>
          </div>
        </Container>
      </>
    )
  ) : (
    <></>
  );
};
