import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import LocalPrivacySiteModal from "../../LocalPrivacySiteModal/LocalPrivacySiteModal";
import Default from "./ManagePrivacyContent/Default";
import CountrySpecific from "./ManagePrivacyContent/CountrySpecific";
import "./ManagePrivacy.scss";
import { UtilsUser } from "../../../../../utils/UtilsUser";
import { useMyDataHooks } from "../../../../../hooks/mydata-hooks";
import { MyDataState } from "../../../../../store/reducers/mydata-reducer";
import { UserState } from "../../../../../store/reducers/user-reducer";
import { useIntersectionObserver } from "../../../../../hooks/intersection-hooks";
import { useHub } from "../../../../../hooks/hub-hooks";
import FocusTrapping from "../../../../../components/__hoc/FocusTrapping";

const ManagePrivacy: React.FC = () => {
  const { history, historyReqBody } = useSelector<
    ApplicationState,
    MyDataState
  >((state) => state.myData);
  const { userData } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const [isCountryListModalOpen, setIsCountryListModalOpen] =
    useState<boolean>(false);
  const { loadMyDataHistory } = useMyDataHooks();
  const { hasLocalSite } = useHub();
  const ref = useRef<HTMLDivElement>(null);
  const visible = useIntersectionObserver(ref, {
    rootMargin: "0px",
    threshold: 0.8,
  });

  const removeSnapScroll = () => {
    if (!visible) {
      return;
    }
    const el = document.getElementById("main-body");
    el?.classList.remove("snap-scroll");
  };

  const addSnapScroll = () => {
    const el = document.getElementById("main-body");
    if (el?.scrollTop === 0) {
      el.classList.add("snap-scroll");
    }
  };

  const renderContent = () => {
    // Scenario 1
    // if (!hasSelectedCountryCookie) {
    //   return <Default setModalOpen={setIsCountryListModalOpen} />;
    // }

    // Scenario 2
    if (!hasLocalSite) {
      return (
        <CountrySpecific
          setModalOpen={setIsCountryListModalOpen}
          hasLocalSite={hasLocalSite}
        />
      );
    }

    // Scenario 3
    if (
      (UtilsUser.isUserLoggedIn() && history.request_count === 0) ||
      !UtilsUser.isUserLoggedIn()
    ) {
      return <Default setModalOpen={setIsCountryListModalOpen} />;
    } else {
      return (
        <CountrySpecific
          setModalOpen={setIsCountryListModalOpen}
          hasLocalSite={hasLocalSite}
        />
      );
    }
  };

  useEffect(() => {
    if (UtilsUser.isUserLoggedIn()) {
      loadMyDataHistory(historyReqBody);
    }
  }, [userData.loginId]);

  useEffect(() => {
    removeSnapScroll();
  }, [visible]);

  useEffect(() => {
    document
      .getElementById("main-body")
      ?.addEventListener("scroll", addSnapScroll);

    return () =>
      document
        .getElementById("main-body")
        ?.removeEventListener("scroll", addSnapScroll);
  }, []);

  return (
    <>
      <FocusTrapping
        isActive={isCountryListModalOpen}
        elements="div.modal-body, div.title2, button, input, a"
      >
        <div className="manage-privacy" id="manage-privacy" ref={ref} data-testId="manage-privacy">
          {renderContent()}
        </div>
        <LocalPrivacySiteModal
          show={isCountryListModalOpen}
          onClickClose={() => setIsCountryListModalOpen(false)}
        />
      </FocusTrapping>
    </>
  );
};

export default ManagePrivacy;
