import { CustomAction } from "../index";
import { ServiceRequestErrorEntity, SummaryEntity } from "../../types/mydata";
import { MYDATA_ACTION_TYPES } from "../actions-types";
import {
  myDataHistoryDefaults,
  myDataHistoryReqBodyDefaults,
  requestDetailsDefaults,
  serviceRequestToastErrorDefaults,
  summaryDefaults,
} from "../../data/defaults";
import { RequestDetailsEntity } from "../../types/request-details";
import { MyDataHistoryEntity, MyDataHistoryReqBody } from "../../types/history";

export interface MyDataState {
  summary: SummaryEntity;
  details: RequestDetailsEntity;
  history: MyDataHistoryEntity;
  historyReqBody: MyDataHistoryReqBody;
  isSummaryLoading: boolean;
  requestNumber: string;
  isRequestPending: boolean;
  isSummaryLoaded: boolean;
  isRequestDetailsLoading: boolean;
  isFilePasswordSent: boolean;
  isCancelRequestComplete: boolean;
  isCancelRequestPending: boolean;
  isHistoryLoading: boolean;
  isHistoryLoaded: boolean;
  hasMoreHistory: boolean;
  isHistoryMoreLoading: boolean;
  isGlobalSiteLinkOpen: boolean;
  serviceRequestToastError: ServiceRequestErrorEntity;
  isUserEmailNotSet: boolean;
}

const defaultState: MyDataState = {
  summary: summaryDefaults,
  details: requestDetailsDefaults,
  history: myDataHistoryDefaults,
  historyReqBody: myDataHistoryReqBodyDefaults,
  isSummaryLoading: true,
  requestNumber: "",
  isRequestPending: false,
  isSummaryLoaded: false,
  isRequestDetailsLoading: true,
  isFilePasswordSent: false,
  isCancelRequestComplete: false,
  isCancelRequestPending: false,
  isHistoryLoading: false,
  isHistoryLoaded: false,
  hasMoreHistory: false,
  isHistoryMoreLoading: false,
  isGlobalSiteLinkOpen: true,
  serviceRequestToastError: serviceRequestToastErrorDefaults,
  isUserEmailNotSet:false,
};

const myDataReducer = (state = defaultState, action: CustomAction) => {
  switch (action.type) {
    case MYDATA_ACTION_TYPES.SET_SUMMARY:
      return {
        ...state,
        summary: action.data,
        isSummaryLoading: false,
        isSummaryLoaded: true,
      };
    case MYDATA_ACTION_TYPES.SET_SUMMARY_LOADING:
      return {
        ...state,
        isSummaryLoading: true,
        isSummaryLoaded: false,
      };
    case MYDATA_ACTION_TYPES.SET_REQUEST_DETAILS:
      return {
        ...state,
        details: action.data,
        isRequestDetailsLoading: false,
      };
    case MYDATA_ACTION_TYPES.SET_REQUEST_DETAILS_LOADING:
      return {
        ...state,
        isRequestDetailsLoading: true,
      };
    case MYDATA_ACTION_TYPES.SET_FILE_PASSWORD_SEND:
      return {
        ...state,
        isFilePasswordSent: action.data,
      };
    case MYDATA_ACTION_TYPES.SET_REQUEST:
      return {
        ...state,
        requestNumber: action.data.request_number,
        isRequestPending: false,
      };
    case MYDATA_ACTION_TYPES.SET_CANCEL_REQUEST_COMPLETE:
      return {
        ...state,
        isCancelRequestComplete: action.data,
      };

    case MYDATA_ACTION_TYPES.SET_CANCEL_REQUEST_PENDING:
      return {
        ...state,
        isCancelRequestPending: action.data,
      };
    case MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY:
      return {
        ...state,
        history: action.data,
        isHistoryLoading: false,
        isHistoryLoaded: true,
      };
    case MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY_LOADED:
      return {
        ...state,
        isHistoryLoaded: action.data,
      };
    case MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY_LOADING:
      return {
        ...state,
        isHistoryLoading: true,
        isHistoryLoaded: false,
      };
    case MYDATA_ACTION_TYPES.SET_REQUEST_PENDING:
      return {
        ...state,
        isRequestPending: true,
      };
    case MYDATA_ACTION_TYPES.SET_MYDATA_LOADMORE_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          next_offset: action.data.next_offset,
          request_count: action.data.request_count,
          request_list: [
            ...state.history.request_list,
            ...action.data.request_list,
          ],
        },
        isHistoryLoaded: true,
      };
    case MYDATA_ACTION_TYPES.SET_MYDATA_HAS_MORE_HISTORY:
      return {
        ...state,
        hasMoreHistory: action.data,
      };
    case MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY_MORE_LOADING:
      return {
        ...state,
        isHistoryMoreLoading: action.data,
      };
    case MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY_REQ_BODY:
      return {
        ...state,
        historyReqBody: action.data,
      };
    case MYDATA_ACTION_TYPES.SET_MYDATA_GLOBAL_SITE_LINK_POPUP_CLOSE:
      return {
        ...state,
        isGlobalSiteLinkOpen: false,
      };
    case MYDATA_ACTION_TYPES.SET_IS_SHOW_SERVICE_TOAST_ERROR:
      return {
        ...state,
        serviceRequestToastError: action.data,
        isRequestPending: false,
      };
      case MYDATA_ACTION_TYPES.SET_USER_EMAIL_IS_NOT_SET:
        return {
          ...state,
          isUserEmailNotSet: action.data,
        };
    default:
      return state;
  }
};

export default myDataReducer;
