import { useHistory } from "react-router-dom";

export const UtilsUseHistory = () => {
  const history = useHistory();

  const go = (times: number) => {
    history.go(times);
  };

  const push = (config: any, extraSettings: string) => {
    history.push(config, extraSettings);
  };

  return {
    go,
    push,
  };
};
