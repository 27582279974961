import { useDispatch, useSelector } from "react-redux";
import {
  API_BASE_URL,
  API_ENDPOINTS,
  API_PUBLIC_AUTH_TOKEN,
} from "../constants/api";
import { Survey, User, UserEmailLoginInfo } from "../types/user";
import {
  DEFAULT_COUNTRY_CODE,
  EUR_COUNTRIES,
  EU_COUNTRIES,
  USA_COUNTRIES,
  US_COUNTRIES,
} from "../constants/locale";

import Http from "../services/http";
import { ApplicationState } from "../store";
import {
  actionSetGeoIpLoading,
  actionSetGetDataUserLoading,
  actionSetRestriction,
  actionSetSACountry,
  actionSetUser,
  actionSetUserCountry,
  actionSetUserRegion,
  actionSetUserAnsweredSurvey,
  actionSetGetSurveyResponsePending,
  actionSetSubmitSurveyPending,
  actionSetUserEmailLoginInfo,
} from "../store/actions/user-actions";
import { UserState } from "../store/reducers/user-reducer";
import { UtilsLocale } from "../utils/UtilsLocale";
import { LocalStorage } from "../services/storage";
import {
  GEO_IP,
  RESTRICTED,
  USER_DATA_NAMESPACE,
  USER_SESSION_ID,
} from "../constants/storage";
import { UtilsUser } from "../utils/UtilsUser";
import { generateToken } from "../utils/UtilsTokenGenerator";
import { useSiteSettingsHook } from "./site-settings-hooks";
import { IS_LOCALHOST, IS_PRE, IS_PROD } from "../constants/environment";
import { DETECTED } from "../constants/site-settings";
import { SystemState } from "../store/reducers/site-reducer";

export const useUserHooks = () => {
  const dispatch = useDispatch();
  const { getCountryCode } = useSiteSettingsHook();
  const { userCountryCode, userData, userRegion } = useSelector<
    ApplicationState,
    UserState
  >((state) => state.user);

  const siteSettings = useSelector<ApplicationState, SystemState>(
    (state) => state.system
  );

  const loadUserCountryCode = async () => {
    try {
      dispatch(actionSetGeoIpLoading(true));

      const countryCode = IS_LOCALHOST
        ? DEFAULT_COUNTRY_CODE
        : await getCountryCode();

      // NOTE: Geo IP state does not persist so we set it to session storage.
      //       To be used for download/deletion of apps and services (SDAP-1247)
      sessionStorage.setItem(GEO_IP, countryCode);
      dispatch(actionSetUserCountry(countryCode));

      const isUsCountry: boolean = UtilsLocale.checkIfCountryIsFromRegion(
        countryCode,
        US_COUNTRIES
      );
      const isEuropeanCountry: boolean = UtilsLocale.checkIfCountryIsFromRegion(
        countryCode,
        EU_COUNTRIES
      );

      dispatch(
        actionSetUserRegion({
          isFromUS: isUsCountry,
          isFromEurope: isEuropeanCountry,
        })
      );
    } catch (e) {
      dispatch(actionSetGeoIpLoading(false));
      console.error(e);
    }
  };

  const getUserData = async () => {
    try {
      dispatch(actionSetGetDataUserLoading(true));

      const url = `${API_BASE_URL}${API_ENDPOINTS.PROFILE}`;
      const data = await Http.get(url);

      const saAge =
        siteSettings.sysProps.SA_AGE !== DETECTED && !IS_PROD && !IS_PRE
          ? Number(siteSettings.sysProps.SA_AGE)
          : undefined;

      const saCountry =
        siteSettings.sysProps.SA_COUNTRY !== DETECTED && !IS_PROD && !IS_PRE
          ? UtilsLocale.convertISO2toISO3(siteSettings.sysProps.SA_COUNTRY)
          : (data.countryCode as string);

      const userData: User = {
        birthDate: data.birthday, // SA_AGE
        countryCode: data.countryCode, // SA_COUNTRY
        joinedDate: data.joinedDate,
        loginId: data.loginId,
        loginIdType: data.loginIdType,
        mail: data.mail,
        name: data.name,
        validatedEmail: data.validatedEmail,
        profileImageUrl: data.profileImageUrl,
      };

      LocalStorage.set(USER_DATA_NAMESPACE, JSON.stringify(userData));

      const isSAFromEurope = UtilsLocale.checkIfCountryIsFromRegion(
        saCountry,
        EUR_COUNTRIES
      );

      const isRestricted = UtilsUser.isUserRestricted(
        saCountry,
        userData.birthDate,
        isSAFromEurope,
        saAge
      );

      sessionStorage.setItem(RESTRICTED, String(isRestricted));
      const isSAFromAmerica = UtilsLocale.checkIfCountryIsFromRegion(
        saCountry,
        USA_COUNTRIES
      );

      const isSAUSA = saCountry === "USA";
      const authState = generateToken(12);
      LocalStorage.set(USER_SESSION_ID, authState);

      dispatch(actionSetUser(userData));
      dispatch(
        actionSetSACountry({
          isSAFromAmerica: isSAFromAmerica,
          isSAFromUSA: isSAUSA,
          isSAFromEurope: isSAFromEurope,
        })
      );
      dispatch(actionSetRestriction(isRestricted));
      dispatch(actionSetGetDataUserLoading(false));

      return userData;
    } catch (e: any) {
      dispatch(actionSetGetDataUserLoading(false));
      LocalStorage.remove(USER_DATA_NAMESPACE);
      return;
    }
  };

  const getUserSurveyResponse = async () => {
    try {
      dispatch(actionSetGetSurveyResponsePending(true));
      const url = `${API_BASE_URL}/${API_ENDPOINTS.SURVEY}`;

      const extraHeaders = {
        Authorization: API_PUBLIC_AUTH_TOKEN,
      };

      const data = await Http.get(url, extraHeaders);

      dispatch(actionSetUserAnsweredSurvey(data.answered));
      dispatch(actionSetGetSurveyResponsePending(false));
    } catch (e: any) {
      dispatch(actionSetGetDataUserLoading(false));
    }
  };

  const submitUserSurvey = async (formData: Survey) => {
    try {
      dispatch(actionSetSubmitSurveyPending(true));
      const url = `${API_BASE_URL}/${API_ENDPOINTS.SURVEY}`;

      const extraHeaders = {
        Authorization: API_PUBLIC_AUTH_TOKEN,
      };

      const userSurveyData: Survey = {
        survey_id: 1,
        answer_1: formData.answer_1,
        comments: formData.comments?.trim(),
      };

      await Http.post(url, userSurveyData, extraHeaders);

      dispatch(actionSetUserAnsweredSurvey(true));
      dispatch(actionSetSubmitSurveyPending(false));
    } catch (e: any) {
      dispatch(actionSetGetDataUserLoading(false));
    }
  };

  const setUserEmailLoginInfoData = (data: UserEmailLoginInfo) => {
    dispatch(actionSetUserEmailLoginInfo(data));
  };

  return {
    loadUserCountryCode,
    userRegion,
    userData,
    userCountryCode,
    getUserData,
    submitUserSurvey,
    getUserSurveyResponse,
    setUserEmailLoginInfoData,
  };
};
