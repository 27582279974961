import { CustomAction } from "..";
import { LOGIN_OPTION_TYPES } from "../actions-types";

export const actionSetLoginModalOpen = (data: {
  isLoginModalOpen: boolean;
  loginModalRedirect?: string;
}): CustomAction => {
  return {
    type: LOGIN_OPTION_TYPES.SET_LOGIN_MODAL_OPEN,
    data,
  };
};

export const actionSetEmailLoginModalOpen = (data: boolean): CustomAction => {
  return {
    type: LOGIN_OPTION_TYPES.SET_EMAIL_LOGIN_MODAL_OPEN,
    data,
  };
};

export const actionSetEmailOtpModalOpen = (data: boolean): CustomAction => {
  return {
    type: LOGIN_OPTION_TYPES.SET_EMAIL_OTP_MODAL_OPEN,
    data,
  };
};

export const actionSetOtpErrorMessage = (data: string): CustomAction => {
  return {
    type: LOGIN_OPTION_TYPES.SET_OTP_ERROR_MESSAGE,
    data,
  };
};
