import classNames from "classnames";
import React from "react";
import { Svg, SVG_ICONS } from "../Svg/Svg";
import { Typography } from "../Typography/Typography";
import { TTSButton } from "../_controls/TTSButton/TTSButton";

import "./StaticModal.scss";

interface ModalSubComponents {
  Header: React.FC<HeaderProps>;
  Title: React.FC<TitleProps>;
  Body: React.FC<BodyProps>;
  Footer: React.FC<FooterProps>;
}

interface HeaderProps {
  children?: React.ReactNode;
  onHide?: () => void;
}

interface TitleProps {
  children?: string;
  ttsButtonId?: string;
}

interface BodyProps {
  id?: string;
  children?: React.ReactNode;
  isNoFooter?: boolean;
}

interface FooterProps {
  children?: React.ReactNode;
}

interface Props {
  show: boolean;
  children?: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  autoHeight?: boolean;
  id?: string;
  modalBodyId?: string;
  tabIndex?: number;
  testId?: string;
}

const StaticModal: React.FC<Props> & ModalSubComponents = (props) => {
  const {
    show,
    children,
    className = "",
    wrapperClassName = "",
    autoHeight = false,
    id,
    modalBodyId,
    tabIndex,
    testId,
  } = props;
  const wrapClass = classNames(
    "static-modal-wrapper",
    { open: !!show },
    wrapperClassName
  );
  const bodyClass = classNames("modal", {
    [className]: !!className,
    "auto-height": autoHeight,
  });

  return (
    <div className={wrapClass} id={id} tabIndex={tabIndex} data-testid={testId}>
      {/* <Overlay open={show} /> */}
      <div className="modal-content">
        <div className={bodyClass} id={modalBodyId}>
          {children}
        </div>
      </div>
    </div>
  );
};

const Header: React.FC<HeaderProps> = (props) => (
  <div className="modal-header">
    {props.children}
    <button className="modal-header__close__btn" onClick={props.onHide}>
      <Svg icon={SVG_ICONS.DRAWER_CLOSE} width={24} height={24} />
    </button>
  </div>
);

const Title: React.FC<TitleProps> = (props) => (
  <div className="modal-title">
    <Typography variant="title2">
      {props.children}
      {props.children && (
        <TTSButton
          value={props.children}
          className="tts-button-modal"
          id={props.ttsButtonId}
        />
      )}
    </Typography>
  </div>
);

const Body: React.FC<BodyProps> = (props) => {
  const { isNoFooter = false, id } = props;
  return (
    <div
      className={classNames("modal-body", { "no-footer": isNoFooter })}
      id={id}
    >
      {props.children}
    </div>
  );
};

const Footer: React.FC<FooterProps> = (props) => (
  <div className="modal-footer">{props.children}</div>
);

StaticModal.Header = Header;
StaticModal.Title = Title;
StaticModal.Body = Body;
StaticModal.Footer = Footer;

export default StaticModal;
