import React from "react";

import { Col, Container, Row } from "../../../../components/Grid/Grid";
import { Svg, SVG_ICONS } from "../../../../components/__common/Svg/Svg";
import { Typography } from "../../../../components/__common/Typography/Typography";
import { useTranslation } from "react-i18next";

export const DeleteGuide: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="usage-guide-modal__table">
      <Typography
        variant="body2"
        weight="w700"
        className="usage-guide-modal__table__title"
      >
        {t("PRIVACY_MYDATA_USAGE_GUIDE_DELETE_TITLE")}
      </Typography>
      <Container>
        <Row className="usage-guide-modal__table__row">
          <Col sm={12} className="usage-guide-modal__table__row__left-col">
            <Typography
              variant="body3"
              className="usage-guide-modal__table__number"
            >
              1
            </Typography>
          </Col>
          <Col sm={12} className="usage-guide-modal__table__row__left-col icon">
            <Svg icon={SVG_ICONS.GUIDE_NOTICE} width={64} height={64} />
          </Col>
          <Col sm={12} className="usage-guide-modal__table__row__text-col">
            <Typography variant="body3">
              {t("PRIVACY_MYDATA_USAGE_GUIDE_DELETE_TEXT1")}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="line" />
          </Col>
        </Row>
        <Row className="usage-guide-modal__table__row">
          <Col sm={12} className="usage-guide-modal__table__row__left-col">
            <Typography
              variant="body3"
              className="usage-guide-modal__table__number"
            >
              2
            </Typography>
          </Col>
          <Col sm={12} className="usage-guide-modal__table__row__left-col icon">
            <Svg icon={SVG_ICONS.GUIDE_DELETE} width={64} height={64} />
          </Col>
          <Col className="usage-guide-modal__table__row__text-col">
            <Typography variant="body3" component="div">
              {t("PRIVACY_MYDATA_USAGE_GUIDE_DELETE_TEXT2")}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="line" />
          </Col>
        </Row>
        <Row className="usage-guide-modal__table__row">
          <Col sm={12} className="usage-guide-modal__table__row__left-col">
            <Typography
              variant="body3"
              className="usage-guide-modal__table__number"
            >
              3
            </Typography>
          </Col>
          <Col sm={12} className="usage-guide-modal__table__row__left-col icon">
            <Svg icon={SVG_ICONS.GUIDE_STEP_DELETE} width={64} height={64} />
          </Col>
          <Col className="usage-guide-modal__table__row__text-col">
            <Typography variant="body3" component="div">
              {t("PRIVACY_MYDATA_USAGE_GUIDE_DELETE_TEXT3")}
            </Typography>
            <Typography
              variant="body3"
              component="div"
              className="usage-guide-modal__table__row__text-col__bullets first"
            >
              {t("PRIVACY_MYDATA_USAGE_GUIDE_DELETE_TEXT3_SUB2")}
            </Typography>
            <Typography
              variant="body3"
              component="div"
              className="usage-guide-modal__table__row__text-col__bullets"
            >
              {t("PRIVACY_MYDATA_USAGE_GUIDE_DELETE_TEXT3_SUB3")}
            </Typography>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
