import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import { ROUTES } from "../configs/routes";
import { REQUEST_NUMBER } from "../constants/storage";
import { UtilsBrowser } from "../utils/UtilsBrowser";
import initializeGoogleAnalytics, { sendPageView } from "../utils/UtilsGA";
import { UtilsUser } from "../utils/UtilsUser";
import { Cookie } from "./storage";

const locationHelper = locationHelperBuilder({});

export const nonAuth = connectedRouterRedirect({
  authenticatedSelector: () => {
    const isInitialized = initializeGoogleAnalytics();
    if (isInitialized) {
      sendPageView();
    }

    return !UtilsBrowser.isInternetExplorer();
  },
  redirectPath: (_, ownProps) => {
    return UtilsBrowser.isInternetExplorer()
      ? ROUTES.ERROR_IE
      : locationHelper.getRedirectQueryParam(ownProps) || ROUTES.ROOT;
  },
  wrapperDisplayName: "UserIsNotAuthenticated",
});

export const auth = connectedRouterRedirect({
  authenticatedSelector: (_, ownProps: any) => {
    // Cookie.remove(REQUEST_NUMBER);
    if (ownProps.match.params.requestNumber && !UtilsUser.isUserLoggedIn()) {
      Cookie.set(REQUEST_NUMBER, ownProps.match.params.requestNumber);
    }

    const isInitialized = initializeGoogleAnalytics();
    if (isInitialized) {
      sendPageView();
    }
    return UtilsUser.isUserLoggedIn();
  },
  redirectPath: ROUTES.ROOT, // NOTE: Redirected back to home page so that user will be presented login options (email/SA)
  wrapperDisplayName: "UserIsAuthenticated",
});

export const unsupportedBrowserOnly = connectedRouterRedirect({
  authenticatedSelector: () => {
    return UtilsBrowser.isInternetExplorer();
  },
  redirectPath: ROUTES.ROOT,
});
