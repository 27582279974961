export const Languages: any = {
  ar: "العربية",
  en: "English",
  pt: "Português",
  fa: "Farsi",
  fr: "Français",
  zh_MO: "中文(繁体)\u200E",
  zh_CN: "中文\u200E",
  zh_HK: "繁體中文\u200E",
  zh_SG: "中文(简体)\u200E",
  zh_TW: "繁體中文\u200E",
  he: "עברית",
  id: "Bahasa Indonesia",
  ja: "日本語",
  ky: "Kyrgyz",
  ko: "한국어",
  ms: "Bahasa Melayu",
  th: "ไทย",
  vi: "Tiếng Việt",
  ru: "Русский",
  ru_RU: "Русский",
  nl: "Nederlands",
  hr: "Croatian",
  bg: "Български",
  el: "Ελληνικά",
  cs: "Čeština",
  da: "Dansk",
  et: "Eesti",
  fi: "Suomi",
  mk: "Македонски",
  hu: "Magyar",
  gd: "Irish",
  it: "Italiano",
  kk: "Қазақша ",
  lv: "Latvian",
  lt: "Lietuvių",
  ro: "Română",
  sr: "Српски",
  no: "Norsk",
  pl: "Polski",
  sk: "Slovenčina",
  sl: "Slovenščina",
  es: "Español",
  sv: "Svenska",
  de: "Deutsch",
  tr: "Türkçe",
  uk: "Українська",
  ur: "اردو",
  uz: "O‘zbek tili",
  as: "অসমীয়া",
  az: "azərbaycan dili",
  bo: "tibétain",
  bn: "বাংলা",
  ca: "català",
  en_US: "US English",
  en_UK: "UK English",
  es_MX: "Español",
  eu: "euskara",
  fr_CA: "Français",
  ga: "Gaeilge",
  gl: "Galego",
  gu: "ગુજરાતી",
  hi: "हिन्दी",
  hy: "Armenian",
  in_ID: "Bahasa Indonesia",
  is: "Íslenska",
  iw: "עברית",
  ka: "ქართული",
  km: "ខ្មែរ",
  kn: "ಕನ್ನಡ",
  lo: "ພາສາລາວ",
  ml: "മലയാളം",
  mn: "Монгол хэл",
  mr: "मराठी",
  my: "Burmese",
  ne: "नेपाली",
  or: "ଓଡ଼ିଆ",
  pa: "ਪੰਜਾਬੀ",
  pt_BR: "Português",
  si: "සිංහල",
  sq: "Shqip",
  ta: "தமிழ்",
  te: "తెలుగు",
  tg: "тоҷикӣ",
  tk: "Türkmen",
  tl: "Wikang Tagalog",
  ug: "ouïgour",
  zh: "中文(简体)",
};
