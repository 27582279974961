import {
  DEFAULT_BROWSER_LANGUAGE,
  DEFAULT_LANGUAGE,
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_REGION,
} from "../constants/locale";
import {
  COUNTRY,
  COUNTRY_CODE,
  HIGH_CONTRAST,
  LANGUAGE,
  LANGUAGE_CODE,
  LANGUAGE_TRANS,
  REGION,
  USER_SELECTED_COUNTRY,
} from "../constants/storage";
import { Cookie } from "../services/storage";
import { LayoutState } from "../store/reducers/layout-reducer";
import {
  HISTORY_REQUEST_TYPE,
  MyDataHistoryEntity,
  MyDataHistoryReqBody,
  SORT_TYPE,
} from "../types/history";
import { ServiceRequestErrorEntity, SummaryEntity } from "../types/mydata";
import { RequestDetailsEntity } from "../types/request-details";
import { User } from "../types/user";
import { UtilsParseCookieValue } from "../utils/UtilsConverter";

export const userDefaults: User = {
  countryCode: "",
  loginId: "",
  loginIdType: "",
  mail: "",
  name: "",
  validatedEmail: "",
  birthDate: "",
  joinedDate: "",
  profileImageUrl: "",
};

export const summaryDefaults: SummaryEntity = {
  count: {
    total_count: 0,
    total_count_inprogress: 0,
    total_count_requesting: 0,
    total_count_completed: 0,
  },
  download: undefined,
  delete: undefined,
  unsubscribe: undefined,
};

export const requestDetailsDefaults: RequestDetailsEntity = {
  request_number: "",
  request_type: "",
  request_status: "",
  lang_trns: "",
  sub_request_count: 0,
  sub_request_list: [],
  user_request_date: "",
  file_expire: "",
  complete_date: "",
};

export const myDataHistoryDefaults: MyDataHistoryEntity = {
  request_count: 0,
  next_offset: 0,
  request_list: [],
};

export const myDataHistoryReqBodyDefaults: MyDataHistoryReqBody = {
  request_type: HISTORY_REQUEST_TYPE.ALL,
  sort_by: SORT_TYPE.date_desc,
  limit: 10,
  offset: 0,
};

export const languageLayoutDefaults: LayoutState = {
  region: Cookie.get(REGION) || DEFAULT_REGION,
  country: Cookie.get(COUNTRY) || "",
  countryCode: Cookie.get(COUNTRY_CODE) || "",
  language: Cookie.get(LANGUAGE) || DEFAULT_LANGUAGE,
  languageCode: Cookie.get(LANGUAGE_CODE) || DEFAULT_LANGUAGE_CODE,
  languageTrans: Cookie.get(LANGUAGE_TRANS) || "",
  browserLanguage: DEFAULT_BROWSER_LANGUAGE,
  isRTL: false,
  isManageCookieOpen: false,
  isShowNotice: false,
  isHighContrast: (Cookie.get(HIGH_CONTRAST) === "true" && true) || false,
  isShowCookieNotice: false,
  isShowSurvey: false,
  ttsVoices: [],
  selectedCountry: Cookie.get(USER_SELECTED_COUNTRY)
    ? UtilsParseCookieValue(USER_SELECTED_COUNTRY)
    : {
        code: "",
        name: "",
        displayName: "",
        link: "",
      },
  isCookieNoticeAccepted: false,
};

export const serviceRequestToastErrorDefaults: ServiceRequestErrorEntity = {
  isError: false,
  hasRendered: false,
  isSAUnverifiedError: false,
};
