import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./CookiePolicy.scss";
import { Typography } from "../../../components/__common/Typography/Typography";
import { LinkButton, TTSButton } from "../../../components/__common/_controls";
import { SVG_ICONS } from "../../../components/__common/Svg/Svg";
import { ApplicationState } from "../../../store";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import { ManageCookieModal } from "../../../components/CookieModal/ManageCookie";
import { useCookie } from "../../../hooks/cookie-hooks";
import { UserState } from "../../../store/reducers/user-reducer";
import { ROUTES } from "../../../configs/routes";
import FocusTrapping from "../../../components/__hoc/FocusTrapping";

export const CookiePolicy: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setManageCookieModalOpen } = useCookie();
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { isGeoIPLoading, userRegion } = userState;
  const { countryCode, languageCode } = layoutState;
  // useState variable for manage cookie modal for cookie policy page only
  const [isOpen, setIsOpen] = useState(false);

  useEffect(
    () => {
      if (!userRegion.isFromEurope && !isGeoIPLoading) {
        history.replace(ROUTES.ERROR_404);
      }
    },
    // eslint-disable-next-line
    [userRegion.isFromEurope, isGeoIPLoading]
  );

  const [CookiePolicy, setCookiePolicy] = useState<React.FC>(
    lazy(() =>
      new Promise((resolve) => setTimeout(resolve, 0))
        .then(async () => import(`./countriesContent/${countryCode}`))
        .catch(() => import("./countriesContent/GB"))
    )
  );

  useEffect(() => {
    setTimeout(() => {
      const scroll = document.getElementById("main-body");
      if (scroll) scroll.scrollTop = 0;
    }, 500);

    const code =
      countryCode === "BE" && languageCode === "fr"
        ? "fr-BE"
        : countryCode === "CH" && languageCode === "fr"
        ? "fr-CH"
        : countryCode === "AL" && languageCode === "en"
        ? "GB"
        : countryCode;

    setCookiePolicy(
      lazy(() =>
        new Promise((resolve) => setTimeout(resolve, 0))
          .then(async () => import(`./countriesContent/${code}`))
          .catch(() => import("./countriesContent/GB"))
      )
    );
  }, [countryCode, languageCode]);

  return !isGeoIPLoading ? (
    <>
      {isOpen && (
        <FocusTrapping
          isActive={isOpen}
          elements="svg, button, div.title2 , div.modal-body, div.manage-cookie"
        >
          <ManageCookieModal isOpen={isOpen} onHide={() => setIsOpen(false)} />
        </FocusTrapping>
      )}
      <div className="cookie-policy">
        <div className="cookie-policy__title-section">
          <Typography
            variant="h2"
            className="privacy-policy__title-section__title sharp-sans"
          >
            {t("PRIVACY_COOKIE_POLICY_TITLE")}
            <TTSButton value={t("PRIVACY_COOKIE_POLICY_TITLE")} />
          </Typography>
        </div>
        <div className="cookie-policy__title-section__button">
          <LinkButton
            testId="open-manage-cookie-modal-button"
            to="#"
            title={t("PRIVACY_FOOTER_MENU_MANAGE_COOKIES")}
            icon={SVG_ICONS.SETTING}
            iconPosition="left"
            iconSize={24}
            underlined={false}
            onClick={() => setManageCookieModalOpen(true)}
          />
        </div>
        <Suspense fallback={<div></div>}>
          {
            <div dir="ltr">
              <CookiePolicy />
            </div>
          }
        </Suspense>
      </div>
    </>
  ) : (
    <></>
  );
};
