import { ROUTES } from "./routes";

export const ROUTES_NO_HELP_TIPS = [
  ROUTES.MYDATA,
  ROUTES.BDP_CONSIGNMENT,
  ROUTES.ANALYTICS_CONSIGNMENT,
  ROUTES.DIAGMON_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT_WITH_KR,
  ROUTES.DIGITAL_LEGACY_AUTH
];

// NOTE: separate this variable into two if there are
//       routes that has no header but has a footer
//       or vice versa
//       e.g.:
//         - export const ROUTES_NO_HEADER
//         - export const ROUTES_NO_FOOTER
export const ROUTES_NO_HEADER_AND_FOOTER = [
  ROUTES.BDP_CONSIGNMENT,
  ROUTES.ANALYTICS_CONSIGNMENT,
  ROUTES.DIAGMON_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT_WITH_KR,
  ROUTES.ADSSO_LOGOUT,
  ROUTES.DIGITAL_LEGACY_AUTH
];

export const ROUTES_NO_COOKIE_NOTICE = [
  ROUTES.BDP_CONSIGNMENT,
  ROUTES.ANALYTICS_CONSIGNMENT,
  ROUTES.DIAGMON_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT_WITH_KR,
  ROUTES.DIGITAL_LEGACY_AUTH
];

// export const ROUTES_NO_LOCAL_SITE_PROMPT = [ROUTES.BDP_CONSIGNMENT];
export const ROUTES_CONSIGNMENT_PAGE = [
  ROUTES.BDP_CONSIGNMENT,
  ROUTES.ANALYTICS_CONSIGNMENT,
  ROUTES.DIAGMON_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT,
  ROUTES.OLD_CONSIGNMENT_WITH_KR,
];

/**
 * Country codes within this array has the option to login via Email or SA
 *
 * Format:
 * - ISO 3166 alpha-2 (2 letter country code)
 * - Arranged alphabetically
 */
export const EMAIL_LOGIN_SUPPORTED_COUNTRIES = [
  "AT", // Austria
  "BE", // Belgium
  "CH", // Switzerland
  "CZ", // Czech
  "DE", // Germany
  "DK", // Denmark
  "EE", // Estonia
  "ES", // Spain
  "FI", // Finland
  "FR", // France
  "GB", // United Kingdom
  "GR", // Greece
  "HR", // Croatia
  "HU", // Hungary
  "LT", // Lithuania
  "LV", // Latvia
  "NL", // Netherlands
  "NO", // Norway
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SE", // Sweden
  "SI", // Slovenia
  "SK", // Slovakia
];
