import React from "react";
import { useSelector } from "react-redux";

import "./Loading.scss";
import { ApplicationState } from "../../../store";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import { Image } from "../Image";
import LoadingNHC from "../../../assets/images/gif/loading_transparent.gif";
import LoadingHC from "../../../assets/images/gif/loading_transparent_HC.gif";

const Loading = () => {
  const { isHighContrast } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  return (
    <div className="loading-screen">
      <Image
        image={!isHighContrast ? LoadingNHC : LoadingHC}
        width={60}
        height={60}
        className="loading-screen__spinner"
      />
    </div>
  );
};

export default Loading;
