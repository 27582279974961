import { LANGUAGE_VIEW, Region } from "../types/countries";

export const Regions: Region[] = [
  {
    name: "Africa",
    countries: [
      {
        code: "DZ",
        name: "Algeria",
        displayName: "Algérie",
        languages: [
          { isRTL: false, code: "en", trans: "en", cUsLang: "en_us" },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
        ],
      },
      {
        code: "AO",
        name: "Angola",
        displayName: "Angola",
        languages: [
          {
            isRTL: false,
            code: "pt",
            trans: "pt-rPT",
            cUsLang: "pt_pt",
          },
        ],
      },
      {
        code: "BJ",
        name: "Benin",
        displayName: "Bénin",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "BW",
        name: "Botswana",
        displayName: "Botswana",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "BF",
        name: "Burkina Faso",
        displayName: "Burkina Faso",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "BI",
        name: "Burundi",
        displayName: "Burundi",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "CV",
        name: "Cape Verde",
        displayName: "Cabo Verde",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "CM",
        name: "Cameroon",
        displayName: "Cameroun",
        languages: [
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
        ],
      },
      {
        code: "KM",
        name: "Comoros",
        displayName: "Comores",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "CG",
        name: "Congo",
        displayName: "Congo",
        languages: [
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
        ],
      },
      {
        code: "CI",
        name: "Cote D'Ivoire",
        displayName: "Côte d’Ivoire",
        languages: [
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
        ],
      },
      {
        code: "DJ",
        name: "Djibouti",
        displayName: "Djibouti",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "CD",
        name: "DR Congo",
        displayName: "DR Congo",
        languages: [
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
        ],
      },
      {
        code: "EG",
        name: "Egypt",
        displayName: "مصر",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "GQ",
        name: "Equatorial Guinea",
        displayName: "Equatorial Guinea",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "ER",
        name: "Eritrea",
        displayName: "Eritrea",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "ET",
        name: "Ethiopia",
        displayName: "Ethiopia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "GA",
        name: "Gabon",
        displayName: "Gabon",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "GM",
        name: "Gambia",
        displayName: "Gambia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "GH",
        name: "Ghana",
        displayName: "Ghana",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "GW",
        name: "Guinea-Bissau",
        displayName: "Guiné-Bissau",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "MU",
        name: "Mauritius",
        displayName: "Île Maurice ",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "KE",
        name: "Kenya",
        displayName: "Kenya",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "LS",
        name: "Lesotho",
        displayName: "Lesotho",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "LR",
        name: "Liberia",
        displayName: "Liberia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "LY",
        name: "Libya",
        displayName: "ليبيا",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
        ],
      },
      {
        code: "MG",
        name: "Madagascar",
        displayName: "Madagascar",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "MW",
        name: "Malawi",
        displayName: "Malawi",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "ML",
        name: "Mali",
        displayName: "Mali",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "MA",
        name: "Morocco",
        displayName: "Maroc",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
        ],
      },
      {
        code: "MR",
        name: "Mauritania",
        displayName: "Mauritanie",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
        ],
      },

      {
        code: "MZ",
        name: "Mozambique",
        displayName: "Moçambique",
        languages: [
          {
            isRTL: false,
            code: "pt",
            trans: "pt-rPT",
            cUsLang: "pt_pt",
          },
        ],
      },
      {
        code: "NA",
        name: "Namibia",
        displayName: "Namibia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "NE",
        name: "Niger",
        displayName: "Niger",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "NG",
        name: "Nigeria",
        displayName: "Nigeria",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "CF",
        name: "Central African Republic",
        displayName: "République centrafricaine",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "GN",
        name: "Guinea",
        displayName: "République de Guinée",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "RE",
        name: "Reunion",
        displayName: "Réunion",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "RW",
        name: "Rwanda",
        displayName: "Rwanda",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "ST",
        name: "Sao Tome and Principe",
        displayName: "Sao Tome and Principe",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "SN",
        name: "Senegal",
        displayName: "Sénégal",
        languages: [
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
        ],
      },
      {
        code: "SC",
        name: "Seychelles",
        displayName: "Seychelles",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "SL",
        name: "Sierra Leone",
        displayName: "Sierra Leone",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "SO",
        name: "Somalia",
        displayName: "الصومال",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "ZA",
        name: "South Africa",
        displayName: "South Africa",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "SS",
        name: "South Sudan",
        displayName: "South Sudan",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "SD",
        name: "Sudan",
        displayName: "السودان",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "SZ",
        name: "Swaziland",
        displayName: "Swaziland",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "TZ",
        name: "Tanzania, United Republic Of",
        displayName: "Tanzania",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "TD",
        name: "Chad",
        displayName: "Tchad",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "TG",
        name: "Togo",
        displayName: "Togo",
        languages: [
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "TN",
        name: "Tunisia",
        displayName: "Tunisie",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
        ],
      },
      {
        code: "UG",
        name: "Uganda",
        displayName: "Uganda",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "ZM",
        name: "Zambia",
        displayName: "Zambia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "ZW",
        name: "Zimbabwe",
        displayName: "Zimbabwe",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
    ],
  },
  {
    name: "Asia Pacific",
    countries: [
      {
        code: "AU",
        name: "Australia",
        displayName: "Australia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "BD",
        name: "Bangladesh",
        displayName: "Bangladesh",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "BT",
        name: "Bhutan",
        displayName: "Bhutan",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "BN",
        name: "Brunei Darussalam",
        displayName: "Brunei Darussalam",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "KH",
        name: "Cambodia",
        displayName: "Cambodia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "CN",
        name: "China",
        displayName: "中国大陆",
        languages: [
          {
            isRTL: false,
            code: "zh_CN",
            trans: "zh-rCN",
            cUsLang: "zh_cn",
          },
        ],
      },
      {
        code: "CK",
        name: "Cook Islands",
        displayName: "Cook Islands",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "FJ",
        name: "Fiji",
        displayName: "Fiji",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "PF",
        name: "French Polynesia",
        displayName: "French Polynesia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "GE",
        name: "Georgia",
        displayName: "Georgia",
        languages: [
          {
            isRTL: false,
            code: "ru",
            trans: "ru",
            cUsLang: "ru",
          },
        ],
      },
      {
        code: "HK",
        name: "Hong Kong",
        displayName: "香港",
        languages: [
          {
            isRTL: false,
            code: "zh_HK",
            trans: "zh-rHK",
            cUsLang: "zh_hk",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
        ],
      },
      {
        code: "IN",
        name: "India",
        displayName: "India",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "ID",
        name: "Indonesia",
        displayName: "Indonesia",
        languages: [
          {
            isRTL: false,
            code: "id",
            trans: "id",
            cUsLang: "id",
          },
        ],
      },
      {
        code: "JP",
        name: "Japan",
        displayName: "日本",
        languages: [
          {
            isRTL: false,
            code: "ja",
            trans: "ja",
            cUsLang: "ja",
          },
        ],
      },
      {
        code: "KZ",
        name: "Kazakhstan",
        displayName: "Казахстан",
        languages: [
          {
            isRTL: false,
            code: "kk",
            trans: "kk-rKZ",
            cUsLang: "kk",
          },
          {
            isRTL: false,
            code: "ru",
            trans: "ru",
            cUsLang: "ru",
          },
        ],
      },
      {
        code: "KR",
        name: "Korea",
        displayName: "한국",
        languages: [
          {
            isRTL: false,
            code: "ko",
            trans: "ko",
            cUsLang: "ko",
          },
        ],
      },
      {
        code: "KI",
        name: "Kiribati",
        displayName: "Kiribati",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "KG",
        name: "Kyrgyzstan",
        displayName: "Kyrgyzstan",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },

      {
        code: "LA",
        name: "Lao People's Democratic Republic",
        displayName: "Lao People's Democratic Republic",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "MO",
        name: "Macao",
        displayName: "Macao",
        languages: [
          {
            isRTL: false,
            code: "zh_MO",
            trans: "zh-rHK",
            cUsLang: "zh_hk",
          },
        ],
      },
      {
        code: "MY",
        name: "Malaysia",
        displayName: "Malaysia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
          {
            isRTL: false,
            code: "ms",
            trans: "ms-rMY",
            cUsLang: "ms",
          },
        ],
      },
      {
        code: "MV",
        name: "Maldives",
        displayName: "Maldives",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "MH",
        name: "Marshall Islands",
        displayName: "Marshall Islands",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "FM",
        name: "Micronesia, Federated States Of",
        displayName: "Micronesia, Federated States Of",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "MN",
        name: "Mongolia",
        displayName: "Монгол",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "MM",
        name: "Myanmar",
        displayName: "Myanmar",
        languages: [
          {
            isRTL: false,
            code: "my",
            trans: "my-rMM",
            cUsLang: "my",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "NR",
        name: "Nauru",
        displayName: "Nauru",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "NP",
        name: "Nepal",
        displayName: "Nepal",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "NC",
        name: "New Caledonia",
        displayName: "New Caledonia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "NZ",
        name: "New Zealand",
        displayName: "New Zealand",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "NU",
        name: "Niue",
        displayName: "Niue",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "PW",
        name: "Palau",
        displayName: "Palau",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "PG",
        name: "Papua New Guinea",
        displayName: "Papua New Guinea",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "PH",
        name: "Philippines",
        displayName: "Philippines",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "WS",
        name: "Samoa",
        displayName: "Samoa",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "SG",
        name: "Singapore",
        displayName: "Singapore",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
          {
            isRTL: false,
            code: "zh_SG",
            trans: "zh-rCN",
            cUsLang: "zh_cn",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "SB",
        name: "Solomon Islands",
        displayName: "Solomon Islands",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "LK",
        name: "Sri Lanka",
        displayName: "Sri Lanka",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "TW",
        name: "Taiwan",
        displayName: "台灣",
        languages: [
          {
            isRTL: false,
            code: "zh_TW",
            trans: "zh-rTW",
            cUsLang: "zh_tw",
          },
        ],
      },
      {
        code: "TJ",
        name: "Tajikistan",
        displayName: "Tajikistan",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "TH",
        name: "Thailand",
        displayName: "ประเทศไทย",
        languages: [
          {
            isRTL: false,
            code: "th",
            trans: "th",
            cUsLang: "th",
          },
        ],
      },
      {
        code: "TL",
        name: "Timor-Leste",
        displayName: "Timor-Leste",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "TO",
        name: "Tonga",
        displayName: "Tonga",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
          {
            isRTL: false,
            code: "en_UK",
            trans: "en-rUK",
            cUsLang: "en_uk",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
        ],
      },
      {
        code: "TV",
        name: "Tuvalu",
        displayName: "Tuvalu",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "UZ",
        name: "Uzbekistan",
        displayName: "Uzbekistan",
        languages: [
          {
            isRTL: false,
            code: "ru",
            trans: "ru",
            cUsLang: "ru",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
          {
            isRTL: false,
            code: "uz",
            trans: "uz-rUZ",
            cUsLang: "uz",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
        ],
      },
      {
        code: "VU",
        name: "Vanuatu",
        displayName: "Vanuatu",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "VN",
        name: "Vietnam",
        displayName: "Việt Nam",
        languages: [
          {
            isRTL: false,
            code: "vi",
            trans: "vi",
            cUsLang: "vi",
          },
        ],
      },
    ],
  },
  {
    name: "Europe",
    countries: [
      {
        code: "AL",
        name: "Albania",
        displayName: "Shqipëri",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
          },
          {
            isRTL: false,
            code: "sq",
            trans: "sq-rAL",
            cUsLang: "sq",
          },
        ],
      },
      {
        code: "AD",
        name: "Andorra",
        displayName: "Andorra",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "AM",
        name: "Armenia",
        displayName: "Armenia",
        languages: [
          {
            isRTL: false,
            code: "hy",
            trans: "hy",
            cUsLang: "hy",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
          {
            isRTL: false,
            code: "ru",
            trans: "ru",
            cUsLang: "ru",
          },
        ],
      },
      {
        code: "AT",
        name: "Austria",
        displayName: "Österreich",
        languages: [
          {
            isRTL: false,
            code: "de",
            trans: "de",
            cUsLang: "de",
          },
        ],
      },
      {
        code: "AZ",
        name: "Azerbaijan",
        displayName: "Azerbaijan",
        languages: [
          {
            isRTL: false,
            code: "ru",
            trans: "ru",
            cUsLang: "ru",
          },
        ],
      },
      {
        code: "BY",
        name: "Belarus",
        displayName: "Belarus",
        languages: [
          {
            isRTL: false,
            code: "ru",
            trans: "ru",
            cUsLang: "ru",
          },
        ],
      },
      {
        code: "BE",
        name: "Belgium",
        displayName: "Belgium",
        languages: [
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
          {
            isRTL: false,
            code: "nl",
            trans: "nl",
            cUsLang: "nl",
          },
        ],
      },
      {
        code: "BA",
        name: "Bosnia and Herzegovina",
        displayName: "Bosnia And Herzegovina",
        languages: [
          {
            isRTL: false,
            code: "hr",
            trans: "hr",
            cUsLang: "hr",
          },
        ],
      },
      {
        code: "BG",
        name: "Bulgaria",
        displayName: "България",
        languages: [
          {
            isRTL: false,
            code: "bg",
            trans: "bg",
            cUsLang: "bg",
          },
        ],
      },
      {
        code: "HR",
        name: "Croatia",
        displayName: "Hrvatska",
        languages: [
          {
            isRTL: false,
            code: "hr",
            trans: "hr",
            cUsLang: "hr",
          },
        ],
      },
      {
        code: "CZ",
        name: "Czech",
        displayName: "Česká republika",
        languages: [
          {
            isRTL: false,
            code: "cs",
            trans: "cs",
            cUsLang: "cs",
          },
        ],
      },
      {
        code: "CY",
        name: "Cyprus",
        displayName: "Cyprus",
        languages: [
          {
            isRTL: false,
            code: "el",
            trans: "el",
            cUsLang: "el",
          },
        ],
      },
      {
        code: "DK",
        name: "Denmark",
        displayName: "Danmark",
        languages: [
          {
            isRTL: false,
            code: "da",
            trans: "da",
            cUsLang: "da",
          },
        ],
      },
      {
        code: "EE",
        name: "Estonia",
        displayName: "Estonian",
        languages: [
          {
            isRTL: false,
            code: "et",
            trans: "et-rEE",
            cUsLang: "et",
          },
        ],
      },
      {
        code: "FK",
        name: "Falkland Islands",
        displayName: "Falkland Islands",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_us",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
        ],
      },
      {
        code: "FO",
        name: "Faroe Islands",
        displayName: "Faroe Islands",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "FI",
        name: "Finland",
        displayName: "Suomi",
        languages: [
          {
            isRTL: false,
            code: "fi",
            trans: "fi",
            cUsLang: "fi",
          },
        ],
      },
      {
        code: "FR",
        name: "France",
        displayName: "France",
        languages: [
          {
            isRTL: false,
            code: "fr",
            trans: "fr-rFR",
            cUsLang: "fr_fr",
          },
        ],
      },
      {
        code: "MK",
        name: "FYROM",
        displayName: "Северна Македонија",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rMK",
            cUsLang: "en_gb",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
          {
            isRTL: false,
            code: "mk",
            trans: "mk-rMK",
            cUsLang: "mk",
          },
        ],
      },
      {
        code: "DE",
        name: "Germany",
        displayName: "Deutschland",
        languages: [
          {
            isRTL: false,
            code: "de",
            trans: "de",
            cUsLang: "de",
          },
        ],
      },
      {
        code: "GI",
        name: "Gibraltar",
        displayName: "Gibraltar",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "GR",
        name: "Greece",
        displayName: "Ελλάδα",
        languages: [
          {
            isRTL: false,
            code: "el",
            trans: "el",
            cUsLang: "el",
          },
        ],
      },
      {
        code: "GL",
        name: "Greenland",
        displayName: "Greenland",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "GP",
        name: "Guadeloupe",
        displayName: "Guadeloupe",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "VA",
        name: "Holy See (Vatican City State)",
        displayName: "Holy See (Vatican City State) ",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "HU",
        name: "Hungary",
        displayName: "Magyarország",
        languages: [
          {
            isRTL: false,
            code: "hu",
            trans: "hu",
            cUsLang: "hu",
          },
        ],
      },
      {
        code: "IS",
        name: "Iceland",
        displayName: "Iceland",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "IE",
        name: "Ireland",
        displayName: "Ireland",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
          },
          {
            isRTL: false,
            code: "gd",
            trans: "ga",
            cUsLang: "gd",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
        ],
      },
      {
        code: "IT",
        name: "Italy",
        displayName: "Italia",
        languages: [
          {
            isRTL: false,
            code: "it",
            trans: "it-rIT",
            cUsLang: "it",
          },
        ],
      },
      {
        code: "XK",
        name: "Kosovo",
        displayName: "Kosovo",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
        ],
      },
      {
        code: "LV",
        name: "Latvia",
        displayName: "Latvija",
        languages: [
          {
            isRTL: false,
            code: "lv",
            trans: "lv",
            cUsLang: "lv",
          },
        ],
      },
      {
        code: "LI",
        name: "Liechtenstein",
        displayName: "Liechtenstein",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "LT",
        name: "Lithuania",
        displayName: "Lietuva",
        languages: [
          {
            isRTL: false,
            code: "lt",
            trans: "lt",
            cUsLang: "lt",
          },
        ],
      },
      {
        code: "LU",
        name: "Luxembourg",
        displayName: "Luxembourg",
        languages: [
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
        ],
      },
      {
        code: "MT",
        name: "Malta",
        displayName: "Malta",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "MD",
        name: "Moldova",
        displayName: "Moldova",
        languages: [
          {
            isRTL: false,
            code: "ro",
            trans: "ro",
            cUsLang: "ro",
            languageView: LANGUAGE_VIEW.SITE_LANGUAGE,
          },
          {
            isRTL: false,
            code: "ru",
            trans: "ru",
            cUsLang: "ru",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
        ],
      },
      {
        code: "MC",
        name: "Monaco",
        displayName: "Monaco",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "ME",
        name: "Montenegro",
        displayName: "Montenegro",
        languages: [
          {
            isRTL: false,
            code: "sr",
            trans: "sr",
            cUsLang: "sr",
          },
        ],
      },
      {
        code: "NL",
        name: "Netherlands",
        displayName: "Nederland",
        languages: [
          {
            isRTL: false,
            code: "nl",
            trans: "nl",
            cUsLang: "nl",
          },
        ],
      },
      {
        code: "NO",
        name: "Norway",
        displayName: "Norge",
        languages: [
          {
            isRTL: false,
            code: "no",
            trans: "nb",
            cUsLang: "no",
          },
        ],
      },
      {
        code: "PL",
        name: "Poland",
        displayName: "Polska",
        languages: [
          {
            isRTL: false,
            code: "pl",
            trans: "pl",
            cUsLang: "pl",
          },
        ],
      },
      {
        code: "PT",
        name: "Portugal",
        displayName: "Portugal",
        languages: [
          {
            isRTL: false,
            code: "pt",
            trans: "pt-rPT",
            cUsLang: "pt_pt",
          },
        ],
      },
      {
        code: "RO",
        name: "Romania",
        displayName: "Romania",
        languages: [
          {
            isRTL: false,
            code: "ro",
            trans: "ro",
            cUsLang: "ro",
          },
        ],
      },
      {
        code: "RU",
        name: "Russia",
        displayName: "Россия",
        languages: [
          {
            isRTL: false,
            code: "ru_RU",
            trans: "ru",
            cUsLang: "ru",
          },
        ],
      },
      {
        code: "PM",
        name: "Saint Pierre and Miquelon",
        displayName: "Saint Pierre And Miquelon",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
        ],
      },
      {
        code: "SM",
        name: "San Marino",
        displayName: "San Marino",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "RS",
        name: "Serbia",
        displayName: "Cрбија",
        languages: [
          {
            isRTL: false,
            code: "sr",
            trans: "sr",
            cUsLang: "sr",
          },
        ],
      },
      {
        code: "SK",
        name: "Slovakia",
        displayName: "Slovensko",
        languages: [
          {
            isRTL: false,
            code: "sk",
            trans: "sk",
            cUsLang: "sk",
          },
        ],
      },
      {
        code: "SI",
        name: "Slovenia",
        displayName: "Slovenija",
        languages: [
          {
            isRTL: false,
            code: "sl",
            trans: "sl",
            cUsLang: "sl",
          },
        ],
      },
      {
        code: "ES",
        name: "Spain",
        displayName: "Espana",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rES",
            cUsLang: "es_es",
          },
        ],
      },
      {
        code: "SE",
        name: "Sweden",
        displayName: "Sverige",
        languages: [
          {
            isRTL: false,
            code: "sv",
            trans: "sv",
            cUsLang: "sv",
          },
        ],
      },
      {
        code: "CH",
        name: "Switzerland",
        displayName: "Schweiz",
        languages: [
          {
            isRTL: false,
            code: "de",
            trans: "de",
            cUsLang: "de",
          },
          {
            isRTL: false,
            code: "fr",
            trans: "fr",
            cUsLang: "fr_fr",
          },
          {
            isRTL: false,
            code: "it",
            trans: "it",
            cUsLang: "it",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
        ],
      },
      {
        code: "TR",
        name: "Türkiye",
        displayName: "Türkiye",
        languages: [
          {
            isRTL: false,
            code: "tr",
            trans: "tr",
            cUsLang: "tr",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "UA",
        name: "Ukraina",
        displayName: "Україна",
        languages: [
          {
            isRTL: false,
            code: "uk",
            trans: "uk",
            cUsLang: "uk",
          },
        ],
      },
      {
        code: "GB",
        name: "United Kingdom",
        displayName: "UK",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rGB",
            cUsLang: "en_gb",
          },
        ],
      },
    ],
  },
  {
    name: "Latin America",
    countries: [
      {
        code: "AI",
        name: "Anguilla",
        displayName: "Anguilla",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "AG",
        name: "Antigua and Barbuda",
        displayName: "Antigua and Barbuda",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "AR",
        name: "Argentina",
        displayName: "Argentina",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "AW",
        name: "Aruba",
        displayName: "Aruba",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "BS",
        name: "Bahamas",
        displayName: "Bahamas & Caribbean Islands",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "BB",
        name: "Barbados",
        displayName: "Barbados",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "BZ",
        name: "Belize",
        displayName: "Belize",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "BM",
        name: "Bermuda",
        displayName: "Bermuda",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "BO",
        name: "Bolivia",
        displayName: "Bolivia",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "BR",
        name: "Brazil",
        displayName: "Brasil",
        languages: [
          {
            isRTL: false,
            code: "pt_BR",
            trans: "pt-rBR",
            cUsLang: "pt_latn",
          },
        ],
      },
      {
        code: "VG",
        name: "British Virgin Islands",
        displayName: "British Virgin Islands",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "KY",
        name: "Cayman Islands",
        displayName: "Cayman Islands",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "CL",
        name: "Chile",
        displayName: "Chile",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "CO",
        name: "Colombia",
        displayName: "Colombia",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "CR",
        name: "Costa Rica",
        displayName: "Costa Rica",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "CU",
        name: "Cuba",
        displayName: "Cuba",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "DM",
        name: "Dominica",
        displayName: "Dominica",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "EC",
        name: "Ecuador",
        displayName: "Ecuador",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "SV",
        name: "El Salvador",
        displayName: "El Salvador",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "GF",
        name: "French Guiana",
        displayName: "French Guiana",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "GD",
        name: "Grenada",
        displayName: "Grenada",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "GT",
        name: "Guatemala",
        displayName: "Guatemala",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "GY",
        name: "Guyana",
        displayName: "Guyana",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "HT",
        name: "Haiti",
        displayName: "Haiti",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "HN",
        name: "Honduras",
        displayName: "Honduras",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "JM",
        name: "Jamaica",
        displayName: "Jamaica",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "MX",
        name: "Mexico",
        displayName: "México",
        languages: [
          {
            isRTL: false,
            code: "es_MX",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "MS",
        name: "Montserrat",
        displayName: "Montserrat",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "AN",
        name: "Netherlands Antilles",
        displayName: "Netherlands Antilles",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "NI",
        name: "Nicaragua",
        displayName: "Nicaragua",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "PA",
        name: "Panama",
        displayName: "Panamá",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "PY",
        name: "Paraguay",
        displayName: "Paraguay",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "PE",
        name: "Peru",
        displayName: "Perú",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "PR",
        name: "Puerto Rico",
        displayName: "Puerto Rico",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "DO",
        name: "Dominican Republic",
        displayName: "República Dominicana",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "KN",
        name: "Saint Kitts and Nevis",
        displayName: "Saint Kitts and Nevis",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "LC",
        name: "Saint Lucia",
        displayName: "Saint Lucia",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "VC",
        name: "Saint Vincent and The Grenadines",
        displayName: "Saint Vincent And The Grenadines",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "SR",
        name: "Suriname",
        displayName: "Suriname",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "TT",
        name: "Trinidad and Tobago",
        displayName: "Trinidad And Tobago",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "TC",
        name: "Turks and Caicos Islands",
        displayName: "Turks and Caicos Islands",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
        ],
      },
      {
        code: "UY",
        name: "Uruguay",
        displayName: "Uruguay",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
      {
        code: "VE",
        name: "Venezuela",
        displayName: "Venezuela",
        languages: [
          {
            isRTL: false,
            code: "es",
            trans: "es-rUS",
            cUsLang: "es_latn",
          },
        ],
      },
    ],
  },
  {
    name: "Middle East",
    countries: [
      {
        code: "AF",
        name: "Afghanistan",
        displayName: "Afghanistan",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "BH",
        name: "Bahrain",
        displayName: "البحرين",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
        ],
      },
      {
        code: "IR",
        name: "Iran",
        displayName: "ایران",
        languages: [
          {
            isRTL: true,
            code: "fa",
            trans: "fa",
            cUsLang: "fa",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "IQ",
        name: "Iraq",
        displayName: "العراق",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
        ],
      },
      {
        code: "IL",
        name: "Israel",
        displayName: "ישראל",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
          {
            isRTL: true,
            code: "he",
            trans: "iw",
            cUsLang: "iw",
          },
        ],
      },
      {
        code: "JO",
        name: "Jordan",
        displayName: "الأردن",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "KW",
        name: "Kuwait",
        displayName: "الكويت",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "LB",
        name: "Lebanon",
        displayName: "لبنان",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "OM",
        name: "Oman",
        displayName: "عُمان",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "PK",
        name: "Pakistan",
        displayName: "Pakistan",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
          {
            isRTL: true,
            code: "ur",
            trans: "ur-rPK",
            cUsLang: "ur",
          },
        ],
      },
      {
        code: "QA",
        name: "Qatar",
        displayName: "قطر",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "SA",
        name: "Saudi Arabia",
        displayName: "المملكة العربية السعودية",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
          },
        ],
      },
      {
        code: "SY",
        name: "Syrian Arab Republic",
        displayName: "Syrian Arab Republic",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
        ],
      },
      {
        code: "TM",
        name: "Turkmenistan",
        displayName: "Turkmenistan",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "AE",
        name: "United Arab Emirates",
        displayName: "الإمارات العربية المتحدة",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_gb",
          },
        ],
      },
      {
        code: "YE",
        name: "Yemen",
        displayName: "اليمن",
        languages: [
          {
            isRTL: true,
            code: "ar",
            trans: "ar",
            cUsLang: "ar_ae",
          },
        ],
      },
    ],
  },
  {
    name: "North America",
    countries: [
      {
        code: "CA",
        name: "Canada",
        displayName: "Canada",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
          },
          {
            isRTL: false,
            code: "fr_CA",
            trans: "fr-rCA",
            cUsLang: "fr_ca",
          },
        ],
      },
      {
        code: "GU",
        name: "Guam",
        displayName: "Guam",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en",
            cUsLang: "en_us",
            languageView: LANGUAGE_VIEW.PP_LANGUAGE,
          },
        ],
      },
      {
        code: "US",
        name: "USA",
        displayName: "USA",
        languages: [
          {
            isRTL: false,
            code: "en",
            trans: "en-rUS",
            cUsLang: "en_us",
          },
        ],
      },
    ],
  },
];
