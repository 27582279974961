import reactGA from "react-ga4";
import { ALLOW_PERFORMANCE_COOKIES } from "../constants/storage";

// import {
//   EVENT_CATEGORY_FILE_DOWNLOAD,
//   EVENT_CATEGORY_SEND_FILE_PASSWORD,
//   EVENT_CATEGORY_VIEW_FILE_PASSWORD,
//   EVENT_ACTION_SERVICES
// } from '../constants/analytics';
// import { ALLOW_GOOGLE_COOKIES } from '../constants/storage';

import initializeGoogleAnalytics from "./UtilsGA";

const triggerGAEvent = (categoryName: string, actionName: string) => {
  const isInitialized = initializeGoogleAnalytics();
  const isGoogleCookiesAllowed =
    localStorage.getItem(ALLOW_PERFORMANCE_COOKIES) === "true";

  if (isInitialized && isGoogleCookiesAllowed) {
    // const ga = reactGA.ga();
    if (categoryName && actionName) {
      // ga("send", "event", {
      //   eventCategory: categoryName,
      //   eventAction: actionName,
      // });
      reactGA.event({
        category: categoryName,
        action: actionName,
      });
    }
  }
};

// export const downloadFileGAEvent = ({ serviceCode = '' } = {}) => {
//   triggerGAEvent(EVENT_CATEGORY_FILE_DOWNLOAD, EVENT_ACTION_SERVICES[serviceCode]);
// };

// export const filePasswordGAEvent = ({ servicesWithFile = [], actionType = 'send' } = {}) => {
//   servicesWithFile.forEach(serviceCode => {
//     if (actionType === 'send') {
//       triggerGAEvent(EVENT_CATEGORY_SEND_FILE_PASSWORD, EVENT_ACTION_SERVICES[serviceCode]);
//     } else {
//       triggerGAEvent(EVENT_CATEGORY_VIEW_FILE_PASSWORD, EVENT_ACTION_SERVICES[serviceCode]);
//     }
//   });
// };

export default triggerGAEvent;
