import React from "react";
import classNames from "classnames";
import { RequestStatus } from "../../../../types/mydata";

interface StepsProps {
  status: RequestStatus;
}

export const Steps: React.FC<StepsProps> = (props) => {
  const { status } = props;
  const allowedStatuses = [
    RequestStatus.COMPLETED,
    RequestStatus.PROCESSING,
    RequestStatus.REQUESTING,
  ];

  return (
    <>
      {allowedStatuses.includes(status) && (
        <div className="request-details__steps">
          <span
            className={classNames("request-details__steps__step", {
              active: status === RequestStatus.REQUESTING,
              prev: status !== RequestStatus.REQUESTING,
            })}
          >
            {status === RequestStatus.REQUESTING ? 1 : ""}
          </span>
          <span
            className={classNames("request-details__steps__step", {
              active: status === RequestStatus.PROCESSING,
              prev: status === RequestStatus.COMPLETED,
            })}
          >
            {status === RequestStatus.PROCESSING ? 2 : ""}
          </span>
          <span
            className={classNames("request-details__steps__step", {
              active: status === RequestStatus.COMPLETED,
            })}
          >
            {status === RequestStatus.COMPLETED ? 3 : ""}
          </span>
        </div>
      )}
    </>
  );
};
