import React, { HTMLInputTypeAttribute, useEffect, useState } from "react";

import "./InputField.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import { ApplicationState } from "../../../store";

interface Props {
  id?: string;
  testId?: string;
  label: string;
  errorMsg?: string;
  className?: string;
  isInvalid?: boolean;
  value?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<Props> = (props) => {
  const { isHighContrast } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const [focusElement, setFocusElement] = useState<boolean>(false);
  const {
    label,
    onChange,
    testId,
    type = "text",
    value,
    placeholder = `Your ${label?.toLocaleLowerCase()}`,
    isInvalid = false,
    errorMsg = `Invalid ${label}`,
    className,
  } = props;

  const wrapClass = classNames(className);

  useEffect(() => {
    const input = document.getElementById(`input-field${label}`);
    if (input) {
      if (isInvalid && !focusElement) {
        input.classList.add("invalid");
      } else {
        input.classList.remove("invalid");
      }
    }
  }, [value, focusElement]);

  return (
    <div className="input-group-holder">
      <label
        className={`margin-zero login-label ${isHighContrast ? "hc" : ""}`}
      >
        {label}
      </label>
      <input
        data-testid={testId}
        className={wrapClass}
        id={`input-field${label}`}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={() => setFocusElement(true)}
        onBlur={() => setFocusElement(false)}
      ></input>
      {isInvalid && !focusElement ? (
        <p className="error-text margin-zero">{errorMsg}</p>
      ) : null}
    </div>
  );
};

export default InputField;
