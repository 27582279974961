import { CountriesWithLocalPrivacy } from "../data/landing-page";
import { SustainabilityLinks } from "../data/sustainability-links";

export const UtilsHome = {
  getSustainabilityLink: (countryCode: string, trans?: string): string => {
    const country = SustainabilityLinks.filter((sl) => {
      return sl.countryCode === countryCode;
    });

    let link = "";

    if (country.length > 0) {
      const links = country[0].links;
      link = links[0].link;

      if (links.length > 1 && !!trans) {
        links.forEach((l) => {
          if (`${l.trans}_${countryCode}` === trans || l.trans === trans) {
            link = l.link;
          }
        });
      }
    }

    return link;
  },
  hasLocalSite: (countryCode: string) => {
    return CountriesWithLocalPrivacy.some(
      (country) => country.code === countryCode,
    );
  },
};
