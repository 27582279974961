import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Svg, SVG_ICONS } from "../Svg/Svg";
import { Typography } from "../Typography/Typography";

import "./Accordion.scss";

interface AccordionSubComponents {
  Item: React.FC<AccordionItemProps>;
}

interface Props {
  children?: React.ReactNode;
}

export const Accordion: React.FC<Props> & AccordionSubComponents = (props) => {
  const { children } = props;
  return <div className="accordion">{children}</div>;
};

interface AccordionItemProps {
  id: string | number;
  title: string;
  children?: React.ReactNode;
  activeItem?: string;
  onClick?: (isOpen: boolean) => void;
  testId?: string;
  className?: string;
}
const Item: React.FC<AccordionItemProps> = (props) => {
  const { title, children, onClick, activeItem, id, testId } = props;
  const [isActive, setIsActive] = useState<boolean>(title === activeItem);
  const wrapClass = classNames("accordion-item", {
    active: title === activeItem,
  });

  useEffect(
    () => {
      setIsActive(title === activeItem);
    },
    // eslint-disable-next-line
    [activeItem]
  );

  return (
    <div className={wrapClass} id={String(id)}>
      <button
        data-testid={testId}
        className="accordion-item__title"
        onClick={() => {
          setIsActive(!isActive);
          if (onClick) {
            onClick(!isActive);
          }
        }}
      >
        <Typography
          variant="body2"
          weight="wBold"
          className="accordion-item__title__text"
        >
          {title}
        </Typography>
        <Svg icon={SVG_ICONS.CHEVRON_MORE} width={14} height={14} />
      </button>
      <div className="accordion-item__content">{children}</div>
    </div>
  );
};

Accordion.Item = Item;

export default Accordion;
