import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CHARACTER_WITH_DASH, EMAIL_REGEX } from "../../constants/validation";
import { useLoginOptionHooks } from "../../hooks/login-option-hooks";
import { ApplicationState } from "../../store";
import { LoginOptionState } from "../../store/reducers/login-option-reducer";
import { Validate } from "../../utils/Validation";
import InputField from "../__common/InputField/InputField";
import Modal from "../__common/Modal/Modal";
import { Button } from "../__common/_controls";
import "./EmailLogin.scss";
import { LoadingButton } from "../__common/_controls/Button/Button";

interface Props {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

export const EmailLoginModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  // third party hooks
  const { t } = useTranslation();

  // custom hooks
  const {
    handleEmailSignin,
    removeEmailLoginSession,
    removeGstToken,
    isVerifying,
    duration,
  } = useLoginOptionHooks();

  // states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailChecking, setIsEmailChecking] = useState(false);
  const { banDuration } = useSelector<ApplicationState, LoginOptionState>(
    (state) => state.loginOption
  );

  // variables
  const emailRegex = new RegExp(EMAIL_REGEX);
  const stringWithDashAndAccentedCharacter = new RegExp(CHARACTER_WITH_DASH);

  useEffect(() => {
    const checker = setTimeout(() => {
      setIsEmailChecking(true);
      const result = Validate(emailRegex, email);
      setIsEmailValid(result);
    }, 500);

    return () => {
      clearTimeout(checker);
      setIsEmailChecking(false);
    };
  }, [email]);

  const isAllFieldDone =
    !isVerifying &&
    isEmailValid &&
    isEmailChecking &&
    Validate(stringWithDashAndAccentedCharacter, firstName) &&
    Validate(stringWithDashAndAccentedCharacter, lastName);

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
  };

  const handleClose = () => {
    setIsOpen(false);
    removeEmailLoginSession();
    removeGstToken();
  };

  useEffect(() => {
    if (!isOpen) {
      clearFields();
    }
  }, [isOpen]);

  return (
    <Modal
      wrapperClassName="otp-modal"
      className="otp-modal-wrapper auto-height"
      show={isOpen}
      id="otp-modal"
      testId="login-modal"
    >
      <Modal.Header
        onHide={() => {
          !isVerifying && handleClose();
        }}
      >
        <Modal.Title iconVisibility={false}>
          {t("PRIVACY_LOGIN_EMAIL_FORM_TITLE")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {banDuration !== "" && (
          <span className="text-danger text-center">
            {t("PRIVACY_LOGIN_EMAIL_OTP_LOCKED_TEXT")}
            <br />
            {t("PRIVACY_LOGIN_EMAIL_OTP_LOCKED_TEXT2", {
              "%s": `${duration.value} ${t(duration.did)}`,
            })}
          </span>
        )}
        <div className="input-holder" id="input-holder">
          <InputField
            testId="firstName"
            label={t("PRIVACY_LOGIN_EMAIL_FORM_LABEL_FNAME")}
            value={firstName}
            placeholder={t("PRIVACY_LOGIN_EMAIL_FORM_PLACEHOLDER_FNAME")}
            errorMsg={t("PRIVACY_LOGIN_EMAIL_FORM_ERROR_FNAME")}
            onChange={(e) => setFirstName(e.target.value)}
            isInvalid={
              !Validate(stringWithDashAndAccentedCharacter, firstName) &&
              firstName !== ""
            }
          />
          <InputField
            testId="lastName"
            label={t("PRIVACY_LOGIN_EMAIL_FORM_LABEL_LNAME")}
            value={lastName}
            isInvalid={
              !Validate(stringWithDashAndAccentedCharacter, lastName) &&
              lastName !== ""
            }
            placeholder={t("PRIVACY_LOGIN_EMAIL_FORM_PLACEHOLDER_LNAME")}
            errorMsg={t("PRIVACY_LOGIN_EMAIL_FORM_ERROR_LNAME")}
            onChange={(e) => setLastName(e.target.value)}
          />

          <InputField
            testId="email"
            className="email-input"
            label={t("PRIVACY_LOGIN_EMAIL_FORM_LABEL_EMAIL")}
            placeholder={t("PRIVACY_LOGIN_EMAIL_FORM_PLACEHOLDER_EMAIL")}
            value={email}
            isInvalid={!isEmailValid && email !== ""}
            errorMsg={t("PRIVACY_LOGIN_EMAIL_FORM_ERROR")}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-container foccusable-div">
          <Button
            type="secondary"
            title={t("PRIVACY_LOGIN_EMAIL_FORM_BTN_CANCEL")}
            onClick={() => handleClose()}
            disabled={isVerifying}
            id="cancel-request-button"
            date-testid="cancel-request-button"
          />
        </div>
        <div className="button-container foccusable-div">
          <LoadingButton
            title={t("PRIVACY_LOGIN_EMAIL_FORM_BTN_LOGIN")}
            onClick={() =>
              handleEmailSignin(
                firstName.trim(),
                lastName.trim(),
                email,
                "send"
              )
            }
            disabled={!isAllFieldDone}
            isLoading={isVerifying}
            loadingPosition="right"
            id="confirm-request-button"
            testId="confirm-request-button"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
