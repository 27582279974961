import React from "react";

import { ErrorDetail } from "../../../components/ErrorDetail/ErrorDetail";

export const Error503: React.FC = () => {
  return (
    <>
      <ErrorDetail variant="error503" />
    </>
  );
};
