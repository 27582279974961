import { useState } from "react";

export const useLanguage = () => {
  const [isLanguageModalShow, setIsLanguageModalShow] =
    useState<boolean>(false);

  return {
    isLanguageModalShow,
    setIsLanguageModalShow,
  };
};
