import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Container } from "../Grid/Grid";
import { Svg, SVG_ICONS } from "../__common/Svg/Svg";
import { Typography } from "../__common/Typography/Typography";
import "./HelpTipsPopup.scss";
import { useCookie } from "../../hooks/cookie-hooks";
import { ROUTES } from "../../configs/routes";
import { INVALID_PAGE } from "../../constants/storage";

// removing onHide props since upon checking the usage of HelpTipsPopup both on Layout.tsx and MyData.tsx it doesn't have an onHide value
interface Props {}

export const HelpTipsPoupup: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isShowNotice, setShowNotice, setCookieShowNotice } = useCookie();

  const handleOnHideClick = () => {
    setShowNotice(false);
    setCookieShowNotice();
  };

  const isPageValid = sessionStorage.getItem(INVALID_PAGE) === "true";

  return isShowNotice && !isPageValid ? (
    <Container>
      <div
        data-testid="help-tips-popup"
        className={classNames("helptips__container", {
          home: pathname === ROUTES.ROOT,
        })}
      >
        <Typography
          variant="body1"
          component="span"
          className="helptips__container__info"
          weight="w700"
        >
          {t("PRIVACY_HELPTIPS_TEXT")}
        </Typography>
        <Typography
          testId="help-tips-on-hide-button"
          variant="body2"
          component="span"
          className="helptips__container__ok"
          weight="w700"
          onClick={handleOnHideClick}
        >
          <Link to="#">{t("PRIVACY_HELPTIPS_BTN")}</Link>
        </Typography>

        <button
          className="helptips__container__close"
          onClick={handleOnHideClick}
        >
          <Svg
            icon={SVG_ICONS.DRAWER_CLOSE}
            width={16}
            height={16}
            onClick={handleOnHideClick}
          />
        </button>
      </div>
    </Container>
  ) : (
    <></>
  );
};
