import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

export const useI18Next = () => {
  const initTranslation = (lng?: string) =>
    i18next
      .use(initReactI18next)
      .use(HttpApi)
      .init({
        compatibilityJSON: "v3",
        lng,
        load: "currentOnly",
        fallbackLng: false,
        backend: {
          loadPath: "/assets/locales/{{lng}}.json",
        },
        react: { useSuspense: true },
      });

  return { i18next, initTranslation };
};
