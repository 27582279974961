import React from "react";
import classNames from "classnames";
import "./Grid.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

interface ColProps {
  children?: React.ReactNode;
  className?: string;
  sm?: string | number;
  md?: string | number;
  lg?: string | number;
  xl?: string | number;
}

export const Container: React.FC<Props> = (props) => {
  const wrapClass = classNames("container", props.className);

  return <div className={wrapClass}>{props.children}</div>;
};

export const Row: React.FC<Props> = (props) => {
  const wrapClass = classNames("row", props.className);
  return <div className={wrapClass}>{props.children}</div>;
};

export const Col: React.FC<ColProps> = (props) => {
  const { className, children, sm, md, lg, xl } = props;

  const wrapClass = classNames("col", className, {
    [`col-sm-${sm}`]: !!sm,
    [`col-md-${md}`]: !!md,
    [`col-${lg}`]: !!lg,
    [`col-xl-${xl}`]: !!xl,
  });

  return <div className={wrapClass}>{children}</div>;
};
