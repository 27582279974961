import { SITE_SETTINGS_TYPE } from "../actions-types";

export const actionSetSysProps = (data: {
  GEO_IP: string;
  SA_COUNTRY: string;
  SA_AGE: string;
  SITE_COUNTRY: string;
  SITE_LANGUAGE: string;
}) => ({
  type: SITE_SETTINGS_TYPE.SET_USER_SYSTEM_PROPERTIES,
  data,
});

export const actionSetSysPropsLoading = (data: boolean) => ({
  type: SITE_SETTINGS_TYPE.SET_LOADING,
  data,
});
