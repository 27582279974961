import classNames from "classnames";
import React, { useEffect, useState } from "react";
import "./TTSButton.scss";
import { Svg, SVG_ICONS } from "../../Svg/Svg";

import { useSpeechSynthesis } from "react-speech-kit";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { LayoutState } from "../../../../store/reducers/layout-reducer";
import { TTS_VOICES_MAP } from "../../../../constants/locale";

interface Props {
  value?: string;
  className?: string;
  id?: string;
  testId?: string;
}

export const TTSButton: React.FC<Props> = (props) => {
  const { value, className = "", id, testId } = props;
  const wrapClass = classNames("tts-button", { [className]: !!className });
  const [voice, setVoice] = useState({});
  const [displayIcon, setDisplayIcon] = useState(false);
  const pitch: number = 1.5;
  const rate: number = 1;

  const { speak, cancel, speaking, supported } = useSpeechSynthesis();

  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { languageTrans, ttsVoices } = layoutState;

  useEffect(() => {
    if (ttsVoices.length > 0) {
      const language = languageTrans.split("-")[0];
      var voiceFound = ttsVoices.filter(
        (voiceSynth: any) => voiceSynth.name === TTS_VOICES_MAP[languageTrans]
      )[0];
      if (!voiceFound) {
        voiceFound = ttsVoices.filter(
          (voiceSynth: any) => voiceSynth.lang.substring(0, 2) === language
        )[0];
      }
      if (!voiceFound) {
        setDisplayIcon(false);
      } else {
        setDisplayIcon(true);
        setVoice(voiceFound);
      }
    } else {
      setDisplayIcon(false);
    }
  }, [languageTrans, ttsVoices]);

  const handleClick = () => {
    if (!speaking && ttsVoices.length > 0) {
      speak({ text: value, voice, rate, pitch });
    } else {
      cancel();
    }
  };

  return (
    <>
      <button
        onClick={() => handleClick()}
        id={id}
        className="tts-button-container"
        data-testid={testId}
      >
        {displayIcon &&
          supported &&
          (!speaking ? (
            <Svg
              icon={SVG_ICONS.TTS_PLAY}
              className={wrapClass}
              // onClick={() => handleClick()}
            />
          ) : (
            <Svg
              icon={SVG_ICONS.TTS_STOP}
              className={wrapClass}
              // onClick={() => handleClick()}
            />
          ))}
      </button>
    </>
  );
};
