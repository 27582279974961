import React from "react";
import "./Image.scss";

interface Props {
  image?: string;
  name?: string;
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  testId?: string;
}

export const Image: React.FC<Props> = (props) => {
  const {
    image = "",
    name = "",
    width = 48,
    height = 48,
    className,
    onClick,
    testId,
  } = props;

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <img
      className={className}
      src={image}
      alt={name}
      width={width}
      height={height}
      onClick={handleOnClick}
      data-testid={testId}
    />
  );
};
