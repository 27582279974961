export const URLS = {
  ROOT: "/",
  HOME: "/home",
  LOGIN: "/login",
  BUTTONS: "/buttons",
  TYPOGRAPHY: "/typography",
  FORMS: "/forms",
  MODALS: "/modals",
  ABOUT_OVERVIEW: "/about/overview",
  ABOUT_GRPS: "/gps",
  PRIVACY_POLICY: "/privacy/samsung",
  POLICY_OVERVIEW: "/policy/overview",
  COOKIE_POLICY: "/cookie/policy",
  MYDATA: "/my-data",
  MYDATA_HISTORY: "/my-data/history",
  ERROR_400: "/error/400",
  ERROR_403: "/error/403",
  ERROR_404: "/error/404",
  ERROR_500: "/error/500",
  ERROR_502: "/error/502",
  ERROR_503: "/error/503",
  ERROR_IE: "/error/ie",
  ERROR_MAINTENANCE: "/error/maintenance",
  OLD_MYDATA: "/mydata",
  OLD_REQUEST_DETAILS: "/details",
  OLD_DOWNLOAD_HISTORY: "/downloads",
  OLD_DELETE_HISTORY: "/delete",
  OLD_OPTOUT_HISTORY: "/preferences",
  OLD_REQUEST_DOWNLOAD: "/downloads/request",
  OLD_REQUEST_DELETE: "/delete/request",
  OLD_REQUEST_OPTOUT: "/preferences/opt-out",
  OLD_ABOUT_GRPS: "/about/grps",
  PARAMS: {
    REQUEST_NUMBER: ":requestNumber",
  },
  CHINA_LOCALSITE: "https://privacy.samsung.com.cn/", // TODO: put in a separate variable
  OLD_CONSIGNMENT_WITH_KR: "/kr/bdp-consignment",
  OLD_CONSIGNMENT: "/bdp-consignment",
  BDP_CONSIGNMENT: "/entrusted/bdp",
  ANALYTICS_CONSIGNMENT: "/entrusted/sanalytics",
  DIAGMON_CONSIGNMENT: "/entrusted/diagmon",
  //ADSSO
  ADSSO_LOGOUT: "/adsso-logout",

  //DIGITAL LEGACY
  DIGITAL_LEGACY_AUTH: "/auth/digital-legacy",
  QUERY_PARAMS: {
    LOCALE: "locale",
    COUNTRY_CODE: "countryCode"
  }
};
