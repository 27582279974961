export const UtilsKeyboardAccessibility = {
  setFocus: (id: string, options?: any) => {
    document.getElementById(id)?.focus({ ...options });
  },
  addTabEventListener: (onTabOf: string, shouldFocusOn: string) => {
    document.getElementById(onTabOf)?.addEventListener("keydown", (e) => {
      if (e.keyCode === 9 && !e.shiftKey) {
        document.getElementById(shouldFocusOn)?.focus();
      }
    });
  },
  removeTabEventListener: (onTabOf: string, shouldFocusOn: string) => {
    document.getElementById(onTabOf)?.removeEventListener("keydown", (e) => {
      if (e.keyCode === 9 && !e.shiftKey) {
        document.getElementById(shouldFocusOn)?.focus();
      }
    });
  },
};
