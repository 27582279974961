import React from "react";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

import "./Svg.scss";

import ArrowTopRight from "../../../assets/images/svg/icon_arrow_top_right.svg";
import ChevronRight from "../../../assets/images/svg/icon_chevron_right.svg";
import ChevronHubLeft from "../../../assets/images/svg/icon_chevron_hub_left.svg";
import ChevronHubRight from "../../../assets/images/svg/icon_chevron_hub_right.svg";
import ChevronHubLeftDisabled from "../../../assets/images/svg/ic_chevron_left_disabled.svg";
import ChevronHubRightDisabled from "../../../assets/images/svg/ic_chevron_right_disabled.svg";
import ChevronHubLeftMobile from "../../../assets/images/svg/icon_chevron_hub_left_mobile.svg";
import ChevronHubRightMobileDisabled from "../../../assets/images/svg/icon_chevron_hub_right_mobile_disabled.svg";
import ChevronHubLeftMobileDisabled from "../../../assets/images/svg/icon_chevron_hub_left_mobile_disabled.svg";
import ChevronHubRightMobile from "../../../assets/images/svg/icon_chevron_hub_right_mobile.svg";
import TrashLrg from "../../../assets/images/svg/icon_trash_lrg.svg";
import TrashSml from "../../../assets/images/svg/icon_trash_sml.svg";
import GreaterThanLrg from "../../../assets/images/svg/icon_greater_than_lrg.svg";
import GreaterThanSml from "../../../assets/images/svg/icon_greater_than_sml.svg";
import Logo from "../../../assets/images/svg/logo.svg";
import DefaultTheme from "../../../assets/images/svg/btn_default_template.svg";
import HighContrastTheme from "../../../assets/images/svg/btn_highcontrast_template.svg";
import ArrowRight from "../../../assets/images/svg/icon_arrow_right.svg";
import ArrowRightMobile from "../../../assets/images/svg/icon_arrow_right_mobile.svg";
import FooterLogo from "../../../assets/images/svg/footer_logo.svg";
import Profile from "../../../assets/images/svg/icon_profile.svg";
import ProfileSignOut from "../../../assets/images/svg/icon_sign_out_profile.svg";
import ProfileSignOutCircle from "../../../assets/images/svg/icon_sign_out_profile2.svg";
import MenuAboutDetailIcon from "../../../assets/images/svg/about_detail_icon.svg";
import ArrowUp from "../../../assets/images/svg/icon_arrow_up.svg";
import BurgerMenu from "../../../assets/images/svg/icon_burger_menu.svg";
import DrawerClose from "../../../assets/images/svg/icon_drawer_close.svg";
import MobileMenuIntro from "../../../assets/images/svg/icon_mobile_menu_intro.svg";
import TooltipOutline from "../../../assets/images/svg/tooltip_outline.svg";
import CloseSmall from "../../../assets/images/svg/icon_close_small.svg";
import MobileMenuPrivacyPolicy from "../../../assets/images/svg/icon_mobile_menu_pp.svg";
import MobileMenuMyData from "../../../assets/images/svg/icon_mobile_menu_my_data.svg";
import IconError400 from "../../../assets/images/svg/icon_error400.svg";
import IconError403 from "../../../assets/images/svg/icon_error403.svg";
import IconError404 from "../../../assets/images/svg/icon_error404.svg";
import IconError500 from "../../../assets/images/svg/icon_error500.svg";
import IconError502 from "../../../assets/images/svg/icon_error502.svg";
import IconError503 from "../../../assets/images/svg/icon_error503.svg";
import IconErrorIE from "../../../assets/images/svg/icon_errorIE.svg";
import IconErrorMaintenance from "../../../assets/images/svg/icon_error_maintenance.svg";
import IconHomeTopBase from "../../../assets/images/svg/icon_home_top_base.svg";
import IconHomeTopBaseMobile from "../../../assets/images/svg/icon_home_top_base_mobile.svg";
import IconHomeTopHand from "../../../assets/images/svg/icon_home_top_hand.svg";
import IconHomeTopLaptop from "../../../assets/images/svg/icon_home_top_laptop.svg";
import IconHomeTopLock from "../../../assets/images/svg/icon_home_top_lock.svg";
import IconHomeTopMagnifyingGlass from "../../../assets/images/svg/icon_home_top_magnifyingglass.svg";
import IconHomeTopPrivacy from "../../../assets/images/svg/icon_home_top_privacy.svg";
import IconHomeTopSetting from "../../../assets/images/svg/icon_home_top_setting.svg";
import SearchFilled from "../../../assets/images/svg/icon_search_filled.svg";
import Guide from "../../../assets/images/svg/icon_guide.svg";
import MyDataChina from "../../../assets/images/svg/icon_mydata_china.svg";
import MyDataInProgress from "../../../assets/images/svg/icon_mydata_inprogress.svg";
import MyDataRequesting from "../../../assets/images/svg/icon_mydata_requesting.svg";
import MyDataCompleted from "../../../assets/images/svg/icon_mydata_completed.svg";
import MyDataDeleteBtn from "../../../assets/images/svg/icon_data_delete_btn.svg";
import MyDataOptOutBtn from "../../../assets/images/svg/icon_data_opt_out_btn.svg";
import MyDataDownloadBtn from "../../../assets/images/svg/icon_data_download_btn.svg";
import MyDataAcceptingPopup from "../../../assets/images/svg/icon_mydata_accepting.svg";
import MyDataInProgressPopup from "../../../assets/images/svg/icon_in_progress.svg";
import MyDataCompletedPopup from "../../../assets/images/svg/icon_completed.svg";
import MyDataCancelPopup from "../../../assets/images/svg/icon_cancelled.svg";
import MyDataExpiredPopup from "../../../assets/images/svg/icon_expired.svg";
import MyDataDeniedPopup from "../../../assets/images/svg/icon_denied.svg";
import CarouselArrow from "../../../assets/images/svg/icon_carousel_arrow.svg";
import HomeMoreInfo from "../../../assets/images/svg/icon_privacy_home_info.svg";
import HomeChoiceBase from "../../../assets/images/svg/icon_privacy_home_choice_base.svg";
import HomeChoiceIcon1 from "../../../assets/images/svg/icon_privacy_home_choice_icon-1.svg";
import HomeChoiceIcon2 from "../../../assets/images/svg/icon_privacy_home_choice_icon-2.svg";
import HomeChoiceIcon3 from "../../../assets/images/svg/icon_privacy_home_choice_icon-3.svg";
import HomeSafetyBase from "../../../assets/images/svg/icon_privacy_home_safety_base.svg";
import HomeSafetyIcon1 from "../../../assets/images/svg/icon_privacy_home_safety_icon-1.svg";
import HomeSafetyIcon2 from "../../../assets/images/svg/icon_privacy_home_safety_icon-2.svg";
import HomeSafetyIcon3 from "../../../assets/images/svg/icon_privacy_home_safety_icon-3.svg";
import HomeTransparencyBase from "../../../assets/images/svg/icon_privacy_home_transparency_base.svg";
import HomeTransparencyIcon1 from "../../../assets/images/svg/icon_privacy_home_transparency_icon-1.svg";
import HomeTransparencyIcon2 from "../../../assets/images/svg/icon_privacy_home_transparency_icon-2.svg";
import HomeTransparencyIcon3 from "../../../assets/images/svg/icon_privacy_home_transparency_icon-3.svg";
import RightsResponse from "../../../assets/images/svg/icon_rights_response_site.svg";
import FunctionGuide from "../../../assets/images/svg/icon_function_guide.svg";
import AboutPrivacy from "../../../assets/images/svg/icon_about_privacy.svg";
import ArrowDown from "../../../assets/images/svg/icon_arrow_down.svg";
import GuideDownload from "../../../assets/images/svg/icon_guide_download.svg";
import GuideStep from "../../../assets/images/svg/icon_guide_step.svg";
import GuidePassword from "../../../assets/images/svg/icon_guide_password.svg";
import GuideExpires from "../../../assets/images/svg/icon_guide_expires.svg";
import GuideNotice from "../../../assets/images/svg/icon_guide_notice.svg";
import GuideDelete from "../../../assets/images/svg/icon_guide_delete.svg";
import GuideStepDelete from "../../../assets/images/svg/icon_guide_step_delete.svg";
import GuideNoticeUnsubscribe from "../../../assets/images/svg/icon_guide_notice_uns.svg";
import GuideCancel from "../../../assets/images/svg/icon_guide_cancel.svg";
import GuideStepUnsubscribe from "../../../assets/images/svg/icon_guide_step_uns.svg";
import Inform from "../../../assets/images/svg/icon_inform.svg";
import PolicyOverviewSummary from "../../../assets/images/svg/icon_policy_overview_summary.svg";
import MapPin from "../../../assets/images/svg/icon_map_pin.svg";
import PrivacyPolicyHistory from "../../../assets/images/svg/icon_pp_history.svg";
import PrivacyPolicyLanguage from "../../../assets/images/svg/icon_pp_language.svg";
import PrivacyPolicyDownload from "../../../assets/images/svg/icon_pp_download.svg";
import PolicyOverviewStep1 from "../../../assets/images/svg/icon_privacy_overview_step1.svg";
import ChevronMore from "../../../assets/images/svg/icon_chevron_more.svg";
import PolicyOverviewStep11 from "../../../assets/images/svg/icon_policy_overview_step1-1.svg";
import PolicyOverviewStep12 from "../../../assets/images/svg/icon_policy_overview_step1-2.svg";
import PolicyOverviewStep13 from "../../../assets/images/svg/icon_policy_overview_step1-3.svg";
import PolicyOverviewStep14 from "../../../assets/images/svg/icon_policy_overview_step1-4.svg";
import PolicyOverviewStep15 from "../../../assets/images/svg/icon_policy_overview_step1-5.svg";
import PolicyOverviewStep1Table from "../../../assets/images/svg/icon_policy_overview_step1_table.svg";
import PolicyOverviewStep2 from "../../../assets/images/svg/icon_privacy_overview_step2.svg";
import PolicyOverviewStep21 from "../../../assets/images/svg/icon_policy_overview_step2-1.svg";
import PolicyOverviewStep22 from "../../../assets/images/svg/icon_policy_overview_step2-2.svg";
import PolicyOverviewStep3 from "../../../assets/images/svg/icon_privacy_overview_step3.svg";
import PolicyOverviewStep31 from "../../../assets/images/svg/icon_policy_overview_step3-1.svg";
import PolicyOverviewStep32 from "../../../assets/images/svg/icon_policy_overview_step3-2.svg";
import PolicyOverviewStep33 from "../../../assets/images/svg/icon_policy_overview_step3-3.svg";
import PolicyOverviewBottom1 from "../../../assets/images/svg/icon_policy_overview_bottom1.svg";
import PolicyOverviewBottom2 from "../../../assets/images/svg/icon_policy_overview_bottom2.svg";
import PolicyOverviewBottom1Mobile from "../../../assets/images/svg/icon_policy_overview_bottom1_mobile.svg";
import PolicyOverviewBottom2Mobile from "../../../assets/images/svg/icon_policy_overview_bottom2_mobile.svg";
import ArrowDownWhite from "../../../assets/images/svg/icon_arrow_down_white.svg";
import Setting from "../../../assets/images/svg/icon_setting.svg";
import MenuPolicyOverviewIcon from "../../../assets/images/svg/menu_policy_overview_icon.svg";
import MenuPrivacyPolicyIcon from "../../../assets/images/svg/menu_privacy_policy_icon.svg";
import GrrsMap from "../../../assets/images/svg/grrs_map.svg";
import GrrsMapMobile from "../../../assets/images/svg/grrs_map_mobile.svg";
import GrrsPin from "../../../assets/images/svg/icon_grrs_location.svg";
import MyDataChinaNoData from "../../../assets/images/svg/icon_china_no_data.svg";
import ArrowRightWhite from "../../../assets/images/svg/icon_arrow_right_white.svg";
import SurveyVSatisfied from "../../../assets/images/svg/icon_survey_very_satisfied.svg";
import SurveySatisfied from "../../../assets/images/svg/icon_survey_satisfied.svg";
import SurveyDissatisfied from "../../../assets/images/svg/icon_survey_dissatisfied.svg";
import SurveyVDissatisfied from "../../../assets/images/svg/icon_survey_very_dissatisfied.svg";
import ToastClose from "../../../assets/images/svg/icon_toast_close.svg";
import TTSPlay from "../../../assets/images/svg/icon_speaker_play.svg";
import TTSStop from "../../../assets/images/svg/icon_speaker_stop.svg";
import MyDataKoreaChild from "../../../assets/images/svg/icon_mydata_korea_child.svg";
import MouseScroll from "../../../assets/images/svg/icon_mouse_scroll.svg";
import EarthIcon from "../../../assets/images/svg/icon_btn_earth.svg";
import GpsMapAsia from "../../../assets/images/svg/ic_map_asia.svg";
//import GpsMapAfrica from '../../../assets/images/svg/ic_map_africa.svg';
import GpsMapEurope from "../../../assets/images/svg/ic_map_europe2.svg";
import GpsMapLatinAmerica from "../../../assets/images/svg/ic_map_latam.svg";
import GpsMapNorthAmerica from "../../../assets/images/svg/ic_map_northam.svg";
import GpsMapMena from "../../../assets/images/svg/ic_map_mena.svg";
import HubRightsMapUSA from "../../../assets/images/svg/ic_map_usa_hub.svg";
import HubRightsMapLatAm from "../../../assets/images/svg/ic_map_latin_america_hub.svg";
import HubRightsMapAsia from "../../../assets/images/svg/ic_map_asia_hub.svg";
import HubRightsMapEU from "../../../assets/images/svg/ic_map_eu_hub.svg";
import HubRightsMapTurkiye from "../../../assets/images/svg/ic_map_turkiye_hub.svg";
import HubRightsMapChina from "../../../assets/images/svg/ic_map_china_hub.svg";
import HubRightsMapGlobal from "../../../assets/images/svg/ic_map_global_hub.svg";
import HubRightsMapMena from "../../../assets/images/svg/ic_map_mena_hub.svg";
import HubRightsMapSapp from "../../../assets/images/svg/ic_map_sapp_hub.svg";
import HubWorldMap from "../../../assets/images/svg/ic_icon_worldmap.svg";
import HubManageRights from "../../../assets/images/svg/ic_rights.svg";
import HubManageNoAds from "../../../assets/images/svg/ic_noads.svg";
import HubManageDeleteData from "../../../assets/images/svg/ic_deletedata.svg";
import HubManageSeeData from "../../../assets/images/svg/ic_seedata.svg";
import MyDataArrowIcon from "../../../assets/images/svg/ic_arrow_mydata_location.svg";
import MyDataArrowIconHc from "../../../assets/images/svg/ic_arrow_mydata_location_hc.svg";
import RoundCheckButtonIcon from "../../../assets/images/svg/ic_round-check_button_icon.svg";
import SelectLocationButtonIcon from "../../../assets/images/svg/ic_go_select_location.svg";
export const SVG_ICONS: any = {
  FOOTER_THEME_DEFAULT: DefaultTheme,
  FOOTER_THEME_HIGH_CONTRAST: HighContrastTheme,
  FOOTER_LOGO: FooterLogo,
  ARROW_UP: ArrowUp,
  ARROW_TOP_RIGHT: ArrowTopRight,
  ARROW_RIGHT: ArrowRight,
  ARROW_RIGHT_MOBILE: ArrowRightMobile,
  CHEVRON_RIGHT: ChevronRight,
  CHEVRON_HUB_LEFT_DISABLED: ChevronHubLeftDisabled,
  CHEVRON_HUB_RIGHT_DISABLED: ChevronHubRightDisabled,
  CHEVRON_HUB_LEFT: ChevronHubLeft,
  CHEVRON_HUB_RIGHT: ChevronHubRight,
  CHEVRON_HUB_LEFT_MOBILE: ChevronHubLeftMobile,
  CHEVRON_HUB_RIGHT_MOBILE: ChevronHubRightMobile,
  CHEVRON_HUB_LEFT_MOBILE_DISABLED: ChevronHubLeftMobileDisabled,
  CHEVRON_HUB_RIGHT_MOBILE_DISABLED: ChevronHubRightMobileDisabled,
  TRASH_LRG: TrashLrg,
  TRASH_SML: TrashSml,
  GREATE_THAN_LRG: GreaterThanLrg,
  GREATE_THAN_SML: GreaterThanSml,
  LOGO: Logo,
  PROFILE: Profile,
  PROFILE_SIGNOUT: ProfileSignOut,
  PROFILE_SIGNOUT_CIRCLE: ProfileSignOutCircle,
  MENU_ABOUT_DETAIL_ICON: MenuAboutDetailIcon,
  MOBILE_MENU_INTRO: MobileMenuIntro,
  MOBILE_MENU_PP: MobileMenuPrivacyPolicy,
  MOBILE_MENU_MY_DATA: MobileMenuMyData,
  BURGER_MENU: BurgerMenu,
  DRAWER_CLOSE: DrawerClose,
  ERROR_400: IconError400,
  ERROR_403: IconError403,
  ERROR_404: IconError404,
  ERROR_500: IconError500,
  ERROR_502: IconError502,
  ERROR_503: IconError503,
  ERROR_IE: IconErrorIE,
  ERROR_MAINTENANCE: IconErrorMaintenance,
  TOOLTIP_OUTLINE: TooltipOutline,
  CLOSE_SMALL: CloseSmall,
  HOME_TOP_BANNER_BASE: IconHomeTopBase,
  HOME_TOP_BANNER_BASE_MOBILE: IconHomeTopBaseMobile,
  HOME_TOP_BANNER_HAND: IconHomeTopHand,
  HOME_TOP_BANNER_LAPTOP: IconHomeTopLaptop,
  HOME_TOP_BANNER_LOCK: IconHomeTopLock,
  HOME_TOP_BANNER_MAGNIFYINGGLASS: IconHomeTopMagnifyingGlass,
  HOME_TOP_BANNER_PRIVACY: IconHomeTopPrivacy,
  HOME_TOP_BANNER_SETTING: IconHomeTopSetting,
  SEARCH_FILLED: SearchFilled,
  GUIDE: Guide,
  MYDATA_CHINA: MyDataChina,
  MYDATA_INPROGRESS: MyDataInProgress,
  MYDATA_REQUESTING: MyDataRequesting,
  MYDATA_COMPLETED: MyDataCompleted,
  MYDATA_DELETE_BTN: MyDataDeleteBtn,
  MYDATA_DOWNLOAD_BTN: MyDataDownloadBtn,
  MYDATA_OPTOUT_BTN: MyDataOptOutBtn,
  MYDATA_ACCEPTING_POPUP: MyDataAcceptingPopup,
  MYDATA_PROCESSING_POPUP: MyDataInProgressPopup,
  MYDATA_COMPLETED_POPUP: MyDataCompletedPopup,
  MYDATA_CANCELED_POPUP: MyDataCancelPopup,
  MYDATA_EXPIRED_POPUP: MyDataExpiredPopup,
  MYDATA_DENIED_POPUP: MyDataDeniedPopup,
  CAROUSEL_ARROW: CarouselArrow,
  HOME_MORE_INFO: HomeMoreInfo,
  HOME_TRANSPARENCY_BASE: HomeTransparencyBase,
  HOME_TRANSPARENCY_ICON1: HomeTransparencyIcon1,
  HOME_TRANSPARENCY_ICON2: HomeTransparencyIcon2,
  HOME_TRANSPARENCY_ICON3: HomeTransparencyIcon3,
  HOME_CHOICE_BASE: HomeChoiceBase,
  HOME_CHOICE_ICON1: HomeChoiceIcon1,
  HOME_CHOICE_ICON2: HomeChoiceIcon2,
  HOME_CHOICE_ICON3: HomeChoiceIcon3,
  HOME_SAFETY_BASE: HomeSafetyBase,
  HOME_SAFETY_ICON1: HomeSafetyIcon1,
  HOME_SAFETY_ICON2: HomeSafetyIcon2,
  HOME_SAFETY_ICON3: HomeSafetyIcon3,
  RIGHTS_RESPONSE: RightsResponse,
  FUNCTION_GUIDE: FunctionGuide,
  ABOUT_PRIVACY: AboutPrivacy,
  ARROW_DOWN: ArrowDown,
  INFORM: Inform,
  POLICY_OVERVIEW_SUMMARY: PolicyOverviewSummary,
  POLICY_OVERVIEW_STEP1: PolicyOverviewStep1,
  POLICY_OVERVIEW_STEP1_1: PolicyOverviewStep11,
  POLICY_OVERVIEW_STEP1_2: PolicyOverviewStep12,
  POLICY_OVERVIEW_STEP1_3: PolicyOverviewStep13,
  POLICY_OVERVIEW_STEP1_4: PolicyOverviewStep14,
  POLICY_OVERVIEW_STEP1_5: PolicyOverviewStep15,
  POLICY_OVERVIEW_STEP1_TABLE: PolicyOverviewStep1Table,
  GUIDE_DOWNLOAD: GuideDownload,
  GUIDE_STEP: GuideStep,
  GUIDE_PASSWORD: GuidePassword,
  GUIDE_NOTICE: GuideNotice,
  GUIDE_EXPIRES: GuideExpires,
  GUIDE_DELETE: GuideDelete,
  GUIDE_STEP_DELETE: GuideStepDelete,
  GUIDE_NOTICE_UNSUBSCRIBE: GuideNoticeUnsubscribe,
  GUIDE_CANCEL: GuideCancel,
  GUIDE_STEP_UNSUBSCRIBE: GuideStepUnsubscribe,
  MAP_PIN: MapPin,
  PRIVACY_POLICY_HISTORY: PrivacyPolicyHistory,
  PRIVACY_POLICY_LANGUAGE: PrivacyPolicyLanguage,
  PRIVACY_POLICY_DOWNLOAD: PrivacyPolicyDownload,
  CHEVRON_MORE: ChevronMore,
  POLICY_OVERVIEW_STEP2: PolicyOverviewStep2,
  POLICY_OVERVIEW_STEP2_1: PolicyOverviewStep21,
  POLICY_OVERVIEW_STEP2_2: PolicyOverviewStep22,
  POLICY_OVERVIEW_STEP3: PolicyOverviewStep3,
  POLICY_OVERVIEW_STEP3_1: PolicyOverviewStep31,
  POLICY_OVERVIEW_STEP3_2: PolicyOverviewStep32,
  POLICY_OVERVIEW_STEP3_3: PolicyOverviewStep33,
  POLICY_OVERVIEW_BOTTOM_1: PolicyOverviewBottom1,
  POLICY_OVERVIEW_BOTTOM_2: PolicyOverviewBottom2,
  ARROW_DOWN_WHITE: ArrowDownWhite,
  POLICY_OVERVIEW_BOTTOM_1_MOBILE: PolicyOverviewBottom1Mobile,
  POLICY_OVERVIEW_BOTTOM_2_MOBILE: PolicyOverviewBottom2Mobile,
  SETTING: Setting,
  MENU_PRIVACY_OVERVIEW_ICON: MenuPolicyOverviewIcon,
  MENU_PRIVACYPOLICY_ICON: MenuPrivacyPolicyIcon,
  GRRS_MAP: GrrsMap,
  GRRS_PIN: GrrsPin,
  GRRS_MAP_MOBILE: GrrsMapMobile,
  CHINA_NODATA: MyDataChinaNoData,
  ARROW_RIGHT_WHITE: ArrowRightWhite,
  SURVEY_VERY_SATISFIED: SurveyVSatisfied,
  SURVEY_SATISFIED: SurveySatisfied,
  SURVEY_DISSATISFIED: SurveyDissatisfied,
  SURVEY_VERY_DISSATISFIED: SurveyVDissatisfied,
  TOAST_CLOSE: ToastClose,
  TTS_PLAY: TTSPlay,
  TTS_STOP: TTSStop,
  MYDATA_KOREA_CHILD: MyDataKoreaChild,
  MOUSE_SCROLL: MouseScroll,
  EARTH_ICON: EarthIcon,
  GPS_MAP_ASIA: GpsMapAsia,
  GPS_MAP_EUROPE: GpsMapEurope,
  GPS_MAP_NORTH_AMERICA: GpsMapNorthAmerica,
  GPS_MAP_LATIN_AMERICA: GpsMapLatinAmerica,
  GPS_MAP_MENA: GpsMapMena,
  HUB_RIGHTS_MAP_USA: HubRightsMapUSA,
  HUB_RIGHTS_MAP_LATAM: HubRightsMapLatAm,
  HUB_RIGHTS_MAP_ASIA: HubRightsMapAsia,
  HUB_RIGHTS_MAP_EUROPE: HubRightsMapEU,
  HUB_RIGHTS_MAP_TURKIYE: HubRightsMapTurkiye,
  HUB_RIGHTS_MAP_CHINA: HubRightsMapChina,
  HUB_RIGHTS_MAP_OTHERS: HubRightsMapGlobal,
  HUB_RIGHTS_MAP_MENA: HubRightsMapMena,
  HUB_RIGHTS_MAP_SAPP: HubRightsMapSapp,
  HUB_WORLD_MAP: HubWorldMap,
  HUB_MANAGE_RIGHTS: HubManageRights,
  HUB_MANAGE_NO_ADS: HubManageNoAds,
  HUB_MANAGE_DELETE_DATA: HubManageDeleteData,
  HUB_MANAGE_SEE_DATA: HubManageSeeData,
  MYDATA_LOCATION_ARROW: MyDataArrowIcon,
  MYDATA_LOCATION_ARROW_HC: MyDataArrowIconHc,
  ROUND_CHECK_BUTTON_ICON: RoundCheckButtonIcon,
  SELECT_LOCATION_BUTTON_ICON: SelectLocationButtonIcon,
};

interface Props {
  icon: string;
  className?: string;
  width?: number;
  height?: number;
  onClick?: (e: any) => void;
  variant?: string;
  testId?: string;
}

export const Svg: React.FC<Props> = (props) => {
  const {
    icon,
    className = "",
    width = 20,
    height = 20,
    onClick,
    variant,
    testId,
  } = props;
  const wrapClass = classNames("svg", { [className]: !!className });

  const handleOnClick = (e?: any) => {
    if (onClick) onClick(e);
  };

  const style =
    variant === "empty"
      ? {}
      : {
          width,
          height,
          minWidth: width,
          minHeight: height,
        };

  return (
    <ReactSVG
      src={icon}
      wrapper="span"
      style={style}
      className={wrapClass}
      onClick={handleOnClick}
      data-testid={testId}
    />
  );
};
