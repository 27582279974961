import { useState } from "react";

export const useDrawerHooks = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return { isDrawerOpen, toggleDrawer };
};
