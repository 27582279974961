export interface GpsCountry {
  code: string;
  name: string;
  link: string;
}

export enum GPS_REGION_CODE {
  NORTH_AMERICA = "NA",
  LATIN_AMERICA = "LA",
  EUROPE = "EU",
  ASIA_PACIFIC = "AP",
  MENA = "ME", // Middle east and Africa
}

export interface GpsRegion {
  code: GPS_REGION_CODE;
  name: string;
  image?: string;
  countries: GpsCountry[];
}
